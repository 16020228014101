define('acif/view/formify/precompiled/formify-master', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (obj) {
    var __t,
        __p = '',
        __j = Array.prototype.join,
        print = function print() {
      __p += __j.call(arguments, '');
    };
    with (obj || {}) {
      __p += '<form class="formify-form" ' + ((__t = formify._attrs) == null ? '' : __t) + ' novalidate>\n  <div class="formify-form-body" role="main">\n\n    <div  acif-template="formify-header">' + ((__t = getSubTemplate("formify-header")(Object.assign({}, obj))) == null ? '' : __t) + ' </div>\n\n    ';
      _.each(formify.fields, function (field) {
        __p += '\n\n      ';
        if (field.fields) {
          __p += '\n        ';
          _.each(field.fields, function (groupItem) {
            __p += '\n          ';
            if (groupItem._order === 1) {
              __p += '\n            <div class="formify-group" acif-fieldset-group>\n            <h2>' + ((__t = this.group) == null ? '' : __t) + '</h2>\n          ';
            }
            __p += '\n            <div  acif-template="formify-type">' + ((__t = getSubTemplate("formify-type")(Object.assign({ field: field, groupItem: groupItem }, obj))) == null ? '' : __t) + ' </div>\n          ';
            if (groupItem._order === this._size) {
              __p += '\n            </div>\n          ';
            }
            __p += '\n        ';
          }, field);
          __p += '\n    ';
        }
        __p += '\n\n      <div  acif-template="formify-type">' + ((__t = getSubTemplate("formify-type")(Object.assign({ field: field }, obj))) == null ? '' : __t) + ' </div>\n\n    ';
      });
      __p += '\n  </div>\n\n  ';
      if (!formify.submitOnClick) {
        __p += '\n    <div  acif-template="formify-footer">' + ((__t = getSubTemplate("formify-footer")(Object.assign({}, obj))) == null ? '' : __t) + ' </div>\n  ';
      }
      __p += '\n\n</form>';
    }
    return __p;
  };
});