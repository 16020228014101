define('acif/view/formify/index', ['exports', '../../utils', './templates', './templates-precompiled'], function (exports, _utils, _templates, _templatesPrecompiled) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _templates2 = _interopRequireDefault(_templates);

  var _templatesPrecompiled2 = _interopRequireDefault(_templatesPrecompiled);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = {
    process: processFormify,
    getTemplate: getTemplate
  };


  function processFormify(originalFormifyData) {
    var processedFormifyData = $.extend(true, {}, originalFormifyData);

    validateFormify(processedFormifyData);
    setFormifyVars(processedFormifyData);
    setAllFieldVars(processedFormifyData);

    return processedFormifyData;
  }

  function getTemplate(templateName) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var emptyString = '';

    /*
     adding empty string keeps babel from converting this so that it's not imported as a dependency
     this module is available from acif-configs so it's not available when ACIF first loads
    */
    var clientFormifyTemplates = require('Automatons/templates/formify-map' + emptyString);

    if (clientFormifyTemplates && clientFormifyTemplates[templateName]) {
      var clientTemplateId = clientFormifyTemplates[templateName];
      return require('Automatons/templates/' + clientTemplateId);
    }

    if (options.precompiled) {
      return _templatesPrecompiled2.default[templateName];
    }

    return _templates2.default[templateName];
  }

  function validateFormify(data) {
    if (_.isUndefined(data.fields)) {
      _utils2.default.console.error('Formify: formify object is missing "fields" array.');
    } else {
      data.fields.forEach(function (field) {
        // if field is not a group and is missing "name"
        if (_.isUndefined(field.group) && _.isUndefined(field.id)) {
          _utils2.default.console.error('Formify: field is missing property "id".');
        }
      });
    }
  }

  function setFormifyVars(formifyData) {
    var formify = formifyData || {};
    var nextType = void 0;

    formify.submit = formify.submit || 'Submit';
    formify.next = formify.next || 'submit';
    nextType = _.isNumber(formify.next) ? 'acif-next-node' : 'acif-next-transition';
    formify._next_attr = ' ' + nextType + '="' + formify.next + '" ';

    formify._toggle_attr = '';
    formify._submitOnClick_attr = '';

    if (formify.toggle) {
      formify._toggle_attr = ' acif-toggle-disabled-submit ';
    }

    if (formify.submitOnClick) {
      formify._submitOnClick_attr = ' acif-submit-on-click ';
    }

    // _attrs are passed onto the form tag itself
    formify._attrs = formify._next_attr + formify._submitOnClick_attr;
  }

  function setAllFieldVars(processedFormifyData) {
    processedFormifyData.fields.forEach(function (field) {
      if (field.fields) {
        field.fields.forEach(function (groupedField) {
          field._size = field.fields.length;
          groupedField._order = _.indexOf(field.fields, groupedField) + 1;
          setFieldVars(groupedField);
        });
      } else {
        setFieldVars(field);
      }
    });
  }

  function setFieldVars(fieldData) {
    var field = fieldData || {};
    setFieldLabelAndTypeAndPlaceholder(field);
    setFieldAttributes(field);
    setFieldValidationAttributes(field);
    setValidationErrorStrings(field);
    buildErrorMessages(field.validate);
  }

  function setFieldLabelAndTypeAndPlaceholder(field) {
    field.label = field.label || field.id;
    field.type = field.type || 'text';
    field._type_attr = field.type === 'scale' ? 'radio' : field.type;
    field.placeholder = field.type === 'select' ? field.placeholder || 'Select One&#8230;' : field.placeholder || '';
    field._placeholder_attr = field.placeholder ? ' placeholder="' + field.placeholder + '" ' : '';
  }

  function setFieldAttributes(field) {
    field._required_attr = '';
    field._dynamic_attr = '';
    field._autoscroll_attr = '';
    field._masked_attr = '';
    field._omit_attr = '';
    field._exposure_attr = '';

    if (field.required) {
      field._required_attr = ' required class="required" ';
    }

    if (field.dynamic) {
      field.dynamic.check = field.dynamic.check || '';
      field.dynamic.logic = field.dynamic.logic || 'equals';
      field.dynamic.value = field.dynamic.value || '';
      field._dynamic_value_prop = field.dynamic.logic === 'equals' ? ' acif-base-field-value' : ' acif-base-field-value-' + field.dynamic.logic;
      field._dynamic_attr = ' acif-base-field-name="' + field.dynamic.check + '" ' + field._dynamic_value_prop + '="' + field.dynamic.value + '" ';
    }

    if (field.conditions) {
      // flag as a potential dependent
      field._dynamic_attr = ' acif-conditional';
    }

    if (field.autoscroll) {
      field._autoscroll_attr = ' acif-scroll-on-change ';
    }

    if (field.masked) {
      field._masked_attr = ' acif-masked ';
    }

    if (field.omit) {
      field._omit_attr = ' acif-omit-datapass ';
    }

    if (field.exposureRate > -1) {
      field._exposure_attr = ' acif-base-exposure-rate="' + field.exposureRate + '" ';
    }

    // put attributes with same destination into a single variable
    field._attrs = field._required_attr + field._dynamic_attr + field._autoscroll_attr + field._masked_attr + field._omit_attr + field._exposure_attr;
  }

  function setFieldValidationAttributes(field) {
    field.validate = field.validate || {};
    field.validate.required = field.required || '';
    field.validate._error_types = ['required', 'min', 'max', 'pattern', 'minlength', 'maxlength'];
    field.validate._html = '';

    field.multiple = field.type === 'select' && field.validate.min ? true : field.multiple || false;
    field._multiple_attr = field.multiple ? ' multiple ' : '';

    field._pattern_attr = '';
    field._min_attr = '';
    field._max_attr = '';
    field._minlength_attr = '';
    field._maxlength_attr = '';

    if (field.validate.pattern) {
      field._pattern_attr = ' pattern="' + field.validate.pattern + '" ';
    }

    if (field.validate.min) {
      field._min_attr = ' min="' + field.validate.min + '" ';
    }

    if (field.validate.max) {
      field._max_attr = ' max="' + field.validate.max + '" ';
    }

    if (field.validate.minlength) {
      field._minlength_attr = ' minlength="' + field.validate.minlength + '" ';
    }

    if (field.validate.maxlength) {
      field._maxlength_attr = ' maxlength="' + field.validate.maxlength + '" ';
    }

    field.validate._attrs = field._pattern_attr + field._min_attr + field._max_attr + field._minlength_attr + field._maxlength_attr;
  }

  function setValidationErrorStrings(field) {
    var val = field.validate;
    val.requiredError = val.requiredError || field.requiredError || 'This is a required field';
    val.minError = val.minError || 'You must select at least ' + val.min + ' options.';
    val.maxError = val.maxError || 'You cannot select more than ' + val.min + ' options.';
    val.patternError = val.patternError || 'Please enter a valid ' + field.label + '.';
    val.minlengthError = val.minlengthError || field.label + ' must be at least ' + val.minlength + ' characters.';
    val.maxlengthError = val.maxlengthError || field.label + ' must be at less than ' + val.maxlength + ' characters.';
  }

  function buildErrorMessages(currentField) {
    var errorMessages = '';
    var errorTypes = {
      required: 'requiredError',
      min: 'minError',
      max: 'maxError',
      pattern: 'patternError',
      minlength: 'minlengthError',
      maxlength: 'maxlengthError'
    };
    Object.keys(errorTypes).forEach(function (type) {
      if (currentField[type]) {
        errorMessages += '<p acif-invalid="' + type + '">' + currentField[errorTypes[type]] + '</p>\n';
      }
    });
    currentField._html = errorMessages;
  }
});