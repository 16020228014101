define('acif/utils/get', ['exports', './logger'], function (exports, _logger) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _logger2 = _interopRequireDefault(_logger);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = get;


  function get(obj, path) {
    if (!obj) return;

    try {
      var parts = (path || '').split('.');
      var key = parts.shift();
      var val = obj[key];

      return parts.length ? get(val, parts.join('.')) : val;
    } catch (e) {
      _logger2.default.error(e);
    }
  }
});