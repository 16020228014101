define('acif/utils/throwError', ['exports', './events', './logger'], function (exports, _events, _logger) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = throwError;

  var _events2 = _interopRequireDefault(_events);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function throwError(msg) {
    var error = new Error(msg);
    error.name = 'ACIF Error';
    (0, _logger.logError)(error);
    _events2.default.trigger('error', error);
    throw error;
  }
});