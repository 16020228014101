define('acif/view/position', ['exports', '../utils', '../ci/ijsf-adapter'], function (exports, _utils, _ijsfAdapter) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Position;


  function Position(View) {
    this.View = View;
    this.Env = View.Env;
  }

  var fn = Position.prototype = { constructor: Position };

  fn.set = function (position) {
    if (this.Env.flashVars.deviceType === 'Phone') {
      return;
    }
    if (this.Env.isPersistentWindow) {
      return;
    }
    if (!position) {
      this.repositionInsideViewport();
      return;
    }

    var args = [];

    if (_.has(position, 'x') && _.has(position, 'y') || _.has(position, 'top') && _.has(position, 'left')) {
      var x = position.x || position.left;
      var y = position.y || position.top;

      if (_.isString(x) && _.isString(y)) {
        args = [normalizePositionKeyword(y + '_' + x)];
      } else if (_.isNumber(x) && _.isNumber(y)) {
        args = [position.type || 'relative', x, y];
      } else {
        var msg = 'The x and y coordinates were unrecognized. ' + 'You passed: x = ' + x + ', y = ' + y + '. Defaulting to center position.';
        _utils2.default.console(msg, 'error');
        args = ['center'];
      }
    } else if (typeof position === 'string') {
      args = [normalizePositionKeyword(position)];
    } else if (position.my || position.at || position.of) {
      args = this.getCustomPositionSettings(position);
    } else {
      _utils2.default.console('Unrecognized position settings.  Skipping CI positioning', 'error');
      return;
    }

    var move = function move() {
      _ijsfAdapter2.default.moveStage.apply(null, args);
    };

    if (position.didScroll) {
      // Must use a timeout due to IJSF quirk that respositions the CI after
      // a 500ms delay anytime the page has been scrolled (even the page
      // has been programatically scrolled, as is the case here)
      // see com.inq.flash.client.chatskins.ScrollMonitor.whenScroll
      setTimeout(move, 550);
    } else {
      var originalCoordinates = this.View.$skin.position();
      move();
      if (position && position.animate && !this.Env.is_preview_mode) {
        this.animate(originalCoordinates, position, args);
      }
    }
  };

  function normalizePositionKeyword(keyword) {
    var valid_positions = ['upper_left', 'upper_center', 'upper_right', 'center_left', 'center', 'center_right', 'lower_left', 'lower_center', 'lower_right'];

    var position = keyword.toLowerCase().replace(/[-\s]+/, '_').replace('top', 'upper').replace('bottom', 'lower').replace('middle', 'center').replace('center_center', 'center');

    if (_.indexOf(valid_positions, position) === -1) {
      var msg = '"' + position + '" is not a valid CI position keyword. ' + 'The 9 valid position keywords are: [' + valid_positions.join(', ') + ']. Defaulting to "center" position.';

      _utils2.default.console(msg, 'error');
      position = 'center';
    }

    return position;
  }

  fn.fit = function repositionInsideViewport() {
    if (this.Env.flashVars.deviceType === 'Phone') {
      return;
    }
    if (this.Env.isPersistentWindow) {
      return;
    }
    if (_ijsfAdapter2.default.isMinimized()) {
      return;
    }

    var currentPosition = this.View.$skin.position();

    // Use the IJSF's ability to position the CI away from visible scrollbars
    // to identify the safe levels for the top and left coordinates
    _ijsfAdapter2.default.moveStage('lower_right');

    var bottomRight = this.View.$skin.position();

    var newLeft = Math.min(currentPosition.left, bottomRight.left);
    var newTop = Math.min(currentPosition.top, bottomRight.top);

    _ijsfAdapter2.default.moveStage('absolute', newLeft, newTop);
  };

  fn.getCustomPositionSettings = function (position) {
    var placeholder = this.Env.top.document.createElement('div');
    var $placeholder = $(placeholder);

    position.within = this.Env.top;

    if (position.of) {
      if (position.of === 'window' || position.of === window) {
        position.of = this.Env.top;
      } else if (position.of !== this.Env.top) {
        position.of = $(position.of, this.Env.top.document);
        position.element = position.of[0];
        if (position.of.length === 0) {
          position.of = this.Env.top;
        }
      }
    } else {
      position.of = this.Env.top;
    }

    var shouldScrollFirst = !!(position.element && !isElementInViewport(position.element) && typeof position.element.scrollIntoView === 'function');

    if (shouldScrollFirst) {
      position.element.scrollIntoView();
      position.didScroll = true;
    }

    $placeholder.appendTo($('body', this.Env.top.document));
    $placeholder.css({
      position: 'absolute',
      width: this.View.$skin.outerWidth(),
      height: this.View.$skin.outerHeight()
    });
    $placeholder.position(position);

    var coordinates = $placeholder.position();
    var $top = $(this.Env.top);
    var scrollTop = $top.scrollTop();
    var scrollLeft = $top.scrollLeft();
    var newLeft = coordinates.left - scrollLeft;
    var newTop = coordinates.top - scrollTop;

    $placeholder.remove();

    return ['relative', newLeft, newTop];
  };

  function isElementInViewport(el) {
    el = el instanceof jQuery ? el[0] : el;

    var isElement = !!el.tagName;

    if (!isElement) {
      return true;
    }

    var rect = el.getBoundingClientRect();

    return rect.top >= 0 && rect.left >= 0 && rect.bottom <= $(this.Env.top).height() && rect.right <= $(this.Env.top).width();
  }

  fn.animate = function (originalCoordinates, position, args) {
    var _this = this;

    _ijsfAdapter2.default.moveStage('relative', originalCoordinates.left, originalCoordinates.top);

    // See full set of animation options at
    // http://api.jquery.com/animate/#animate-properties-options
    var options = typeof position.animate === 'object' ? position.animate : {};
    var delay = options.delay || 500;

    delete options.delay;

    var newCoordinates = this.View.$skin.position();

    var always = typeof options.always === 'function' ? options.always : $.noop;
    options.always = function () {
      _ijsfAdapter2.default.moveStage.apply(null, args);
      always();
    };

    setTimeout(function () {
      _this.View.$skin.animate(newCoordinates, options);
    }, delay);
  };
});