define('acif/data/customer-response-manager', ['exports', '../utils'], function (exports, _utils) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = CRM;


  function CRM(Data) {
    var _this = this;

    this.Data = Data;
    this.Automaton = Data.Automaton;

    // central object where all responses during engagement are stored
    this.storage = Data.StorageClass.create(this, 'CRM'); // a=this.constructor.toString()

    // only stores new responses that must still be logged to the DW
    this.responsesToBeLogged = {};

    // data that DEs can use inside configurations: e.g., api.forms or {"condition" : "_.first_name == 'john_doe'"}
    this.apiData = {
      _: {}
    };

    _.each(this.storage, function (response) {
      _this.exposeResponseToApi(response);
    });
  }

  var fn = CRM.prototype = { constructor: CRM };

  fn.addNodeSelection = function (nextNodeId) {
    var breadcrumbsPath = _utils2.default.generateBreadcrumbs(this.Automaton.displayedPath);
    var isBacktracking = _.indexOf(breadcrumbsPath, nextNodeId) > -1;
    var isFormNode = !!this.Automaton.View.$viewport.find('form:visible').length;

    if (isBacktracking || isFormNode) return;

    var answerId = this.Automaton.node.id + '-' + nextNodeId;
    this.addNodeTransition(answerId);
  };

  fn.addTransitionSelection = function (transitionName) {
    var answerId = this.Automaton.node.id + '-' + transitionName;
    this.addNodeTransition(answerId);
  };

  fn.addNodeTransition = function (answerId) {
    var question = this.getCurrentQuestion();
    var answerText = this.getAnswerText(answerId);
    var answerType = this.answerTypes.NON_FREE_FORM;

    if (!question.questionText || !answerText) return;

    var response = _.extend({ answerId: answerId, answerType: answerType, answerText: answerText }, question);

    this.addResponse(response);
  };

  fn.getCurrentQuestion = function () {
    var $view = this.Automaton.View.$viewport;
    var questionText = $view.find('[acif-question]').text().trim() || this.Automaton.node.form_field_name;

    // If `isTransitionQuestion` is true, we will *not* include with agent datapass.
    // When a node has either `form_field_view` defined or uses a `acif-question`
    // attribute within its view, it indicates that we want to treat the customer's
    // selection as a response to a specific form field.
    var questionId = void 0,
        isTransitionQuestion = void 0;

    if (questionText) {
      isTransitionQuestion = false;
      questionId = questionText;
    } else {
      isTransitionQuestion = true;

      var $questionElement = $view.find('h1,h2,h3').last();

      questionText = $questionElement.data('original-text') || $questionElement.text().trim() || this.Automaton.node.name;
      questionId = this.Automaton.node.id;
    }

    return { questionId: questionId, questionText: questionText, isTransitionQuestion: isTransitionQuestion };
  };

  fn.getAnswerText = function (answerId) {
    var activeEvent = this.Automaton.activeEvent;
    var activeElementText = activeEvent ? activeEvent.$element.data('original-text') || activeEvent.$element.text().trim() : null;
    var transitionName = answerId.split('-')[1];
    return activeElementText || transitionName;
  };

  fn.getAnswerValueByQuestionId = function (questionId) {
    var responses = this.getResponseList();

    var question = responses.find(function (response) {
      return response.questionId === questionId;
    });

    return question.answerValue;
  };

  fn.addResponse = function (response) {
    var answerType = response.answerType !== undefined ? response.answerType : this.answerTypes.FREE_FORM;

    response = {
      questionText: response.questionText || '',
      questionId: response.questionId || '',
      answerText: this.limitResponseValue(response.answerText) || '',
      answerId: response.answerId || '',
      answerValue: this.limitResponseValue(response.answerValue) || '',
      questionNumber: response.questionNumber || '',
      answerNumber: response.answerNumber || '',
      answerType: answerType,
      isMasked: response.isMasked,
      isFieldDisabled: response.isFieldDisabled,
      isTransitionQuestion: response.isTransitionQuestion || false
    };

    this.responsesToBeLogged[response.questionId] = response;

    this.save(response.questionId, response);

    this.exposeResponseToApi(response);
  };

  fn.limitResponseValue = function (value) {
    if (!value) return;

    var isMultiple = Array.isArray(value);
    if (isMultiple) {
      return value.map(function (string) {
        return string.substring(0, 10000);
      });
    } else {
      return value.substring(0, 10000);
    }
  };

  fn.clearResponse = function (fieldName) {
    var underscoredQuestionId = _utils2.default.underscore(fieldName);

    delete this.apiData[fieldName];
    delete this.apiData['_' + underscoredQuestionId];
    delete this.apiData._[underscoredQuestionId];

    delete this.responsesToBeLogged[fieldName];

    this.save(fieldName, undefined);
  };

  fn.exposeResponseToApi = function (response) {
    var underscoredQuestionId = _utils2.default.underscore(response.questionId);
    var underscoredAnswer = _utils2.default.underscore(response.answerText);

    this.apiData[response.questionId] = response.answerText;
    this.apiData['_' + underscoredQuestionId] = underscoredAnswer;
    this.apiData._[underscoredQuestionId] = underscoredAnswer;
  };

  fn.getResponseList = function () {
    var responses = _.values(this.storage);
    responses = responses.filter(function (response) {
      return !!response;
    });
    responses = _.sortBy(responses, 'questionNumber');
    return responses;
  };

  /**
   * Update a form item's answerText and answerValue
   * @method updateFormItemResponse
   * @param  {string} key - Id of the question to apply update(s)
   * @param  {string} answerText - string that will replace the current answerText
   * @param  {string} [answerValue] - string that will replace the current answerValue
   */
  fn.updateFormItemResponse = function (key, answerText, answerValue) {
    var response = this.get(key);

    if (!response) return;

    if (response.isMasked) {
      answerText = '((masked{[' + answerText + ']}masked))';
      answerValue = answerValue ? '((masked{[' + answerValue + ']}masked))' : response.answerValue;
    }

    response.answerText = answerText;
    response.answerValue = answerValue ? answerValue : response.answerValue;

    this.addResponse(response);
  };

  fn.emptyResponses = function () {
    var responses = _.values(this.responsesToBeLogged);

    this.responsesToBeLogged = {};

    return responses;
  };

  fn.answerTypes = {
    FREE_FORM: 1,
    NON_FREE_FORM: 0
  };

  fn.toDatapassString = function () {
    var responses = _.values(this.storage).filter(function (response) {
      return response && !response.isTransitionQuestion;
    }).filter(function (response) {
      return !response.isFieldDisabled;
    });

    return responses.map(function (response) {
      var line = '- ' + response.questionId + ':';
      var answer = response.answerText;
      line += Array.isArray(answer) ? '\n' + answer.map(function (a) {
        return '    ** ' + a;
      }).join('\n') : ' ' + answer;

      return line;
    }).join('\n');
  };

  fn.toSimpleObject = function () {
    var obj = {};

    _.each(this.storage, function (response) {
      if (response && !response.isTransitionQuestion) {
        obj[response.questionId] = response.answerText;
      }
    });

    return obj;
  };

  fn.getResponseValues = function () {
    var obj = {};

    this.getResponseList().forEach(function (response) {
      // response.answerValue has been masked during form validation
      obj[response.questionId] = response.answerValue;
    });

    return obj;
  };
});