define('acif/utils/mixins/native', [], function () {
    ///////////////////////
    // Extend Prototypes //
    ///////////////////////
    Number.prototype.match = function (regex) {
        return (this + '').match(regex);
    };

    /**
     * Shim for window.getComputedStyles.
     * Created by: Jake Detels
     * Date: 11/26/2014
     * Description: Ensures that calls to getComputedStyles will invoke
     *   the getComputedStyle function that belong's to the element's
     *   owning frame/window, rather than only via the current frame.
     *   Fixes a bug with Firefox when communicating between frames.
     */
    (function () {
        'use strict';

        if (window.getComputedStyle) {
            var nativeGetComputedStyle = window.getComputedStyle;

            window.getComputedStyle = function (element) {
                try {
                    var frame = window,
                        ownerDoc = element.ownerDocument;

                    while (frame !== window.top) {
                        if (frame.document !== ownerDoc) {
                            frame = frame.parent;
                        } else {
                            break;
                        }
                    }

                    var getComputedStyle = frame === window ? nativeGetComputedStyle : frame.getComputedStyle;
                    return getComputedStyle.apply(frame, arguments);
                } catch (e) {
                    return nativeGetComputedStyle.apply(window, arguments);
                }
            };
        }
    })();
});