define('storage/top-frame/id-manager', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getId;
  var getters = {
    site: getSiteId,
    session: getSessionId,
    engagement: getEngagementId,
    tab: getTabId,
    lifetime: noId,
    custom: noId
  };

  function getId(scope) {
    var getter = getters[scope];

    if (getter) {
      return getter(scope);
    }

    throw new Error(scope + ' is not a valid TC Local Storage scope');
  }

  function getSiteId() {
    return inqFrame.Inq.siteID;
  }

  function getSessionId() {
    return inqFrame.Inq.FlashPeer.getSessionID();
  }

  function getEngagementId() {
    var id = void 0;

    try {
      id = window.Inq.API.get('engagementId');
    } catch (e) {} // eslint-disable-line no-empty

    if (id) return id;

    try {
      return window.Inq.getData().CHM.id;
    } catch (e) {} // eslint-disable-line no-empty
  }

  function getTabId() {
    var tabId = sessionStorage.getItem('nuance-tab-id');

    if (!tabId) {
      tabId = Math.random().toString().substr(2, 99);
      sessionStorage.setItem('nuance-tab-id', tabId);
    }

    return tabId;
  }

  function noId(scope) {
    return scope;
  }
});