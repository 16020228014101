define('acif/utils/index', ['exports', './isJSON', './tryCatch', './isNumber', './isTerminalNode', './string', './logger', './cookies', './underscore', './events', './throwError', './getAutomatonProp', './get', './set', './benchmark'], function (exports, _isJSON, _tryCatch, _isNumber, _isTerminalNode, _string, _logger, _cookies, _underscore, _events, _throwError, _getAutomatonProp, _get, _set, _benchmark) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _isJSON2 = _interopRequireDefault(_isJSON);

  var _tryCatch2 = _interopRequireDefault(_tryCatch);

  var _isNumber2 = _interopRequireDefault(_isNumber);

  var _isTerminalNode2 = _interopRequireDefault(_isTerminalNode);

  var _string2 = _interopRequireDefault(_string);

  var _logger2 = _interopRequireDefault(_logger);

  var _cookies2 = _interopRequireDefault(_cookies);

  var _underscore2 = _interopRequireDefault(_underscore);

  var _events2 = _interopRequireDefault(_events);

  var _throwError2 = _interopRequireDefault(_throwError);

  var _getAutomatonProp2 = _interopRequireDefault(_getAutomatonProp);

  var _get2 = _interopRequireDefault(_get);

  var _set2 = _interopRequireDefault(_set);

  var _benchmark2 = _interopRequireDefault(_benchmark);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = {
    console: _logger2.default,
    logger: _logger2.default,
    isNumber: _isNumber2.default,
    isTerminalNode: _isTerminalNode2.default,
    string: _string2.default,
    tryCatch: _tryCatch2.default,
    isJSON: _isJSON2.default,
    cookies: _cookies2.default,
    underscore: _underscore2.default,
    events: _events2.default,
    throwError: _throwError2.default,
    getPropFromAlias: getPropFromAlias,
    isTextbox: isTextbox,
    htmlEncode: htmlEncode,
    generateBreadcrumbs: generateBreadcrumbs,
    isPromise: isPromise,
    promisify: promisify,
    smartTypeCast: smartTypeCast,
    getAutomatonProp: _getAutomatonProp2.default,
    get: _get2.default,
    set: _set2.default,
    namespaceString: namespaceString,
    benchmark: (0, _benchmark2.default)()
  };


  function getPropFromAlias(obj, props) {
    props = [].concat(props);

    for (var i = 0; i < props.length; i++) {
      if (obj.hasOwnProperty([props[i]])) {
        return obj[props[i]];
      }
    }
  }

  function isTextbox() {
    var $this = $(this);
    var textboxTypes = ['date', 'datetime', 'datetime-local', 'email', 'month', 'number', 'password', 'search', 'tel', 'text', 'time', 'week', 'url'];

    return $this.is('textarea') || $this.is('input') && _.indexOf(textboxTypes, $(this).attr('type')) > -1;
  }

  // function htmlEncode(value) {
  //   // create a in-memory div, set it's inner text(which jQuery automatically encodes)
  //   // then grab the encoded contents back out.  The div never exists on the page.
  //   return $('<div/>').text(value).html();


  // }

  function htmlEncode(string) {
    var entityMap = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': '&quot;',
      "'": '&#39;',
      "/": '&#x2F;'
    };
    return String(string).replace(/[&<>"'\/]/g, function (s) {
      return entityMap[s];
    });
  }

  function generateBreadcrumbs(path) {
    var breadcrumbs = [],
        node,
        index;
    path = _.clone(path);

    while (path.length) {
      node = path.shift();
      index = _.indexOf(path, node);
      if (index > -1) {
        path = path.splice(index, path.length - index);
      } else {
        breadcrumbs.push(node);
      }
    }

    return breadcrumbs;
  }

  function smartTypeCast(value) {
    if (Number(value) && !isNaN(Number(value)) && value.length < 17) {
      value = Number(value);
    } else if (value.match(/^true$/i)) {
      value = true;
    } else if (value.match(/^false$/i)) {
      value = false;
    } else if ((0, _isJSON2.default)(value)) {
      value = JSON.parse(value);
    }

    return value;
  }

  function namespaceString(namespace, string) {
    return string.trim().split(/\s+/g).map(function (str) {
      return namespace + str;
    }).join(' ');
  }

  function isPromise(obj) {
    return typeof obj === 'object' && typeof obj.then === 'function';
  }

  function promisify(promise) {
    return isPromise(promise) ? promise : new $.Deferred().resolve(promise);
  }
});