define('acif/models/Automaton', ['exports', './Model'], function (exports, _Model) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Model2 = _interopRequireDefault(_Model);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Automaton;


  function Automaton(properties) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    _Model2.default.call(this, properties, options);

    this.loadCustomNodeProperties();

    this._originalProperties = Object.keys(this).concat('_originalProperties');
  }

  var fn = Automaton.prototype = new _Model2.default();

  fn.constructor = Automaton;

  fn._modelType = 'automaton';

  fn.getCustomProperties = function () {
    var data = {};
    for (var key in this) {
      var isCustomProperty = this.hasOwnProperty(key) && this._originalProperties.indexOf(key) === -1;
      if (isCustomProperty) {
        data[key] = this[key];
      }
    }

    return data;
  };

  fn.extend = function (data) {
    if (data && typeof data === 'object') {
      _.extend(this, data);
    }
  };

  fn.loadCustomNodeProperties = function () {
    if (!(this.proto && typeof this.proto.nodeProperties === 'object' && typeof this.nodeProperties === 'object')) {
      return;
    }

    for (var key in this.proto.nodeProperties) {
      this.nodeProperties[key] = _.extend({}, this.nodeProperties[key], this.proto.nodeProperties[key]);
    }
  };
});