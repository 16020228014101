define('acif/transitions/transitions', ['exports', '../utils', '../ci/ijsf-adapter', './TransitionEvaluator', './redirect'], function (exports, _utils, _ijsfAdapter, _TransitionEvaluator, _redirect) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  var _TransitionEvaluator2 = _interopRequireDefault(_TransitionEvaluator);

  var _redirect2 = _interopRequireDefault(_redirect);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  var isTerminalNode = _utils2.default.isTerminalNode;
  exports.default = Transitions;


  function Transitions(Automaton) {
    this.Automaton = Automaton;
    this.Redirect = new _redirect2.default(Automaton);
  }

  var fn = Transitions.prototype = { constructor: Transitions };

  // Called by:
  // - /initializer.js#loadFirstNodeOnPageLanding
  // - this.selectNextNode() (called by /view/index.js, /forms/index.js, and /chat/index.js)
  fn.loadNode = function (node) {
    var _this = this;

    if (node.chatRouter) {
      this.Automaton.Chat.resolveChatNode(node).then(function (selectedNodeId) {
        var routeToBackup = node.id !== selectedNodeId && 'node::' + node.id !== selectedNodeId;

        node = _this.Automaton.getRecord(selectedNodeId);

        if (routeToBackup) {
          _this.loadNode(node);
        } else {
          _this.Automaton.node = node;
          _this.Automaton.Chat.loadChatNode(node);
        }
      });

      return;
    }

    this.Automaton.node = node;

    this.Automaton.utils.timers.clearTimers();

    this.Automaton.Forms.clearLastFocusedElement();

    // when on mobile phones, we need to call the inqFrame
    // transition Page method to enable scrolling on the xframe
    if (_ijsfAdapter2.default.getDeviceType() === 'Phone') {
      _ijsfAdapter2.default.resetPhoneXframe();
    }

    this.runNodeEntry(node).then(function (didReTransition) {
      if (didReTransition) return;

      _this.updateDisplayedPath();

      _this.updateOutcomeType();

      _this.pollAgentAvailability();

      if (_this.Automaton.node.template) {
        _this.Automaton.View.initializeNode(node);
      }

      if (node.endSession && isTerminalNode(node)) {
        _ijsfAdapter2.default.endSession();
      }

      var questions = _this.Automaton.View.getQuestions();

      _this.Automaton.Data.triggerContentSentToCustomer(node, questions);
    });
  };

  fn.runNodeEntry = function (node) {

    var didRetransitionPromise = this.Automaton.utils.makePromise('node retransition check');

    var onEntryHookResponse = null;

    onEntryHookResponse = this.Automaton.settings.runHook('onNodeEntry');

    if (!onEntryHookResponse) {
      onEntryHookResponse = node.runHook('onEntry');
    }

    // Allow the onEntry functions to return a new node ID number that
    // the user should be transitioned to instead of the current node
    var isNumber = typeof onEntryHookResponse === 'number';
    var isHookResponseNewNodeId = isNumber && onEntryHookResponse !== node.id;

    if (isNumber) {
      _utils2.default.logger.deprecate('Hook response should be a reference string. Saw a number \'' + onEntryHookResponse + '\'');
    }

    if (_utils2.default.string.isReferenceString(onEntryHookResponse) || isHookResponseNewNodeId) {
      var potentialNextNode = this.Automaton.getRecord(onEntryHookResponse);
      if (potentialNextNode.id !== node.id) {
        node = potentialNextNode;
        this.selectNextNode(node.id);
        didRetransitionPromise.resolve(true);
        return didRetransitionPromise;
      }
    }

    if (node.onFirstEntry) {
      var path = this.Automaton.Data.storage.path;
      var isFirstEntry = path.length === 1 || _.indexOf(path, node.id) === path.length - 1;

      if (isFirstEntry) {
        node.runHook('onFirstEntry');
      }
    }

    if (node.onEntryTransition) {
      this.trigger(node.onEntryTransition).then(function (node_id) {
        didRetransitionPromise.resolve(node_id);
      }, function (error) {
        didRetransitionPromise.resolve();
      });
    }

    var didNotRedirect = !node.onEntryTransition && !onEntryHookResponse;

    if (didNotRedirect) {
      didRetransitionPromise.resolve();
    }

    return didRetransitionPromise;
  };

  fn.updateDisplayedPath = function () {
    var Automaton = this.Automaton;

    Automaton.displayedPath = [].concat(Automaton.root_path).concat(Automaton.Data.storage.path);
  };

  fn.updateOutcomeType = function () {
    var Data = this.Automaton.Data;

    if (Data.storage.path.length === 1) return;

    var lastOutcomeType = Data.storage.outcomeType;

    var outcomeType = lastOutcomeType === 'Completed' || this.Automaton.node.isOutcomeNode ? 'Completed' : 'Abandoned';

    Data.save('outcomeType', outcomeType);
  };

  fn.pollAgentAvailability = function () {
    var _this2 = this;

    var pollPropAliases = ['pollAA', 'poll_aa', 'pollAgentAvailability', 'poll_agent_availability'];
    var node = this.Automaton.node;
    var poll = node.get(pollPropAliases);

    if (!poll) {
      return;
    }

    var MINIMUM_INTERVAL = 10;
    var transition = void 0,
        targetChatNode = void 0,
        toggleClassName = void 0,
        callback = void 0;

    var interval = typeof poll === 'number' ? poll : poll.interval;
    interval = Math.max(interval || 30, MINIMUM_INTERVAL);

    // When polling, we will either trigger a transition event or invoke a custom callback - never both.
    var willTriggerTransition = !(poll.target || poll.toggleClass || poll.callback);

    if (willTriggerTransition) {
      transition = typeof poll.transition === 'string' ? poll.transition : 'pollAA';
    } else {
      targetChatNode = poll.target ? this.Automaton.getRecord(poll.target) : null;
      toggleClassName = poll.toggleClass || 'agents-ready';
      callback = typeof node[poll.callback] === 'function' ? node[poll.callback] : $.noop;
    }

    this.Automaton.utils.timers.setInterval(function () {
      if (willTriggerTransition) {
        _this2.trigger(transition);
      } else if (targetChatNode) {
        _this2.Automaton.Chat.checkAA(targetChatNode).then(function (response) {
          var toggleAction = response.AA ? 'addClass' : 'removeClass';

          _this2.Automaton.View.$viewport[toggleAction](toggleClassName);
          callback(response);
        });
      }
    }, interval * 1000);
  };

  fn.trigger = function (transitionEventName) {
    var _this3 = this;

    // If a transition successfully occurs, return the id of the selected node.  Otherwise, return false.
    return this.evaluate(transitionEventName).then(function (node_id) {
      _this3.selectNextNode(node_id, transitionEventName);
      return node_id;
    }, function (error) {
      return false;
    });
  };

  fn.evaluate = function (transitionEventName) {
    var _this4 = this;

    return new _TransitionEvaluator2.default(transitionEventName, this.Automaton).getPromise().then(function (selectedNodeId) {
      var selectedNode = _this4.Automaton.getRecord(selectedNodeId);

      if (selectedNode.chatRouter) {
        return _this4.Automaton.Chat.resolveChatNode(selectedNode);
      } else {
        return selectedNodeId;
      }
    });
  };

  fn.setCurrentNode = function (node, transitionEventName) {
    var Automaton = this.Automaton;
    var Data = Automaton.Data;


    Data.CRM.addNodeSelection(transitionEventName || node.id);

    Data.triggerCustomerResponded(node);

    Data.save({
      previousNodeID: Automaton.node.id,
      currentNodeModulePath: node.modulePath
    });

    Automaton.node = node;
  };

  // called by /view/index.js, /forms/index.js, and /chat/index.js
  fn.selectNextNode = function (nextNodeID, transitionEventName) {
    var _this5 = this;

    _utils2.default.benchmark('btn click').mark('selectNextNode');

    var Automaton = this.Automaton;
    var Data = Automaton.Data,
        View = Automaton.View,
        Chat = Automaton.Chat;

    var node = Automaton.getRecord(nextNodeID);

    if (node.chatRouter) {
      if (Automaton.node.onExit) {
        Automaton.node.runHook('onExit');
      }

      return Chat.resolveChatNode(node).then(function (selectedNodeId) {
        var routeToBackup = node.id !== selectedNodeId && 'node::' + node.id !== selectedNodeId;

        if (routeToBackup) {
          _this5.selectNextNode(selectedNodeId, transitionEventName);
        } else {
          _this5.setCurrentNode(node, transitionEventName);
          Chat.loadChatNode(node);
        }
      });
    }

    var previousNode = Automaton.node;
    this.setCurrentNode(node, transitionEventName);

    var path = Data.storage.path;
    // Only update path array with next node if the last node
    // of the path array is different than the next node
    if (path[path.length - 1] !== node.id) {
      path.push(node.id);
      Data.save({ path: path });
    }

    if (previousNode.onExit) {
      previousNode.runHook('onExit');
    }

    if (node.isExitNode && node.onExit) {
      node.runHook('onExit');
    }

    if (!!node.isExitNode && !node.redirectUrl) {
      Automaton.Data.DW.updateOutcomeType(node);

      Automaton.close();
    } else if (node.redirectTarget === 'current') {
      this.Redirect.current(node);
    } else {
      _utils2.default.benchmark('btn click').mark('me.loadNode(node);');
      this.loadNode(node);
    }

    if (node.redirectTarget === 'popup') {

      this.Redirect.openPopup(node);

      if (node.isExitNode) {
        View.Exit.closeCI();
      }
    }

    return false;
  };

  fn.getBreadcrumbs = function () {
    var Automaton = this.Automaton;
    var breadcrumbs = _utils2.default.generateBreadcrumbs(Automaton.displayedPath);

    return _.map(breadcrumbs, function (reference) {
      return Automaton.getRecord(reference);
    });
  };
});