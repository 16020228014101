define('boot/index', ['exports', 'storage', 'acif'], function (exports, _storage, _acif) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var isStorageIframe = !!window.name.match('acif');
    var isMainAcifFrame = !(window.inQ && window.inQ.ENV === 'test');

    if (isStorageIframe) {
      (0, _storage2.default)();
    } else if (isMainAcifFrame) {
      window.ACIF = parent.inQ.ACIF || {};
      parent.inQ.ACIF = ACIF;
      (0, _storage2.default)();
      (0, _acif2.default)();
    }
  };

  var _storage2 = _interopRequireDefault(_storage);

  var _acif2 = _interopRequireDefault(_acif);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }
});