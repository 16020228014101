define('storage/iframe/index', ['exports', '../utils/frame-messenger', './storage'], function (exports, _frameMessenger, _storage) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = storageIframe;

  var _frameMessenger2 = _interopRequireDefault(_frameMessenger);

  var _storage2 = _interopRequireDefault(_storage);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function storageIframe() {
    var frameMessenger = new _frameMessenger2.default({
      frame: window,
      name: 'tc-storage-iframe',
      targetFrame: parent,
      targetName: 'tc-storage-top-frame'
    });

    var storage = void 0;

    try {
      // frameMessenger must be set first incase window.localStorage fails and sessionStorage fallback is used
      var nameParams = JSON.parse(decodeURIComponent(window.name));
      frameMessenger.targetOrigin = nameParams.targetOrigin;

      // if access to localStorage is not allowed, the following line will cause the browser to throw an error.
      // Causing the catch method to be executed and acif will use the alternative storage implementation using session-storate-api
      window.localStorage;

      storage = new _storage2.default(nameParams);
      var data = storage.getAllData();

      frameMessenger.postMessage({ code: 'initial storage data', storage: data });
    } catch (e) {
      frameMessenger.postMessage({ code: 'local storage failed' });
    }

    frameMessenger.onMessage(function (data, callback) {
      var method = storage[data.method];
      var err = void 0;

      if (typeof method === 'function') {
        try {
          var response = method.call(storage, data.params);
          return callback(null, response);
        } catch (e) {
          err = e;
        }
      } else {
        err = '"' + data.method + '" is not a valid TcSorage method.';
      }

      if (err) {
        callback(err);

        /* eslint-disable no-console */
        if (window.console && typeof console.error === 'function') {
          console.error(err);
        }
      }
    });
  }
});