define('acif/ci/ijsf-adapter', ['exports', '../environment/get-env-var', '../utils/tryCatch'], function (exports, _getEnvVar, _tryCatch) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _getEnvVar2 = _interopRequireDefault(_getEnvVar);

  var _tryCatch2 = _interopRequireDefault(_tryCatch);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function getFlashPeer() {
    return inqFrame.Inq.FlashPeer;
  }

  // Globally expose some methods used within automaton configurations
  globalize({
    setAutomatonDataMap: setAutomatonDataMap,
    showAutomaton: showAutomaton,
    isChatConnected: isChatConnected
  });

  exports.default = {
    addListener: addListener,
    closeCI: closeCI,
    endSession: endSession,
    engageAudio: engageAudio,
    engageChat: engageChat,
    engageVideo: engageVideo,
    fireAssistedEvent: fireAssistedEvent,
    fireCustomEvent: fireCustomEvent,
    getActiveRule: getActiveRule,
    getAgentsAvailabilityCheckUrl: getAgentsAvailabilityCheckUrl,
    getAgentAttr: getAgentAttr,
    getAgentGroupId: getAgentGroupId,
    getAgentGroupName: getAgentGroupName,
    getAgentId: getAgentId,
    getAutomatonDataMap: getAutomatonDataMap,
    getBrowserTypeAndVersion: getBrowserTypeAndVersion,
    getBusinessRuleAgentGroupId: getBusinessRuleAgentGroupId,
    getBusinessUnitId: getBusinessUnitId,
    getBusinessUnitName: getBusinessUnitName,
    getBusinessRuleName: getBusinessRuleName,
    getClientMediaBaseUrl: getClientMediaBaseUrl,
    getCustomerId: getCustomerId,
    getCustomerCountryCode: getCustomerCountryCode,
    getCustomerRegionCode: getCustomerRegionCode,
    getCustomerZipCode: getCustomerZipCode,
    getDeviceType: getDeviceType,
    getEngagementId: getEngagementId,
    getPageId: getPageId,
    getPageMarker: getPageMarker,
    getSiteId: getSiteId,
    growLayer: growLayer,
    hideLayer: hideLayer,
    hideLayerAndEndChat: hideLayerAndEndChat,
    isChatConnected: isChatConnected,
    isMinimized: isMinimized,
    isWebRTCCapable: isWebRTCCapable,
    logDiagnosticInfoToServer: logDiagnosticInfoToServer,
    minimizeStage: minimizeStage,
    moveStage: moveStage,
    processSurveyAlerts: processSurveyAlerts,
    registerIJSFListeners: registerIJSFListeners,
    removeCircularTabbing: removeCircularTabbing,
    resetPhoneXframe: resetPhoneXframe,
    resizeStage: resizeStage,
    restoreStage: restoreStage,
    requestTranscript: requestTranscript,
    sendAutomatonMessage: sendAutomatonMessage,
    sendDatapassMessage: sendDatapassMessage,
    sendMessageAsCustomer: sendMessageAsCustomer,
    sendSystemMessage: sendSystemMessage,
    setAutomatonDataMap: setAutomatonDataMap,
    setCircularTabbing: setCircularTabbing,
    setElementPosition: setElementPosition,
    setEngageParameters: setEngageParameters,
    setFocusOnChatInput: setFocusOnChatInput,
    shrinkLayer: shrinkLayer,
    showAutomaton: showAutomaton,
    showLayer: showLayer,
    updateInputListeners: updateInputListeners,
    uploadFile: uploadFile
  };


  function addListener(eventName, callback) {
    var onEventName = eventName.match(/^on/) ? eventName : 'on' + eventName;
    return getFlashPeer().addListener(_defineProperty({}, onEventName, callback));
  }

  function closeCI() {
    ACIF.Utils.logger('Closing CI');
    // getFlashPeer().closeChat method doesn't close the ci in persistent chat window
    return getFlashPeer().ciCloseChat();
  }

  function endSession() {
    return getFlashPeer().ciPreparingClosingChat();
  }

  function engageAudio(chatParams) {
    inqFrame.com.inq.flash.client.control.WebRTCMgr.engageAudio(chatParams);
  }

  function engageChat(chatParams) {
    return getFlashPeer().ciEngageChat(chatParams);
  }

  function engageVideo(chatParams) {
    inqFrame.com.inq.flash.client.control.WebRTCMgr.engageVideo(chatParams);
  }

  function fireAssistedEvent() {
    return Inq.fireCustomEvent('AssistedEvent');
  }

  var noOpListeners = [];

  function fireCustomEvent(eventName, data, rule) {
    // Add a no-op handler before firing the event in case
    // no external listners are active (IJSF logs an error
    // when events are triggered if no listeners are registered)
    if (noOpListeners.indexOf(eventName) === -1) {
      addListener(eventName, function () {});
    }

    return Inq.fireCustomEvent(eventName, data, rule);
  }

  function getActiveRule() {
    return getFlashPeer().getChatRule();
  }

  function getAgentAttr(attrName) {
    return (0, _tryCatch2.default)(function () {
      var chat = inqFrame.Inq.CHM.getChat();
      var attrs = chat && chat.getChatData().agtAttrs;

      if (!attrs) return;

      attrName = attrName.toLowerCase();

      for (var key in attrs) {
        if (key.toLowerCase() === attrName) {
          return decodeURIComponent(attrs[key]);
        }
      }
    });
  }

  function getAgentsAvailabilityCheckUrl() {
    return getFlashPeer().getAgentsAvailabilityCheckUrl();
  }

  function getAgentGroupId() {
    var ruleId = getBusinessRuleId();
    return getBusinessRuleAgentGroupId(ruleId);
  }

  function getAgentGroupName() {
    return getFlashPeer().getAgentGroupName();
  }

  function getAgentId() {
    return getFlashPeer().getAgentID();
  }

  function getAutomatonDataMap() {
    return getFlashPeer().getAutomatonSpecData() || {};
  }

  function getBrowserTypeAndVersion() {
    return getFlashPeer().getBrowserTypeAndVersion();
  }

  function getBusinessRuleAgentGroupId(ruleId) {
    return getFlashPeer().getBuRuleAgentGroupID(ruleId);
  }

  function getBusinessRuleId() {
    return getFlashPeer().getBrID();
  }

  function getBusinessRuleName() {
    return getFlashPeer().getBrName();
  }

  function getBusinessUnitId() {
    return getFlashPeer().getBusinessUnitID();
  }

  function getBusinessUnitName() {
    return getFlashPeer().getBusinessUnitName();
  }

  function getClientMediaBaseUrl() {
    var mediaBaseUrl = getFlashPeer().getMediaBaseURL();
    var siteId = getSiteId();
    return mediaBaseUrl + '/sites/' + siteId;
  }

  function getCustomerId() {
    return getFlashPeer().getCustID();
  }

  function getCustGeoData() {
    try {
      return getFlashPeer().getCustGeoData() || {};
    } catch (e) {
      return {};
    }
  }

  function getCustomerCountryCode() {
    return getCustGeoData().country_code;
  }

  function getCustomerRegionCode() {
    return getCustGeoData().region_code;
  }

  function getCustomerZipCode() {
    return getCustGeoData().zip_code;
  }

  function getDeviceType() {
    return getFlashPeer().getDeviceType();
  }

  function getEngagementId() {
    return inqFrame.Inq.CHM.getChatID();
  }

  function getPageId() {
    return getFlashPeer().getPageID();
  }

  function getPageMarker() {
    return getFlashPeer().getPageMarker();
  }

  function growLayer(layerName) {
    return getFlashPeer().ciGrowLayer(layerName);
  }

  function getSiteId() {
    return inqFrame.Inq.siteID;
  }

  function hideLayer(layerName) {
    return getFlashPeer().ciHideLayer(layerName);
  }

  function hideLayerAndEndChat() {
    return window.inqFrame.com.inq.flash.client.control.XFrameWorker.hideLayerAndEndChat.apply(null, arguments);
  }

  function isChatConnected() {
    return getFlashPeer().isChatConnected();
  }

  function isMinimized() {
    return getFlashPeer().ciIsMinimized();
  }

  function isWebRTCCapable() {
    return inqFrame.com.inq.flash.client.control.isWebRTCCapable();
  }

  function logDiagnosticInfoToServer(message) {
    var siteConstants = getFlashPeer().getSiteConstants() || {};
    var shouldLogDiagnosticInfo = siteConstants.ACIF_DIAGNOSTIC_LOGGING_ENABLED || true;
    if (shouldLogDiagnosticInfo) {
      return getFlashPeer().logInfoToServer('ACIF-Diagnostics - ' + message);
    }
  }

  function minimizeStage() {
    return getFlashPeer().ciMinimize.apply(null, arguments);
  }

  /**
   * Move chat window.
   *
   * @param location the position specifier for where to position the client skin.
   * @param x the horizontal position of left-top corner of the chat window.
   * @param y the vertical position of left-top corner of the chat window.
   * Parameters x and y used if {@code location) is ABSOLUTE or RELATIVE.
   * Possible values of location are:
   * "UPPER_CENTER", "TOP_CENTER"
   * "UPPER_RIGHT", "TOP_RIGHT"
   * "UPPER_LEFT", "TOP_LEFT"
   * "CENTER_LEFT"
   * "CENTER_RIGHT"
   * "LOWER_LEFT", "BOTTOM_LEFT"
   * "LOWER_CENTER", "BOTTOM_CENTER"
   * "LOWER_RIGHT", "BOTTOM_RIGHT"
   * "POP_UNDER_CENTER"
   * "ABSOLUTE"
   * "RELATIVE"
   * "CENTER"
   */
  function moveStage(location, x, y) {
    getFlashPeer().ciMoveStage(location, x, y);
  }

  function registerIJSFListeners(listeners) {
    var ENV = (0, _getEnvVar2.default)('nuance-env') || 'production';

    _.each(listeners, function (listener, listenerName) {
      listeners[listenerName] = function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        switch (ENV) {
          case 'production':
            try {
              listener.apply(null, args);
            } catch (e) {
              ACIF.Utils.logger(e, 'error');
              throw e;
            }
            break;

          case 'test':
            // No catching any errors while testing
            listener.apply(null, args);
            break;

          case 'development':
            // Allow errors to be thrown while in dev mode
            // We use setTimeout to avoid errors being swallowed
            // by the IJSF try/catch statements
            setTimeout(function () {
              listener.apply(null, args);
            });
        }
      };
    });

    getFlashPeer().addListener(listeners);
  }

  function processSurveyAlerts(data) {
    return getFlashPeer().submitNativePostChatSurvey(data);
  }

  function removeCircularTabbing() {
    return getFlashPeer().ciRemoveCircularModalWindow();
  }

  function resetPhoneXframe() {
    return getFlashPeer().ciTransitionPage();
  }

  function resizeStage(stageWidth, stageHeight) {
    return getFlashPeer().ciResizeStage(stageWidth, stageHeight);
  }

  function restoreStage() {
    return getFlashPeer().ciRestore.apply(null, arguments);
  }

  function requestTranscript(email, customerName) {
    if (email) {
      return getFlashPeer().requestTranscript(email, customerName);
    }
  }

  function sendAutomatonMessage(msgType, message, cssClass) {
    // msgType = 'datapass' || 'system'
    return Inq.sendAutomatonMessage(msgType, message, cssClass);
  }

  function sendDatapassMessage(message) {
    message = typeof message === 'object' ? message : { 'Automaton-Datapass': message };
    var engagementOptions = {
      agentID: getAgentId(),
      engagementID: getEngagementId()
    };
    return Inq.sendAutomatonMessage('datapass', _.extend(message, engagementOptions));
  }

  function sendSystemMessage(message) {
    var cssClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

    // Message renders as HTML in the chat window
    return Inq.sendAutomatonMessage('system', message, 'automaton-system-message ' + cssClass);
  }

  /**
   * send message as customer
   * @param text
   * @return {*}
   */
  function sendMessageAsCustomer(text) {
    return getFlashPeer().ciSendAutomatonDataAsCustomerMessage(text);
  }

  function setAutomatonDataMap() {
    return getFlashPeer().ciSetAutomatonDataMapXcd.apply(null, arguments);
  }

  function setCircularTabbing(div, firstEl, lastEl) {
    return getFlashPeer().ciSetCircularModalWindow(div, firstEl, lastEl);
  }

  function setElementPosition(elementId, attributes) {
    return getFlashPeer().setElementPosition(elementId, attributes);
  }

  function setEngageParameters(chatParams) {
    return getFlashPeer().ciSetEngageParameters(chatParams);
  }

  function setFocusOnChatInput() {
    return getFlashPeer().ciSetFocusOnChatInputField();
  }

  function showAutomaton() {
    return getFlashPeer().showAutomaton.apply(null, arguments);
  }

  function showLayer(layerName) {
    return getFlashPeer().ciShowLayer(layerName);
  }

  function shrinkLayer(layerName) {
    return getFlashPeer().ciShrinkLayer(layerName);
  }

  function updateInputListeners(hash, $inputs) {
    // inqFrame.Inq.xforms only available on mobile
    var xforms = inqFrame.Inq.xforms;
    var hasBindOrbeonListners = xforms && xforms.bindOrbeonListeners;
    if (!hasBindOrbeonListners) return;

    var dtid = 'acif::' + hash;
    xforms.unbindOrbeonListeners(dtid);
    if ($inputs) return xforms.bindOrbeonListeners(dtid, $inputs);
  }

  function uploadFile(event, params) {
    return inqFrame.com.inq.flash.client.control.UploadHandler.engageFileUpload(event, params);
  }

  function globalize(methods) {
    Object.keys(methods).forEach(function (methodName) {
      window[methodName] = methods[methodName];
    });
  }
});