define('storage/utils/set', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = set;
  function set(obj, path, value) {
    if (typeof obj !== 'object') {
      throw new Error('set() expected an object as the first argument. Received ' + obj);
    } else if (typeof path !== 'string') {
      throw new Error('set() expected a string as the second argument. Received ' + path);
    }

    var parts = path.split('.');
    var key = parts.shift();

    if (parts.length) {
      var subObj = obj[key] = obj[key] || {};
      set(subObj, parts.join('.'), value);
    } else {
      obj[key] = value;
    }
  }
});