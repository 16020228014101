define('acif/data/local-storage', ['exports', '../utils'], function (exports, _utils) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = StorageManager;


  function StorageManager(Automaton) {
    this.Automaton = Automaton;
  }

  var fn = StorageManager.prototype = { constructor: StorageManager };

  fn.init = function () {
    var _this = this;

    var Automaton = this.Automaton;
    var deferred = Automaton.utils.makePromise('local-storage initializing');
    var options = Automaton.options;
    var startedBy = options.startedBy;

    this.scope = options.context === 'ci' ? 'engagement' : 'tab';
    this.storageKey = 'automaton-' + Automaton.id + '|' + startedBy.name + '|' + options.location;

    parent.inQ.Storage.get(this.storageKey, this.scope, function (err, data) {
      if (err) {
        return deferred.reject(err);
      } else if (data) {
        _this.data = data;
      } else {
        _this.data = {};
        _this.persist();
      }

      deferred.resolve(_this.data);
    });

    return deferred;
  };

  fn.get = function (path, defaultValue) {
    var val = _utils2.default.get(this.data, path);

    if (val === undefined && defaultValue !== undefined) {
      this.save(path, defaultValue);
      val = defaultValue;
    }

    return val;
  };

  fn.save = function (path, value) {
    _utils2.default.set(this.data, path, value);
    this.persist();
  };

  fn.persist = _.debounce(function () {
    this._immediatePersist();
  }, 100);

  fn._immediatePersist = function () {
    parent.inQ.Storage.set(this.storageKey, this.data, this.scope);
  };
});