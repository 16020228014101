define('acif/utils/isTerminalNode', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isTerminalNode;
  function isTerminalNode(node) {
    var $view = node.Automaton.View.$viewport;
    var selectors = ['[acif-node]', '[acif-transition]', '[acif-next-node]', '[acif-next-transition]', '[acif-action][acif-action!="exit"]'];
    var hasButtonTransitions = $view.find(selectors.join(',')).filter(':visible').length;
    var hasCustomDataTransitions = _.isObject(node.transitions) && Object.keys(node.transitions).length > 0;
    var isTerminal = !(hasButtonTransitions || hasCustomDataTransitions);
    return isTerminal;
  }
});