define("acif/environment/globals", ["exports"], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _win = void 0;

    try {
      _win = window.top;
    } catch (e) {
      _win = parent.parent;
    }

    return {
      window: _win,
      location: _win.location
    };
  };
});