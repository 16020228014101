define('acif/transitions/redirect', ['exports', '../utils'], function (exports, _utils) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Redirect;


  function Redirect(Automaton) {
    this.Automaton = Automaton;
    this.env = Automaton.Env;
  }

  var fn = Redirect.prototype = { constructor: Redirect };

  fn.openPopup = function (node) {
    this.availHeight = this.env.win.screen.availHeight;
    this.availWidth = this.env.win.screen.availWidth;

    var redirectWidth = this.Automaton.getProp('redirectWidth', node);
    var redirectHeight = this.Automaton.getProp('redirectHeight', node);
    var width = Math.min(redirectWidth || 700, this.availWidth);
    var height = Math.min(redirectHeight || 500, this.availHeight);
    var popupSettings = 'left=0,top=0,width=' + width + ',height=' + height + ',toolbar=1,resizable=1,scrollbars=1';
    var popup = window.open(node.redirectUrl, '_blank', popupSettings);

    if (!popup) {
      _utils2.default.console('Popup window failed to load.', 'error');
    } else {
      this.popup = popup;

      if (isSameOrigin(this.env.top.location.href, node.redirectUrl)) {
        // Handling of popup windows cannot be placed in any asynchronous callbacks
        // in order to avoid popup blockers.
        this.Automaton.utils.timers.setTimeout(this.positionPopup.bind(this, node), 100);
      }
    }
  };

  fn.positionPopup = function () {
    var availWidth = this.availWidth;
    var availHeight = this.availHeight;
    var redirectPosition = this.Automaton.getProp('redirectPosition', this.node);
    var popup = this.popup;
    var popupWidth = popup.outerWidth || popup.document.documentElement.offsetWidth || popup.document.body.offsetWidth;
    var popupHeight = popup.outerHeight || popup.document.documentElement.offsetHeight || popup.document.body.offsetHeight;
    var topPx = 0;
    var left = 0;

    switch (redirectPosition) {
      case 'cc':
        // center center
        left = 0.5 * (availWidth - popupWidth);
        topPx = 0.5 * (availHeight - popupHeight);
        break;
      case 'tl':
        // top left
        topPx = 0;
        left = 0;
        break;
      case 'bl':
        // bottom left
        left = 0;
        topPx = availHeight - popupHeight;
        break;
      case 'tr':
        // top right
        topPx = 0;
        left = availWidth - popupWidth;
        break;
      case 'br':
        // bottom right
        topPx = availHeight - popupHeight;
        left = availWidth - popupWidth;
        break;
    }

    popup.moveTo(left, topPx);
  };

  fn.current = function (node) {
    var View = this.Automaton.View;
    var onTargetPage = View.Env.location.href === node.redirectUrl;

    if (node.isExitNode) {
      if (onTargetPage) {
        View.Exit.closeCI();
      } else {
        this.exitThenRedirectCurrent(node);
      }
    } else if (!onTargetPage) {

      this.toggleRedirectClass(View.$viewContainer, View.NS);

      this.openCurrentNow(node);
    }
  };

  fn.exitThenRedirectCurrent = function (node) {
    var delay = 1000;
    var document = this.Automaton.Env.win.top.document;
    var script = document.createElement('script');
    var code = 'setTimeout(function() {location.href = "' + node.redirectUrl + '";}, ' + delay + ');';

    script.type = 'text/javascript';

    try {
      script.innerHTML = code;
    } catch (e) {
      // IE8 and lower do not allow setting innerHTML of script elements
      script.text = code;
    }

    $('body', document)[0].appendChild(script);

    this.Automaton.Data.DW.updateOutcomeType(node);
    this.Automaton.close();
  };

  fn.openCurrentNow = function (node) {
    this.Automaton.Env.location.href = node.redirectUrl;
  };

  fn.toggleRedirectClass = function ($el, NS) {
    //////////////////////////////////////////////////////////////////
    // Redirecting in the current page often has a delay if the     //
    // website is slow. Therefore, utilizing the "redirecting"      //
    // className allows one to style the viewport differently when  //
    // the user is about to be redirected in order to give a visual //
    // indication that the guide is responding to the user's click. //
    //////////////////////////////////////////////////////////////////
    $el.addClass(NS + 'redirecting');
    if (!$el.find('.' + NS + 'redirect-panel').length) {
      $('<div>').addClass(NS + 'redirect-panel').appendTo($el);
    }
  };

  function isSameOrigin(url1, url2) {
    if (protocol(url1) !== protocol(url2)) {
      return false;
    }

    if (host(url1) !== host(url2)) {
      return false;
    }

    if (port(url1) !== port(url2)) {
      return false;
    }

    return true;

    function protocol(url) {
      return url.replace(/:.*/, ':');
    }

    function host(url) {
      return url.replace(/^(.*\/\/)([^:\/]+)(.*$)/, '$2');
    }

    function port(url) {
      var match = url.match(/^.*\/\/.*:([^\/]*).*/);
      return match ? match[1] : '';
    }
  }
});