define('acif/view/formify/precompiled/formify-type-radio-scale', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (obj) {
    var __t,
        __p = '',
        __j = Array.prototype.join,
        print = function print() {
      __p += __j.call(arguments, '');
    };
    with (obj || {}) {
      __p += '<div class="formify-group" acif-fieldset-group>\n  <h2>' + ((__t = field.label) == null ? '' : __t) + '</h2>\n    <div class="formify-scale-row formify-scale-header">\n    <div class="formify-scale-cell formify-scale-statement"></div>\n      ';
      _.each(field.scale, function (rawScaleItem) {
        var scaleItem = {};
        scaleItem.label = rawScaleItem.label || rawScaleItem;
        scaleItem.value = rawScaleItem.value || rawScaleItem;

        __p += '\n      <div class="formify-scale-cell">\n      ' + ((__t = scaleItem.label) == null ? '' : __t) + '\n      </div>\n      ';
      });
      __p += '\n    </div>\n  ';
      _.each(field.options, function (rawOption) {
        var option = {};
        option.label = rawOption.label || rawOption;
        option.id = rawOption.id || _.dasherize(rawOption);

        __p += '\n    <fieldset class="formify-scale-item" acif-field-type="radio" name="' + ((__t = option.id) == null ? '' : __t) + '" ' + ((__t = field._required_attr) == null ? '' : __t) + '>\n      <div class="formify-scale-row">\n      <div class="formify-scale-cell formify-scale-statement">\n        <legend class="formify-legend">' + ((__t = option.label) == null ? '' : __t) + '</legend>\n      </div>\n      ';
        _.each(field.scale, function (rawScaleItem) {
          var scaleItem = {};
          scaleItem.label = rawScaleItem.label || rawScaleItem;
          scaleItem.value = rawScaleItem.value || rawScaleItem;

          __p += '\n      <label class="formify-scale-cell formify-scale-option">\n        <input class="formify-scale-input" type="radio" value="' + ((__t = scaleItem.value) == null ? '' : __t) + '" />\n        <span class="formify-scale-label">' + ((__t = scaleItem.label) == null ? '' : __t) + '</span>\n      </label>\n      ';
        });
        __p += '\n      </div>\n    </fieldset>\n  ';
      });
      __p += '\n  ' + ((__t = field.validate._html) == null ? '' : __t) + '\n</div>';
    }
    return __p;
  };
});