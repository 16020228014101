define('acif/utils/set', ['exports', './throwError'], function (exports, _throwError) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _throwError2 = _interopRequireDefault(_throwError);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = set;


  function set(obj, path, value) {
    if (typeof obj !== 'object') {
      (0, _throwError2.default)('set() expected an object as the first argument. Received ' + obj);
    } else if (typeof path !== 'string') {
      (0, _throwError2.default)('set() expected a string as the second argument. Received ' + path);
    }

    var parts = path.split('.');
    var key = parts.shift();

    if (parts.length) {
      var subObj = obj[key] = obj[key] || {};
      set(subObj, parts.join('.'), value);
    } else {
      obj[key] = value;
    }
  }
});