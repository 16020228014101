define('acif/automaton/utils/index', ['exports', './timers', './promises'], function (exports, _timers, _promises) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _timers2 = _interopRequireDefault(_timers);

  var _promises2 = _interopRequireDefault(_promises);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Utils;


  function Utils(Automaton) {
    this.timers = new _timers2.default();
  }

  var fn = Utils.prototype = { constructor: Utils };

  fn.makePromise = function (description) {
    return _promises2.default.makePromise(this.timers, description);
  };

  fn.doAfter = function (condFn, afterFn, poll, maxWait) {
    if (arguments.length === 1) {
      var options = arguments[0];
      condFn = options.condFn;
      afterFn = options.afterFn;
      poll = options.poll;
      maxWait = options.maxWait;
    }

    var self = this;
    var checkLaterTimer = void 0;

    this.timers.setTimeout(function () {
      clearTimeout(checkLaterTimer);
    }, maxWait || 10000);

    (function checkLater() {
      if (condFn()) {
        afterFn();
      } else {
        checkLaterTimer = self.timers.setTimeout(checkLater, poll || 10);
      }
    })();
  };
});