define('acif/data/data-warehouse', ['exports', '../utils/hash-code', '../ci/ijsf-adapter', '../utils', '../utils/logger'], function (exports, _hashCode, _ijsfAdapter, _utils, _logger) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _hashCode2 = _interopRequireDefault(_hashCode);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  var _utils2 = _interopRequireDefault(_utils);

  var _logger2 = _interopRequireDefault(_logger);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var get = _utils2.default.get,
      isTerminalNode = _utils2.default.isTerminalNode;
  exports.default = DW;


  // http://stash.touchcommerce.com/projects/RT/repos/dw/browse/DataWarehouseJobs/source/com/touchcommerce/data/model/dimensions/DefinedField.java
  var DwFieldNameMap = {
    nodeId: 'unique_node_id',
    nodeName: 'custom.decisiontree.nodeID',
    questions: 'custom.decisiontree.questions',
    questionIds: 'custom.decisiontree.questionIDs',
    answers: 'custom.decisiontree.answers',
    answerIds: 'custom.decisiontree.answerIDs',
    answerNumbers: 'custom.decisiontree.answersNumeric',
    answerTypes: 'custom.decisiontree.answerTypes',
    nodeAttributes: 'automatonNodeAttributes',
    automatonAttributes: 'automatonAttributes',
    outcomeType: 'automaton.outcomeType',
    outcomeMessage: 'automaton.outcome'
  };

  function DW(Data) {
    this.Automaton = Data.Automaton;
    this.Storage = Data.Storage;
    this.CRM = Data.CRM;
    this.isLogInProgress = false;

    this.queue = this.Storage.get('dw.queue', []);
    if (this.queue.length) {
      this.sendWithDebounce();
    }

    // eventHistory contains a persistent log of all the automaton
    // events that have fired during the current automaton engagement.
    // It exists only for debugging purposes currently.
    this.eventHistory = this.Storage.get('dw.eventHistory', []);
  }

  var fn = DW.prototype = { constructor: DW };

  /**
   * The `automatonStartedBy` dimension expects a string in the format of:
   * "agent,<agentID>", ex: "agent,john_agt@touchcommerce.com"
   * "br,<brID>", ex: "br,2127"
   * "automaton,<automatonId>"
   *
   * http://confluence.touchcommerce.com/display/dev/TouchCommerce+Data+Events#TouchCommerceDataEvents-automaton.started
   */
  fn.automatonStarted = function (startedBy) {
    if (this.Automaton.isPreChat()) {
      this.triggerEngagementRequested();
    }

    try {
      this._log("started", {
        automatonName: this.Automaton.settings.name,
        automatonStartedBy: startedBy.type + "," + startedBy.id,
        startedIn: "chat", //@todo: updated startedIn once the ACIF supports embedded automatons. startedIn also accepts "div" and "ai"
        countryCode: _ijsfAdapter2.default.getCustomerCountryCode(),
        regionCode: _ijsfAdapter2.default.getCustomerRegionCode()
      });

      this.Storage.save("state.started", true);
    } catch (error) {
      _logger2.default.error("Failed to log automaton.started event: " + error + "\n" + error.stack);
      throw error;
    }
  };

  fn.triggerEngagementRequested = function () {
    this._log('engagement.requested', {
      _domain: 'engagement',
      evt: 'requested',
      'automaton.automatonID': this.Automaton.id,
      automatonType: 'acif_automaton',
      resourceNeeded: 'automaton',
      countryCode: _ijsfAdapter2.default.getCustomerCountryCode(),
      regionCode: _ijsfAdapter2.default.getCustomerRegionCode()
    });
  };

  fn.getAutomatonAttr = function (key) {
    var path = 'automatonAttributes.' + key;
    return this.Automaton.Data.get(path);
  };

  fn.setAutomatonAttr = function (key, val) {
    var path = 'automatonAttributes.' + key;
    return this.Automaton.Data.save(path, val);
  };

  fn.removeAutomatonAttr = function (key) {
    var path = 'automatonAttributes.' + key;
    return this.Automaton.Data.save(path, undefined);
  };

  // http://confluence.touchcommerce.com/display/dev/TouchCommerce+Data+Events#TouchCommerceDataEvents-automaton.contentSentToCustomer
  fn.contentSentToCustomer = function (enteringNode, questions) {
    if (!questions.length) {
      questions = [this.CRM.getCurrentQuestion()];
    }

    var questionTextArray = questions.map(function (q) {
      return q.questionText;
    });
    var questionIdArray = questions.map(function (q) {
      return q.questionId;
    });
    var nodeAttributes = getStandardNodeAttributes(enteringNode);
    var attrs = _.extend({}, nodeAttributes, enteringNode.nodeAttributes);
    var nodeAttrsList = toAttrsList(attrs);

    if (typeof enteringNode.automatonAttributes === 'object') {
      var automatonAttributes = _.extend({}, this.Automaton.Data.get('automatonAttributes'), enteringNode.automatonAttributes);

      this.Automaton.Data.save('automatonAttributes', automatonAttributes);
    }

    this.updateOutcomeType(enteringNode);

    var originalAutomatonType = this.Automaton.settings.type;

    var isTerminalEnteringNode = isTerminalNode(enteringNode);

    var isSatisfactionSurvey = originalAutomatonType === 'satisfactionSurvey' && !this.Storage.get('hasTransitioned');

    var isOnInitialNode = this.Automaton.node.id === this.Automaton.displayedPath[0];

    var shouldReportNewAutomaton = !isTerminalEnteringNode && isSatisfactionSurvey && !isOnInitialNode;

    var automatonTransitionType = get(enteringNode, 'automatonTransition');
    var shouldTriggerTransitionEvent = automatonTransitionType && automatonTransitionType !== originalAutomatonType;

    if (shouldTriggerTransitionEvent) {
      // automaton.transition event, created in ENH-2182, allows ACIF to report automaton as completed
      // and continue with a transitioned experience (e.g. satisfactionSurvey -> guide )
      this._log('transition', {
        transitionFrom: originalAutomatonType,
        transitionTo: automatonTransitionType,
        outcomeType: 'Completed',
        outcomeMessage: this.Storage.get('outcomeMessage')
      });
      this.Storage.save('hasTransitioned', true);
    } else if (shouldReportNewAutomaton) {
      // custom event logic was required because satisfaction surveys with transitions were not being reported as "Completed"
      // the following code will report the end of the current automaton and start of a new automaton
      this.ended();

      this.Automaton.settings.type = enteringNode.automatonType || 'guide';
      this.didEnd = false;
      this.Storage.save('outcomeType', 'Refused');

      this.automatonStarted({ type: 'automaton', id: this.Automaton.id });
    }

    this._log('contentSentToCustomer', {
      nodeId: enteringNode.id,
      nodeName: enteringNode.name,
      nodeAttributes: nodeAttrsList,
      questions: questionTextArray,
      questionIds: questionIdArray
    });

    if (isTerminalEnteringNode) {
      this.ended();
    }
  };

  fn.updateOutcomeType = function (node) {
    var outcomeType = this.Storage.get('outcomeType');

    if (outcomeType === 'Completed') return;

    if (node.isOutcomeNode) {
      outcomeType = 'Completed';
    } else if (this.Automaton.Data.get('path.length') > 1) {
      outcomeType = 'Abandoned';
    } else {
      outcomeType = 'Refused';
    }

    this.Storage.save('outcomeType', outcomeType);
  };

  // http://confluence.touchcommerce.com/display/dev/TouchCommerce+Data+Events#TouchCommerceDataEvents-automaton.customerResponded
  fn.customerResponded = function (previousNode, currentNode, nextNode) {
    var responseLists = this.getResponsesToLog();
    var pathAttrs = getLeavingEnteringNodeAttributes(previousNode, nextNode);
    var nodeAttributes = getStandardNodeAttributes(currentNode);
    var attrs = _.extend({}, pathAttrs, nodeAttributes, currentNode.nodeAttributes);
    var nodeAttrsList = toAttrsList(attrs);

    if (this.Automaton.settings.type === 'satisfactionSurvey') {
      this.savePostChatSurveyResponses(responseLists);
    }

    this._log('customerResponded', _.extend({
      nodeId: currentNode.id,
      nodeName: currentNode.name,
      nodeAttributes: nodeAttrsList
    }, responseLists));
  };

  fn.savePostChatSurveyResponses = function (responseLists) {
    var responses = responseLists.questions.map(function (question, index) {
      return {
        question: question,
        answer: responseLists.answers[index]
      };
    });

    var data = {
      responses: responses,
      agentGroup: {
        id: _ijsfAdapter2.default.getAgentGroupId(),
        name: _ijsfAdapter2.default.getAgentGroupName()
      },
      engagementId: _ijsfAdapter2.default.getEngagementId()
    };

    inQ.Storage.set('acif.lastSurvey', data, 'lifetime');
  };

  fn.getResponsesToLog = function () {
    var lists = {
      questionIds: [],
      questions: [],
      answerIds: [],
      answers: [],
      answerTypes: [],
      answerNumbers: []
    };

    var responses = this.CRM.emptyResponses();

    responses = _.sortBy(responses, 'questionNumber');

    responses.forEach(function (response) {
      var isMultiple = Array.isArray(response.answerText);

      if (isMultiple) {
        response.answerText.forEach(function (answerText, index) {
          var answerType = response.answerType,
              questionText = response.questionText,
              questionId = response.questionId;


          addItemsToArrays({
            answerId: response.answerId[index],
            answerNumber: response.answerNumber[index],
            answerText: answerText,
            answerType: answerType,
            questionId: questionId,
            questionText: questionText
          });
        });
      } else {
        addItemsToArrays(response);
      }
    });

    return lists;

    function addItemsToArrays(item) {
      lists.questions.push(item.questionText);
      lists.questionIds.push(_.dasherize(item.questionId));
      lists.answers.push(item.answerText);
      lists.answerIds.push(_.dasherize(item.answerId));
      lists.answerTypes.push(item.answerType);
      lists.answerNumbers.push(item.answerNumber);
    }
  };

  // http://confluence.touchcommerce.com/display/dev/TouchCommerce+Data+Events#TouchCommerceDataEvents-automaton.ended
  fn.ended = function (finalNode) {
    if (this.didEnd) return;
    this.didEnd = true;

    finalNode = finalNode || {};

    var Data = this.Automaton.Data;
    var path = Data.get('path');

    Data.DW.setAutomatonAttr('nodeIdPath', path.join('.'));

    var finalNodeChatRouter = finalNode.chatRouter || {};

    this._log('ended', {
      businessUnitID: finalNodeChatRouter.businessUnit,
      agentGroupID: finalNodeChatRouter.agentGroup,
      outcomeType: this.Storage.get('outcomeType'),
      outcomeMessage: this.Storage.get('outcomeMessage')
    });

    this.Storage.save('state.started', false);
  };

  fn._log = function (eventName, data) {
    this._addToEventQueue(eventName, data);
    this.sendWithDebounce();
  };

  fn.sendWithDebounce = function () {
    var _this = this;

    clearTimeout(this.logEventsTimer);

    this.logEventsTimer = setTimeout(function () {
      _this.logEventsTimer = null;
      _this._sendEventLog();
    }, 500);
  };

  fn._addToEventQueue = function (eventName, data) {
    if ('engagement.requested' !== eventName) {
      data = this._loadAutomatonEventData(eventName, data);
    }

    if (this.isDuplicateEvent(data)) return;

    data.clientTimestamp = Date.now();
    data.attempts = 0;

    this.eventHistory.push(data);

    this.Storage.save('dw.eventHistory', this.eventHistory);

    data = encodeArrays(data);

    this.queue.push(data);
    this.Storage.save('dw.queue', this.queue);
  };

  fn._loadAutomatonEventData = function (eventName, data) {
    var automaton = this.Automaton.settings;
    var automatonAttributes = this.Automaton.Data.get('automatonAttributes');
    var typeFields = getAutomatonTypeFields(automaton.type);

    data = _.extend({
      _domain: 'automaton',
      evt: eventName,
      acifVersion: ACIF.VERSION,
      automatonID: automaton.id,
      automatonType: typeFields.automatonType,
      type: typeFields.type,
      automatonAttributes: toAttrsList(automatonAttributes)
    }, data);

    var fieldsToEncode = ['acifVersion', 'automatonID', 'automatonName', 'outcome', 'outcomeType'];

    fieldsToEncode.forEach(function (key) {
      if (data.hasOwnProperty(key)) {
        data[key] = encodeURIComponent(data[key]);
      }
    });

    // Delete fields with undefined values
    _.each(data, function (val, key) {
      if (String(val) === 'undefined') {
        delete data[key];
      }
    });

    data = mapFieldNames(data);

    return data;
  };

  fn.isDuplicateEvent = function (data) {
    var currentEventHash = (0, _hashCode2.default)(data);

    var lastEventHash = this.Storage.get('lastEventHash.' + data.evt + '.' + data._domain);
    var isDuplicateEvent = currentEventHash === lastEventHash;

    if (!isDuplicateEvent) {
      this.Storage.save('lastEventHash.' + data.evt + '.' + data._domain, currentEventHash);
    }

    return isDuplicateEvent;
  };

  fn._sendEventLog = function () {
    if (this.queue.length === 0) return;
    if (this.isSendingEvents) return;

    var eventList = this.queue;
    var Env = this.Automaton.Env;


    // events are removed from queue only in memory. Upon success, the events are removed from storage
    this.queue = [];

    if (Env.showEvents || Env.devMode) {
      this.sendEventHistoryToConsole();
    }

    eventList.forEach(function (event) {
      return event.attempts++;
    });

    var callback = this._eventStatusCallback.bind(this, eventList);
    var errorCallback = this._eventStatusErrorCallback.bind(this, eventList);

    this.isSendingEvents = true;
    _ijsfAdapter2.default.fireCustomEvent('LogToDW', { eventList: eventList, callback: callback, errorCallback: errorCallback });

    if (this.Automaton.Env.showEvents || this.Automaton.Env.devMode) {
      console.log('ACIF Events LogToDW:', eventList); // eslint-disable-line
    }
  };

  fn._eventStatusErrorCallback = function (eventList) {
    var _queue,
        _this2 = this;

    (_queue = this.queue).unshift.apply(_queue, _toConsumableArray(eventList));
    this.Storage.save('dw.queue', this.queue);

    this.isSendingEvents = false;

    var leastAttempts = Math.min.apply(Math, _toConsumableArray(eventList.map(function (event) {
      return event.attempts;
    })));

    if (leastAttempts >= 20) return;

    setTimeout(function () {
      _this2.sendWithDebounce();
    }, leastAttempts * 1000);
  };

  fn._eventStatusCallback = function (eventList, serverResponse) {
    this.Storage.save('dw.queue', this.queue);
    this.isSendingEvents = false;
    this.sendWithDebounce();

    var responseStatus = serverResponse.responseStatus;

    var allEventsReportedSuccesfully = this.Storage.get('dw.allEventsReportedSuccesfully', true);

    if (allEventsReportedSuccesfully) {
      allEventsReportedSuccesfully = responseStatus >= 200 && responseStatus < 300;
      this.Storage.save('dw.allEventsReportedSuccesfully', allEventsReportedSuccesfully);
    }

    $(this).trigger('onEventsReported', allEventsReportedSuccesfully);
  };

  function getAutomatonTypeFields(type) {
    type = type || 'guide';

    return {
      type: type,
      automatonType: type
    };
  }

  function encodeArrays(obj) {
    var clone = {};

    _.each(obj, function (val, key) {
      if (key === DwFieldNameMap.answerTypes) {
        clone[key] = val.join(',');
      } else {
        clone[key] = Array.isArray(val) ? encodeArray(val) : val;
      }
    });

    return clone;
  }

  function encodeArray(arr) {
    arr = arr.map(function (item) {
      return encodeURIComponent(item !== undefined ? item : '');
    });
    return encodeURIComponent(arr.join(','));
  }

  fn.sendEventHistoryToConsole = function () {
    var log = {};
    var lastEventName = _.last(this.eventHistory).evt;

    this.eventHistory.forEach(function (evt, index) {
      var count = index + 1;
      var key = count + ') ' + evt.evt;
      var evtClone = {};

      _.each(evt, function (val, key) {
        evtClone[key] = Array.isArray(val) ? val.join(',') : val;
      });

      log[key] = evtClone;
    });

    console.log('ACIF Events Log (last event: ' + lastEventName + ')', log); // eslint-disable-line
  };

  function mapFieldNames(data) {
    var map = DwFieldNameMap;
    var obj = {};

    _.each(data, function (value, key) {
      var dwField = map[key] ? map[key] : key;
      obj[dwField] = value;
    });

    return obj;
  }

  function getLeavingEnteringNodeAttributes(previousNode) {
    var nextNode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return {
      previous_node_id: previousNode.id || '',
      previous_node_name: previousNode.name || '',
      next_node_id: nextNode.id || nextNode,
      next_node_name: nextNode.name || nextNode
    };
  }

  function getStandardNodeAttributes(node) {
    var chatRouter = node.chatRouter || {};

    return {
      node_id: node.id,
      node_name: node.name,
      node_type: node.type,
      node_is_outcome: node.isOutcomeNode,
      node_business_unit_id: chatRouter.businessUnit,
      node_agent_group_id: chatRouter.agentGroup,
      node_agent_attributes: chatRouter.agentAttributes,
      node_auto_opener: chatRouter.clientOutcome,
      node_redirect_url: node.redirectUrl,
      check_agent_availability: chatRouter.checkAgentAvailability,
      agents_busy_backup_node: chatRouter.agentsBusyNode,
      outside_hop_backup_node: chatRouter.outsideHopNode
    };
  }

  function toAttrsList(obj) {
    if (!obj) return '';

    var list = [];

    Object.keys(obj).forEach(function (key) {
      var value = obj[key];
      if (value !== undefined) {
        value = removeCommas(value);
        list.push(encodeURIComponent(key) + ',' + encodeURIComponent(value));
      }
    });

    return list.join(';');
  }

  function removeCommas(str) {
    return String(str).replace(/,/g, '');
  }
});