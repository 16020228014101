define('acif/view/Template', ['exports', '../utils', './formify', '../ci/ijsf-adapter'], function (exports, _utils, _formify, _ijsfAdapter) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _formify2 = _interopRequireDefault(_formify);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Template;


  function Template(html) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};


    if (typeof html === 'string') {
      this._rawHtml = html;

      // If the client's templates have been precompiled, 'html' will be a function
    } else if (typeof html === 'function') {
      this._templateFn = html;
    } else {
      this._rawHtml = '<!-- empty view -->';
    }

    _.extend(this, options);
  }

  var fn = Template.prototype = { constructor: Template };

  fn._modelType = 'template';

  fn.getHTML = function () {
    var _this = this;

    var failMessage = 'Failed to render ' + this.modulePath + '. Received error: ';

    _utils2.default.tryCatch(function () {
      if (_this._rawHtml) {
        return _this.getHTMLNotPrecompiled();
      } else {
        return _this.getHTMLPrecompiled();
      }
    }, function (e) {
      _utils2.default.throwError(failMessage + e.message);
    });

    this.html = this.html.replace(/{{clientMediaBaseUrl}}/g, _ijsfAdapter2.default.getClientMediaBaseUrl());

    var $html = $(this.html);
    $html = $html.length > 1 ? $('<div>').html($html) : $html;

    this.$html = $html;

    return $html[0];
  };

  fn.getHTMLPrecompiled = function () {
    var _this2 = this;

    var getSubTemplate = function getSubTemplate(reference) {
      return _this2.getTemplate(reference, { precompiled: true });
    };

    this.html = this._templateFn(Object.assign({}, this.data, { getSubTemplate: getSubTemplate }));
  };

  fn.getHTMLNotPrecompiled = function () {
    var html = this.loadSubTemplates(this._rawHtml);

    var templateFn = _.template(html);

    this.html = templateFn(this.data);
  };

  fn.loadSubTemplates = function (html) {
    var _this3 = this;

    var regex = /<(div|span)([^>]*acif-template="(template::[\w-]+?|formify[-\w]*)"[^/>]*)(?:\/>|>[^<]*<\/\1>)/gi;

    return html.replace(regex, function (match, tagName, attributes, reference) {
      var template = _this3.getTemplate(reference);
      var subHtml = _this3.loadSubTemplates(template._rawHtml);
      return '<' + tagName + ' ' + attributes + '>' + subHtml + '</' + tagName + '>';
    });
  };

  fn.getTemplate = function (reference) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};


    var template = this.Automaton.getRecord(reference, 'template', { throws: false });

    if (template) return template;

    template = _formify2.default.getTemplate(reference, { precompiled: options.precompiled });

    if (options.precompiled) {
      return template;
    }

    return { _rawHtml: template };
  };
});