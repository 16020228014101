define('acif/utils/string', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    isReferenceString: function isReferenceString(value) {
      value = (value || '').toString();
      var referenceColonsReg = new RegExp('::');
      return referenceColonsReg.test(value);
    }

  };
});