define('acif/view/NodeView', ['exports', '../utils', './FieldsetView', './formify'], function (exports, _utils, _FieldsetView, _formify) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _FieldsetView2 = _interopRequireDefault(_FieldsetView);

  var _formify2 = _interopRequireDefault(_formify);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = NodeView;


  function NodeView(node, Automaton) {
    this.node = node;
    this.Automaton = Automaton;
    this.fieldsetViews = [];

    var data = this.getTemplateData();

    var template = void 0;

    if (this.node.template) {

      template = this.Automaton.getRecord(this.node.template, 'template', {
        data: data,
        Automaton: Automaton
      });

      this.html = template.getHTML();
    }

    this.createViewContainer();

    this.enableCustomDataLogging();

    this.makeModalCloseButtons();

    this.$tooltips = this.$container.find('[role=tooltip]').hide();
  }

  var fn = NodeView.prototype = { constructor: NodeView };

  fn.getTemplateData = function () {
    var data = _.clone(this.node);
    var nodes = this.Automaton.api.getBreadcrumbs();

    data.breadcrumbs = nodes.filter(function (node) {
      return !!node.breadcrumb;
    });
    data.api = this.Automaton.api;

    if (typeof data.formify === 'object') {
      data.formify = _formify2.default.process(data.formify);
      this.Automaton.node.fieldsList = this.getFieldsList(data.formify);
    }

    return data;
  };

  fn.getFieldsList = function (formifyData) {
    var _this = this;

    var map = {};
    formifyData.fields.forEach(function (field) {
      if (field.fields) {
        // field group
        var miniMap = _this.getFieldsList(field);
        Object.keys(miniMap).forEach(function (nestedField) {
          map[miniMap[nestedField].id] = miniMap[nestedField];
        });
      } else {
        map[field.id] = field;
      }
    });
    return map;
  };

  fn.createViewContainer = function () {
    var node = this.Automaton.node;
    var nodeClassName = _.dasherize(this.node.name);
    var nodeType = _.dasherize(node.type);
    var classNames = ['view-container', 'acif-wrapping', nodeClassName, 'node-' + node.id, 'node-type-' + nodeType];
    var $container = $('<div>').html(this.html).attr('id', 'view-container').addClass(classNames.join(' ')).attr('acif-node-name', node.name).attr('acif-node-id', node.id);

    this.$container = $container;

    this.createFieldsetViews();
    this.hideInputGroupErrors();

    this.initializeForm();

    return this;
  };

  fn.createFieldsetViews = function () {
    var self = this;
    this.$container.find('fieldset').each(function () {
      var fieldsetView = new _FieldsetView2.default(this);
      self.fieldsetViews.push(fieldsetView);
    });
  };

  fn.hideInputGroupErrors = function () {
    this.$container.find('[acif-input-group-constraint]').attr('hidden', 'true');
  };

  fn.initializeForm = function () {
    var $form = this.$container.find('form');

    if (!$form.length) return false;

    $form.addClass('form-pristine');
  };

  fn.hideBackButtons = function () {
    this.$container.find('[acif-action="back"]').hide();
    return this;
  };

  fn.enableCustomDataLogging = function () {
    var Data = this.Automaton.Data;

    this.$container.find('[acif-log]').each(function () {
      var $el = $(this);
      var evt = $el.attr('acif-on') || 'click';
      var logMultiple = $el.is('[acif-log-multiple]');

      if (logMultiple) {
        $el.on(evt, Data.logCustomData);
      } else {
        $el.one(evt, Data.logCustomData);
      }
    });

    return this;
  };

  fn.makeModalCloseButtons = function () {
    var _this2 = this;

    this.$container.find('.modal-window-invalid [acif-close]').on('click', function () {
      _this2.$container.removeClass(_this2.NS + 'invalid-form');
    });
    return this;
  };

  fn.addNamespace = function (NS) {
    var $wrapper = $('<div>').append(this.$container);

    var attributes = ['id', 'class', 'for', 'aria-labelledby', 'aria-describedby'];

    var skipNamespacing = this.Automaton.getProp('skipNamespacing');

    attributes.forEach(function (attr) {
      $wrapper.find('[' + attr + ']').each(function () {
        var $this = $(this);
        var value = $this.attr(attr);
        var nsValue = _utils2.default.namespaceString(NS, value);

        // If skipNamespacing exists in custom data, check to see
        // if the current element's attribute (or its ancestors's
        // attributes) matches the attribute given in the custom
        // data. Otherwise, namespace everything.
        if (skipNamespacing) {
          // If neither the current node nor its ancestors matches
          // the skipNamespacing node, then namespace those elements.
          // Those elements that do match will simply be passed over.
          if (!$this.closest(skipNamespacing).length) {
            $this.attr(attr, nsValue);
          }
          // If skipNamespacing does not exist, then namespace all elements as usual.
        } else {
          $this.attr(attr, nsValue);
        }
      });
    });
  };

  fn.addIdsToDivTags = function () {
    // add tc-# IDs to all div tags without IDs, which addresses an issue caused by
    // com.inq.flash.client.chatskins.SkinControl.buildModalWindow
    this.$container.find('div:not([id])').each(function () {
      $(this).attr('id', _.uniqueId('tc-'));
    });
  };

  fn.initializeAccordionMenus = function () {
    // Accordions must be initialized after the content is in the DOM
    // See full accordion options at http://api.jqueryui.com/accordion/
    this.$container.find('[role=accordion]').removeAttr('role').each(function () {
      var $accordion = $(this);
      var cast = _utils2.default.smartTypeCast;
      var icons = {};

      var active = $accordion.attr('active');
      var headerIcon = $accordion.attr('header-icon');
      var activeHeaderIcon = $accordion.attr('active-header-icon');
      var animate = $accordion.attr('animate');
      var collapsible = $accordion.attr('collapsible');
      var event = $accordion.attr('event');
      var header = $accordion.attr('header');
      var heightStyle = $accordion.attr('height-style');

      $accordion.removeAttr('active header-icon active-header-icon animate collapsible event header height-style');

      icons.header = headerIcon || 'ui-icon-triangle-1-e';
      icons.activeHeader = activeHeaderIcon || 'ui-icon-triangle-1-s';

      $accordion.accordion({
        icons: icons,
        active: typeof active === 'undefined' ? 0 : active,
        animate: animate ? cast(animate) : {},
        collapsible: collapsible ? cast(collapsible) : false,
        event: event ? event : 'click',
        header: header || '> li > :first-child,> :not(li):even',
        heightStyle: heightStyle || 'auto'
      });
    });
  };

  fn.initializeTooltips = function () {
    // Tooltips must be initialized after the content is in the DOM
    // See full tooltip options at http://api.jqueryui.com/tooltip/

    var self = this;

    this.$tooltips.each(function () {
      var $tooltip = $(this);
      var targetId = $tooltip.attr('for');
      var targetSelector = '#' + targetId;
      var $target = targetSelector ? $(targetSelector, self.$container) : $tooltip.prev();

      if (!$target.length) {
        ACIF.Utils.logger('Element for tooltip with id "' + targetId + '" not found in dom.', 'error');
        return;
      }

      var cast = _utils2.default.smartTypeCast;
      var show = $tooltip.attr('show');
      var hide = $tooltip.attr('hide');
      var track = $tooltip.attr('track');
      var position = {};
      var positionMy = $tooltip.attr('position-my');
      var positionAt = $tooltip.attr('position-at');
      var positionOf = $tooltip.attr('position-of');
      var positionCollision = $tooltip.attr('position-collision');

      position.my = positionMy ? positionMy : 'left top+15';
      position.at = positionAt ? positionAt : 'left bottom';
      position.collision = positionCollision ? positionCollision : 'flipfit';
      position.of = position.Of ? self.$container.find(positionOf) : $target;
      position.within = self.Automaton.Env.top;

      show = show ? cast(show) : true;
      hide = hide ? cast(hide) : true;
      track = track ? cast(track) : false;

      $tooltip.show().removeAttr('role for style show hide track position-my position-at position-of position-collision');

      $target.tooltip({
        items: $target,
        position: position,
        show: show,
        hide: hide,
        track: track,
        tooltipClass: '-ui-tooltip',
        content: $('<div>').append($tooltip).html()
      });
    });
  };

  fn.initializeDatepickers = function () {
    var _this3 = this;

    this.$container.find('[acif-datepicker]').each(function (index, element) {

      var $datepickerInput = $(element);
      var datepickerName = $datepickerInput.attr('acif-datepicker');

      $datepickerInput.removeAttr('acif-datepicker');

      var nodeDatepickerConfigs = _utils2.default.get(_this3, 'node.datepickers.' + datepickerName) || {};

      var flatpickrConfigurations = _.defaults({
        appendTo: _this3.$container[0],
        static: true
      }, nodeDatepickerConfigs, {
        // these configurations will be overwritten by nodeDatepickerConfigs
        allowInput: true,
        ariaDateFormat: 'F j, Y'
      });

      // overwrite the following configuration properties to update classes then call the original method
      ['onMonthChange', 'onOpen', 'onYearChange'].forEach(function (event) {
        flatpickrConfigurations[event] = function (selectedDates, dateStr, instance) {
          _this3._updateFlatpickrClassNames(selectedDates, dateStr, instance, nodeDatepickerConfigs[event]);
        };
      });

      // inline date picker doesn't call onShow. Also, inline replaces dom elements when date is clicked
      if (flatpickrConfigurations.inline) {
        ['onReady', 'onValueUpdate'].forEach(function (event) {
          flatpickrConfigurations[event] = function (selectedDates, dateStr, instance) {
            _this3._updateFlatpickrClassNames(selectedDates, dateStr, instance, nodeDatepickerConfigs[event]);
          };
        });
      }

      var flatpickrInstance = flatpickr(element, flatpickrConfigurations);

      var $calendarContainer = $(flatpickrInstance.calendarContainer);
      $calendarContainer.find(':input').data('acif-skip-validation', true);

      $datepickerInput.parent().css('width', '100%');
      $datepickerInput.on('blur', function () {
        flatpickrInstance.close();
      });
    });
  };

  fn._updateFlatpickrClassNames = function (selectedDates, dateStr, instance, callback) {
    var _this4 = this;

    var calendarContainer = instance.calendarContainer;

    var $calendarContainer = $(calendarContainer);

    $calendarContainer.find('[class]').each(function (index, element) {

      var $element = $(element);
      var value = $element.attr('class');
      var classNames = value.split(' ');

      classNames.forEach(function (className) {
        var NS = _this4.Automaton.View.NS;

        var isClassNamespaced = className.indexOf(NS) < 0;

        if (className && isClassNamespaced) {
          var nsValue = _utils2.default.namespaceString(NS, className);
          $('.' + className, $calendarContainer).addClass(nsValue);
        }
      });
    });

    if (typeof callback === 'function') {
      callback(selectedDates, dateStr, instance);
    }
  };
});