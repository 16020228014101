define('acif/environment/is-dev-mode', ['exports', './get-env-var'], function (exports, _getEnvVar) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    if (isDevMode !== undefined) return isDevMode;

    var env = (0, _getEnvVar2.default)('nuance-env') || 'production';

    isDevMode = !!env.match(/^dev/i);

    return isDevMode;
  };

  var _getEnvVar2 = _interopRequireDefault(_getEnvVar);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  var isDevMode = void 0;
});