define('acif/automaton/utils/get-active-automatons', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getActiveAutomatons;
  function getActiveAutomatons() {
    var automatons = inQ.Storage.peek('acif-loaded-automatons', 'engagement') || {};
    // persisted automatons that are set to 'true'
    var activeAutomatons = Object.keys(automatons).filter(function (key) {
      return automatons[key];
    });
    return activeAutomatons.map(function (key) {
      return JSON.parse(key);
    });
  }
});