define('acif/utils/hash-code', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getHashCode;
  function getHashCode(string) {
    if (!string) return;

    if (typeof string === 'object') {
      string = JSON.stringify(string);
    } else if (typeof string !== 'string') {
      string = String(string);
    }

    var hash = 0,
        i,
        chr,
        len;
    if (string.length === 0) return hash;
    for (i = 0, len = string.length; i < len; i++) {
      chr = string.charCodeAt(i);
      // jshint ignore:start
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
      // jshint ignore:end
    }
    return hash;
  }
});