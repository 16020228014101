define('acif/utils/cookies', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    set: function set(name, value, days) {
      var expires = '';
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toGMTString();
      }

      document.cookie = name + '=' + value + expires + '; path=/';
    },
    remove: function remove(name) {
      this.set(name, null, -1);
    },
    get: function get(c_name) {
      if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + '=');
        if (c_start !== -1) {
          c_start = c_start + c_name.length + 1;
          var c_end = document.cookie.indexOf(';', c_start);
          if (c_end === -1) {
            c_end = document.cookie.length;
          }
          return window.unescape(document.cookie.substring(c_start, c_end));
        }
      }
      return '';
    }
  };
});