define('acif/utils/isJSON', ['exports'], function (exports) {
    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var isJSON = function isJSON(str) {
        var obj;
        try {
            obj = JSON.parse(str);
            return typeof obj === 'object';
        } catch (e) {
            return false;
        }
    };

    exports.default = isJSON;
});