define('storage/top-frame/session-storage-api', ['exports', '../utils/get', '../utils/set', './id-manager'], function (exports, _get, _set, _idManager) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _get2 = _interopRequireDefault(_get);

  var _set2 = _interopRequireDefault(_set);

  var _idManager2 = _interopRequireDefault(_idManager);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = StorageApi;


  function StorageApi() {
    this.ids = {
      site: (0, _idManager2.default)('site'),
      session: (0, _idManager2.default)('session'),
      engagement: (0, _idManager2.default)('engagement'),
      tab: (0, _idManager2.default)('tab')
    };

    this._storageKey = 'nuance-acif-' + this.ids.site;

    var storage = this._getStorage();

    this._initStore(storage, 'session');
    this._initStore(storage, 'engagement');
    this._initStore(storage, 'tab');

    storage.lifetime = storage.lifetime || {
      data: {}
    };

    storage.custom = storage.custom || {
      data: {},
      registry: {}
    };

    this._setStorage(storage);

    this.storage = storage;
  }

  var fn = StorageApi.prototype;

  fn.peek = function (path, scope, callback) {
    if (!scope || typeof scope === 'function') {
      callback = scope;
      scope = 'session';
    }

    var engagementId = (0, _idManager2.default)('engagement');

    if (scope === 'engagement' && !engagementId) return;

    var store = this._getStore(this.storage, scope);

    if (!store) {
      console.error('"' + scope + '" is not a valid TC Storage scope. Expected one of: engagment, tab, session, lifetime, or custom');
      return;
    }

    var item = (0, _get2.default)(store, path);

    return typeof callback === 'function' ? setTimeout(function () {
      return callback(null, item);
    }, 100) : item;
  };

  fn._getStore = function (storage, scope) {
    var store = storage[scope];

    if (scope.match(/custom|lifetime/i)) {
      return store.data;
    }

    var id = (0, _idManager2.default)(scope);

    if (id !== store.id) {
      store = storage[scope] = { id: id, data: {} };
    }

    return store.data;
  };

  fn.get = function (path, scope, callback) {
    if (!scope || typeof scope === 'function') {
      callback = scope;
      scope = 'session';
    }

    var engagementId = (0, _idManager2.default)('engagement');

    if (engagementId) {
      this.ids.engagement = engagementId;
    }

    var isValidScope = ['engagement', 'tab', 'session', 'lifetime', 'custom'].indexOf(scope) > -1;

    if (!isValidScope) {
      throw new Error('"' + scope + '" is not a valid TC Storage scope. Expected one of: engagement, tab, session, lifetime, or custom');
    }

    var storage = this._getStorage();
    var store = this._getStore(storage, scope);

    var response = (0, _get2.default)(store, path);

    setTimeout(function () {
      return callback(null, response);
    }, 100);
  };

  fn.set = function (path, value, scope) {
    if (typeof path === 'object') {
      value = path.value;
      scope = path.scope;
      path = path.path || path.key;
    }

    var engagementId = (0, _idManager2.default)('engagement');

    scope = scope || 'session';

    // Cannot set engagement storage when no engagement is active
    if (scope === 'engagement' && !engagementId) return;

    var storage = this._getStorage();
    var store = this._getStore(storage, scope);

    (0, _set2.default)(store, path, value);

    if (engagementId) {
      this.ids.engagement = engagementId;
    }

    var expires = void 0;

    if (!store) {
      if (scope) {
        expires = this._getExpirationDate(scope);
        scope = 'custom';
      } else {
        scope = 'session';
      }
      store = this._getStore(storage, scope);
    }

    var oldValue = (0, _get2.default)(store, path);

    (0, _set2.default)(store, path, value);

    if (expires) {
      storage.custom.registry[path] = expires.getTime();
    }

    this._setStorage(storage);
    this._ieSyncFix();

    var changes = oldValue === value ? undefined : {
      path: path,
      previous: oldValue,
      current: value,
      scope: scope,
      expires: expires
    };

    this.storage = storage;

    if (changes) {
      Inq.fireCustomEvent('StorageChanged', changes);
    }
  };

  fn._initStore = function (storage, scope) {
    storage[scope] = storage[scope] || {};

    var id = this.ids[scope];

    if (id !== storage[scope].id) {
      storage[scope] = {
        id: id,
        data: {}
      };
    }
  };

  fn._getStorage = function () {
    var data = sessionStorage.getItem(this._storageKey);
    return data ? JSON.parse(data) : {};
  };

  fn._setStorage = function (storage) {
    storage = JSON.stringify(storage);
    sessionStorage.setItem(this._storageKey, storage);
  };

  fn._ieSyncFix = function () {
    // https://connect.microsoft.com/IE/feedback/details/812563/ie-11-local-storage-synchronization-issues

    var noop = function noop() {};

    if (document.addEventListener) {
      document.addEventListener('storage', noop, false);
    } else if (document.attachEvent) {
      document.attachEvent('onstorage', noop);
    } else {
      document.onstorage = noop;
    }

    // Overwrite method to set and remove a dummy property
    this._ieSyncFix = function () {
      localStorage.setItem('inq-dummy', 1);
      localStorage.removeItem('inq-dummy');
    };
  };
});