define('acif/environment/flashvars', ['exports', '../ci/ijsf-adapter'], function (exports, _ijsfAdapter) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = loadFlashVars;


  function loadFlashVars() {
    var browserTypeAndVersion = _ijsfAdapter2.default.getBrowserTypeAndVersion();

    var flashVars = {
      browserType: browserTypeAndVersion[0],
      browserVersion: browserTypeAndVersion[1],
      automatonDataMap: _ijsfAdapter2.default.getAutomatonDataMap(),
      operatingSystemType: getOSType(),
      agentGroupID: _ijsfAdapter2.default.getAgentGroupId(),
      businessUnitID: _ijsfAdapter2.default.getBusinessUnitId(),
      brName: _ijsfAdapter2.default.getBusinessRuleName(),
      // 'initiator',
      // 'location',
      pageMarker: _ijsfAdapter2.default.getPageMarker(),
      pageID: _ijsfAdapter2.default.getPageId()
      // 'language',
      // 'visitorAttributes'
    };

    // Expose "launchType" property, as determined by BR naming conventions
    if (flashVars.brName.match(/-P-/)) {
      flashVars.launchType = 'proactive';
    } else if (flashVars.brName.match(/-R-/)) {
      flashVars.launchType = 'reactive';
    } else {
      flashVars.launchType = 'unknown';
    }

    if (!flashVars.deviceType) {
      flashVars.deviceType = _ijsfAdapter2.default.getDeviceType() || 'unknown';
    }

    return flashVars;
  }

  function getOSType() {
    var os = "Unknown";
    var platform = window.navigator.platform;
    var ua = window.navigator.userAgent;

    if (/Android/.test(ua)) {
      os = "Android";
    } else if (/iPhone/.test(ua) || /iPad/.test(ua) || /iPod/.test(ua)) {
      os = "iOS";
    } else if (/Win/.test(platform)) {
      os = "Windows";
    } else if (/Linux/.test(platform)) {
      os = "Linux";
    } else if (/Mac/.test(platform)) {
      os = "Mac OS";
    }

    return os;
  }
});