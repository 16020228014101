define('storage/utils/get', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = get;
  function get(obj, path) {
    if (!obj) return;

    try {
      var parts = (path || '').split('.');
      var key = parts.shift();
      var val = obj[key];

      return parts.length ? get(val, parts.join('.')) : val;
    } catch (e) {
      console.error(e);
    }
  }
});