define('acif/utils/mixins/underscore', [], function () {
  _.mixin({
    contains: contains,
    dasherize: dasherize,
    titleCase: titleCase,
    camelize: camelize,
    camelizeObject: camelizeObject
  });

  function dasherize(str) {
    str = String(str);
    return str.replace(/\W+/g, ' ').replace(/^\s+|\s+$/g, '').replace(/\s/g, '-').toLowerCase();
  }

  function contains(haystack, needle) {
    if (typeof haystack === 'undefined' || typeof needle === 'undefined') {
      return false;
    } else if (_.isArray(haystack)) {
      return _.indexOf(haystack, needle) > -1;
    } else if (typeof haystack === 'string') {
      var regex = new RegExp(needle);
      return !!haystack.match(regex);
    } else {
      return false;
    }
  }

  function titleCase(str) {
    str = String(str);
    return str.replace(/[\s-]+/g, '-').split('-').map(function (str) {
      return str[0].toUpperCase() + str.slice(1).toLowerCase();
    }).join(' ');
  }

  function camelizeObject(obj) {

    if (Array.isArray(obj)) {
      obj.forEach(function (item) {
        return camelizeObject(item);
      });
    } else if (!obj) {
      return;
    }

    _.each(obj, function (value, key) {
      var camelKey = camelize(key);

      delete obj[key];
      obj[camelKey] = value;

      if (value && typeof value === 'object') {
        camelizeObject(value);
      }
    });

    return obj;
  }

  function camelize(str) {
    return str.toString().replace(/[-_\s]+(\w)/g, function (match, c) {
      return c ? c.toUpperCase() : '';
    });
  }
});