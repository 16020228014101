define('acif/automaton/index', ['exports', './utils', '../utils', './initializer', '../ci/ijsf-adapter', '../environment', '../view', '../view/forms', '../transitions', '../chat', '../data', '../models/Model', '../models/Automaton', '../models/Node', '../models/Style', '../view/Template'], function (exports, _utils, _utils3, _initializer, _ijsfAdapter, _environment, _view, _forms, _transitions, _chat, _data, _Model, _Automaton, _Node, _Style, _Template) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _utils4 = _interopRequireDefault(_utils3);

  var _initializer2 = _interopRequireDefault(_initializer);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  var _environment2 = _interopRequireDefault(_environment);

  var _view2 = _interopRequireDefault(_view);

  var _forms2 = _interopRequireDefault(_forms);

  var _transitions2 = _interopRequireDefault(_transitions);

  var _chat2 = _interopRequireDefault(_chat);

  var _data2 = _interopRequireDefault(_data);

  var _Model2 = _interopRequireDefault(_Model);

  var _Automaton2 = _interopRequireDefault(_Automaton);

  var _Node2 = _interopRequireDefault(_Node);

  var _Style2 = _interopRequireDefault(_Style);

  var _Template2 = _interopRequireDefault(_Template);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Automaton;


  function Automaton(options) {
    this.options = options || {};
    this.Env = new _environment2.default(options);
    this.utils = new _utils2.default(this);
    this.initializedModels = {};

    this.id = parseInt(this.Env.dbSync) || this.options.automatonId;

    this.namespace = 'inq-' + this.id + '-';

    this.hash = this.id + '::' + options.context + '::' + options.location;

    if (!this.id) {
      ACIF.Utils.throwError('An automaton ID was not specified');
    }

    this.Data = new _data2.default(this);
    this.View = new _view2.default(this);
    this.Forms = new _forms2.default(this);
    this.Chat = new _chat2.default(this);
    this.Transitions = new _transitions2.default(this);

    this.displayedPath = [];

    this.flashVars = this.Env.flashVars;

    this.Data.on('storageReady', this.getAndSaveDatapass.bind(this));
  }

  var fn = Automaton.prototype = { constructor: Automaton };

  Automaton.load = function (options) {
    var automaton = new Automaton(options);
    _initializer2.default.initialize(automaton);

    $(automaton).one('didRenderNode', function (event, nodeId) {
      _ijsfAdapter2.default.logDiagnosticInfoToServer('Automaton rendered (nodeId: ' + nodeId + ')');
    });

    return automaton;
  };

  fn.close = function () {
    var _options = this.options,
        automatonID = _options.automatonId,
        location = _options.location,
        context = _options.context;


    return ACIF.closeAutomaton({
      automatonID: automatonID,
      location: location,
      context: context
    });
  };

  /**
   * Returns a property from nearest configuration hierarchy level that has
   * that property defined.  Hierarchy order is:
   * 1) URL hash, 2) Node, 3) Automaton
   *
   * @param  {string} prop Name of property (also accepts an array of strings)
   * @param  {Node}   node Node object
   * @return {mixed} The selected property value
   */
  fn.getProp = function (props, node) {
    props = [].concat(props);
    node = node || this.node;

    var checkIn = [node, this.settings];
    var obj = void 0,
        i = void 0,
        j = void 0,
        property = void 0,
        keyPos = void 0;

    for (i = 0; i < checkIn.length; i++) {
      obj = checkIn[i];
      if (!obj) {
        continue;
      }
      for (j = 0; j < props.length; j++) {
        property = props[j];

        // calling getProp with an argument in dot notation, the following loop returns the data when nested in an object
        var objPath = property.split('.');
        var foundProp = obj;

        for (keyPos = 0; keyPos < objPath.length; keyPos++) {
          var key = objPath[keyPos];

          if (typeof foundProp[key] !== 'undefined') {
            foundProp = foundProp[key];
          } else {
            foundProp = undefined;
            break;
          }
        }

        if (foundProp) return foundProp;
      }
    }
  };

  var Models = {
    automaton: _Automaton2.default,
    node: _Node2.default,
    style: _Style2.default,
    template: _Template2.default,
    default: _Model2.default
  };

  fn.getRecord = function (value) {
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'node';
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    if (value instanceof _Model2.default) return value;
    if (!value) return;

    var modulePath = this.ensureModulePath(value, type);

    if (!modulePath) {
      if (options.throws === false) return;
      _utils4.default.throwError('Could not resolve "' + value + '" to a modulePath in automaton configs.');
    }

    var model = this.initializedModels[modulePath];

    if (!model) {

      var record = require(modulePath);

      var ModelClass = Models[type] || Models['default'];

      options.Automaton = options.Automaton || this;
      options.modulePath = modulePath;

      model = new ModelClass(record, options);

      if (type !== 'template') {
        this.initializedModels[modulePath] = model;
      }
    }

    return model;
  };

  fn.ensureModulePath = function (value, type) {

    if (require.entries[value]) return value;

    // resolve modulePath if 'value' is a referenceString
    if (_utils4.default.string.isReferenceString(value)) return this.referenceStringToModulePath(value);

    // resolve modulePath from Id
    var modulePath = 'Automatons/' + type + 's/' + value;
    if (modulePath && require.entries[modulePath]) return modulePath;
  };

  // referenceStringToModulePath function needs be enhanced to support relative reference strings
  fn.referenceStringToModulePath = function (referenceString) {
    var _referenceString$spli = referenceString.split('::'),
        _referenceString$spli2 = _slicedToArray(_referenceString$spli, 2),
        referenceType = _referenceString$spli2[0],
        referenceId = _referenceString$spli2[1];

    var modulePath = 'Automatons/' + referenceType + 's/' + referenceId;
    if (require.entries[modulePath]) return modulePath;
  };

  /**
   * Indicates whether the automaton is a pre-chat automaton.
   */
  fn.isPreChat = function () {
    return this.options.startedBy.type === 'br';
  };

  fn.addDependent = function (dependentAutomaton) {
    if (!dependentAutomaton) return;

    var dependents = this.Data.get('dependents');
    dependents = dependents || [];
    dependents.push(dependentAutomaton.storageKey);
    this.Data.save('dependents', dependents);
  };

  fn.removeDependent = function (dependentAutomaton) {
    if (!dependentAutomaton) return;

    var dependents = this.Data.get('dependents');
    dependents = dependents || [];

    var dependentIndex = dependents.indexOf(dependentAutomaton.storageKey);
    dependents.splice(dependentIndex, 1);

    this.Data.save('dependents', dependents);
  };

  // datapass refers to the options.datapass property when initializing an automaton
  fn.getAndSaveDatapass = function () {
    var persistedDatapass = this.Data.get('datapass') || {};
    _.defaults(this.options.datapass, persistedDatapass);
    this.Data.save('datapass', this.options.datapass);
  };

  fn.setAsActive = function () {
    this.View.setCircularTabbing(this.View.$viewContainer);
    this.View.Aria.getFirstSelectableElement().focus();
  };
});