define('acif/utils/getAutomatonProp', ['exports', './get'], function (exports, _get) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _get2 = _interopRequireDefault(_get);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = getAutomatonProp;


  function getAutomatonProp(automatonId, propertyPath) {
    if (!automatonId) return;
    if (!propertyPath) return;

    var automatonModulePath = 'Automatons/automatons/' + automatonId;
    var isActive = !!require.entries[automatonModulePath];

    var automatonSettings = isActive && require(automatonModulePath);
    return (0, _get2.default)(automatonSettings, propertyPath);
  }
});