define('acif/automaton/utils/promises', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = { makePromise: makePromise };


  function makePromise(timers, description) {
    var promise = new $.Deferred();

    var INTERVAL_MS = 2000;
    var MAX_WAIT_S = 60;

    var intervalCount = 0;
    var promiseInterval = timers.setInterval(function () {
      intervalCount++;
      var secondsElapsed = intervalCount * INTERVAL_MS / 1000;

      if (promise.state() === 'pending') {

        if (secondsElapsed < MAX_WAIT_S) {
          ACIF.Utils.logger('Pending promise - ' + description + ' | ' + secondsElapsed + 's', 'warn');
        } else {
          ACIF.Utils.logger('Max wait time reached - ' + description + ' | ' + secondsElapsed + 's', 'error');
          timers.clearInterval(promiseInterval);
          promise.reject();
        }
      } else {
        timers.clearInterval(promiseInterval);
      }
    }, INTERVAL_MS);

    return promise;
  }
});