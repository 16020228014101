define('acif/models/Model', ['exports', '../utils'], function (exports, _utils) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Model;


  function Model() {
    var record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var options = arguments[1];

    var recordSource = _.omit(record, 'default');

    try {
      recordSource = JSON.parse(JSON.stringify(recordSource));
    } catch (e) {} // eslint-disable-line no-empty

    _.extend(this, record.attributes, options, {
      id: record.id,
      _recordSource: recordSource
    });

    if (this.Automaton && this.Automaton.Data.save) {
      this.Automaton.Data.save(_defineProperty({}, 'records.' + this._modelType + '.' + this.id, this.modulePath));
    }

    if (this.proto) {
      this.inheritFromPrototype();
    }
  }

  var fn = Model.prototype = { constructor: Model };

  fn.inheritFromPrototype = function () {
    this.proto = this.Automaton.getRecord(this.proto, this._modelType);

    // Merge transitions
    if (typeof this.proto.transitions === 'object' && typeof this.transitions === 'object') {
      _.each(this.proto.transitions, function (value, key) {
        this.transitions[key] = this.transitions[key] ? [].concat(this.transitions[key]).concat(this.proto.transitions[key]) : value;
      });
    }

    _.defaults(this, this.proto);
  };

  fn.extendVersions = function () {
    var versionData = void 0;

    // Select Automaton Specific Properties
    // only relevant for Node instances;
    var automatonSpecificData = this.versions || this.automatons;
    var automaton = this.Automaton.settings;

    if (automatonSpecificData && automaton) {
      automatonSpecificData.forEach(function (value, key) {
        var selectedValue = value[automaton.id] || value[automaton.name];
        if (typeof selectedValue !== 'undefined') {
          versionData[key] = selectedValue;
        }
      });
    }

    _.extend(this, versionData);
  };

  fn.runHook = function (methodName, context) {
    context = context || this;

    // expose api variable inside context of code to be evaluated
    api = this.Automaton.api; // eslint-disable-line 

    var self = context;
    var code = this[methodName] || '';
    var hookMethodResponse = void 0;

    if (!code) {
      return;
    }

    // strip JavaScript comments
    if (typeof code === 'string') {
      code = code.replace(/(?:\/\*(?:[\s\S]*?)\*\/)|(?:([\s;])+\/\/(?:.*)$)/gm, '');
      code = code.trim();
    }

    _utils2.default.tryCatch(function () {
      self._super = self.createSuper(methodName);

      var fn = void 0;

      if (typeof code === 'string') {

        // jshint evil:true
        if (code.match(/^function\s*\([\s\S]*\}$/)) {
          eval('fn = ' + code + ';');
        } else {
          eval('fn = function() {' + code + '};');
        }
        // jshint evil:false
      } else if (typeof code === 'function') {
        fn = code.bind(self);
      }

      hookMethodResponse = fn.apply(context, []);
    }, function (e) {
      var msg = 'Failed to evaluate the "' + methodName + '" custom code for ' + self._modelType + ' ' + self.id + ' ("' + self.name + '").\nReceived error: ' + e.message;
      _utils2.default.console(msg, 'error');
    });

    return hookMethodResponse;
  };

  fn.createSuper = function (methodName) {
    return function _super() {
      var errorMsg = void 0;

      if (!methodName) {
        errorMsg = 'Custom method name not specified.';
      } else if (!this.proto) {
        errorMsg = 'Model has no prototype model assigned';
      } else if (!this.proto[methodName]) {
        errorMsg = 'Prototype model has no "' + methodName + '" method.';
      }

      if (errorMsg) {
        _utils2.default.console('Error calling this._super() - ' + errorMsg, 'error');
      } else {
        return this.proto.runHook(methodName, this);
      }
    };
  };

  fn.get = function (prop) {
    return _utils2.default.getPropFromAlias(this, prop);
  };
});