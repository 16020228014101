define('acif/data/data', ['exports', '../utils', './data-warehouse', './customer-response-manager', './local-storage', './storage-mixin', './alerts'], function (exports, _utils, _dataWarehouse, _customerResponseManager, _localStorage, _storageMixin, _alerts) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _dataWarehouse2 = _interopRequireDefault(_dataWarehouse);

  var _customerResponseManager2 = _interopRequireDefault(_customerResponseManager);

  var _localStorage2 = _interopRequireDefault(_localStorage);

  var _storageMixin2 = _interopRequireDefault(_storageMixin);

  var _alerts2 = _interopRequireDefault(_alerts);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Data;


  function Data(Automaton) {
    this.Automaton = Automaton;
    this.Storage = new _localStorage2.default(Automaton);
    this.Alerts = new _alerts2.default(Automaton);
  }

  var fn = Data.prototype = { constructor: Data };

  fn.init = function () {
    var _this = this;

    return this.Storage.init().then(function (data) {
      _this.StorageClass = (0, _storageMixin2.default)(_this);
      _this.storage = _this.StorageClass.create(_this, 'Automaton.Data');
      _this.CRM = new _customerResponseManager2.default(_this);
      _this.DW = new _dataWarehouse2.default(_this);

      _this.trigger('storageReady');

      return data;
    });
  };

  fn.triggerCustomerResponded = function () {
    var nextNode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var previousNode = this.Automaton.api.getPreviousNode() || {};
    var currentNode = this.Automaton.node;

    if (nextNode === 'exit' || nextNode.isOutcomeNode || nextNode.chatRouter) {
      var outcomeMessage = typeof nextNode === 'object' ? nextNode.outcomeMessage : this.Automaton.node.outcomeMessage;

      outcomeMessage = outcomeMessage || currentNode.outcomeMessage || '';

      this.Storage.save('outcomeMessage', outcomeMessage);
    }

    this.DW.customerResponded(previousNode, currentNode, nextNode);

    this.persistCustomAutomatonProperties();
    this.Automaton.activeEvent = null;
  };

  fn.triggerStarted = function () {
    this.DW.automatonStarted.apply(this.DW, arguments);
  };

  fn.triggerContentSentToCustomer = function (enteringNode, questions) {
    this.DW.contentSentToCustomer(enteringNode, questions);
  };

  fn.triggerEnded = function (chatNode) {
    this.DW.ended(chatNode);
  };

  fn.checkAlertConditions = function () {
    this.Alerts.checkAlertConditions();
  };

  fn.logCustomData = function () {
    var $el = $(this);
    var element = $el.attr('acif-element');
    var element_response = $el.attr('acif-element-response');
    var allowMultiple = $el.is('[acif-log-multiple]');
    var occurence = $el.attr('acif-occurence') || 0;

    if (element && element_response) {
      if (allowMultiple) {
        occurence = occurence + 1;
        element_response = element_response + ' (' + occurence + ')';
        $el.attr('acif-occurence', occurence);
      }

      this.Store.addToLogQueue('custom', 'custom: ' + element, element_response);
    }
  };

  // Allow any custom automaton properties to be persisted across page loads
  // Custom automaton properties are any that are created at runtime via the
  // onEntry or onPageLanding functions
  // e.g., api.automaton.isLoggedIn = true;
  fn.persistCustomAutomatonProperties = function () {
    var customAutomatonProperties = this.Automaton.settings.getCustomProperties();

    if (Object.keys(customAutomatonProperties).length) {
      var msg = 'Saving custom data on the automaton object is deprecated. ' + 'To save (and get) custom data, use `api.save("key", value)` and ' + '`api.get("key")`';

      _utils2.default.logger.deprecate(msg);

      this.save('automaton', customAutomatonProperties);
    }

    return customAutomatonProperties;
  };

  fn.on = function (name, method) {
    var allTriggers = this.allTriggers || {};
    var triggerActions = allTriggers[name] || [];
    triggerActions.push({
      name: name,
      method: method
    });
    allTriggers[name] = triggerActions;
    this.allTriggers = allTriggers;
  };

  fn.trigger = function (name) {
    var allTriggers = this.allTriggers || {};
    var triggerActions = allTriggers[name] || [];
    triggerActions.forEach(function (action) {
      action.method.call();
    });
  };
});