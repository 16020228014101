define('acif/automaton/initializer', ['exports', '../ci/ijsf-adapter', '../utils', '../utils/mixins'], function (exports, _ijsfAdapter, _utils) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  var _utils2 = _interopRequireDefault(_utils);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Initializer;


  Initializer.initialize = function (automaton) {
    var initializer = new Initializer(automaton);

    return initializer.initPromise;
  };

  function Initializer(automaton) {
    var a = automaton;

    this.Automaton = a;
    this.Data = a.Data;
    this.initPromise = a.utils.makePromise('automaton initializing');
    this.benchmark = _utils2.default.benchmark;

    this.benchmark('init').start();
    _utils2.default.console('Initializing framework');

    if (Array.isArray(a.Env.top.inqAcifEventsRegistry)) {
      _utils2.default.events.loadHandlers(a.Env.top.inqAcifEventsRegistry);
    }

    a.Data.init().then(this.init.bind(this));
  }

  var fn = Initializer.prototype = { constructor: Initializer };

  fn.init = function () {
    var Automaton = this.Automaton;
    this.isSessionInitializing = !Automaton.Data.Storage.get('state.started');
    Automaton.isSessionInitializing = this.isSessionInitializing;

    this.Automaton.settings = this.Automaton.getRecord(this.Automaton.id, 'automaton');

    if (this.shouldCloseCustomerInterface()) {
      Automaton.View.Exit.closeCI();
      this.status = 'Cancelled automaton initialization. Closing CI.';
      this.initPromise.resolve(this);
      return;
    }

    this.benchmark('init').mark('starting View.initializeViewport()');
    Automaton.View.initializeViewport();
    this.benchmark('init').mark('finishing View.initializeViewport()');

    this.initializeSessionData();

    this.createPublicAPI();

    var customAutomatonData = this.Automaton.Data.get('automaton');
    Automaton.settings.extend(customAutomatonData);

    if (Automaton.settings.onPageLanding) {
      Automaton.settings.runHook('onPageLanding');
    }

    if (this.isSessionInitializing) {
      Automaton.Data.triggerStarted(Automaton.options.startedBy);
      return this.runAutomatonOnInitHook().then(this.persistInitialSessionData.bind(this)).then(this.doAfterAutomatonIsInitialized.bind(this));
    } else {
      this.doAfterAutomatonIsInitialized();
    }
  };

  fn.shouldCloseCustomerInterface = function () {
    // A failsafe to ensure the CI is closed if customer clicked on an exit node
    // but a page refresh/redirect occured before the CI fully closed.
    var currentNodeModulePath = this.Data.get('currentNodeModulePath');
    if (currentNodeModulePath) {
      var node = this.Automaton.getRecord(currentNodeModulePath);
      return !!(node && node.isExitNode);
    }
  };

  fn.createPublicAPI = function () {
    var _this = this;

    var a = this.Automaton;
    var Chat = a.Chat,
        View = a.View,
        Env = a.Env,
        Data = a.Data,
        Transitions = a.Transitions,
        settings = a.settings,
        utils = a.utils;
    var timers = utils.timers;

    var businessRuleDatapass = _utils2.default.get(a, 'options.datapass');

    var API = {
      hideLayer: _ijsfAdapter2.default.hideLayer,
      showLayer: _ijsfAdapter2.default.showLayer,
      shrinkLayer: _ijsfAdapter2.default.shrinkLayer,
      growLayer: _ijsfAdapter2.default.growLayer,
      fireAssistedEvent: _ijsfAdapter2.default.fireAssistedEvent,
      fireCustomEvent: _ijsfAdapter2.default.fireCustomEvent,
      minimize: _ijsfAdapter2.default.minimizeStage,
      engageChat: Chat.engageChat.bind(Chat),
      map: businessRuleDatapass,
      automatonDataMap: businessRuleDatapass,
      datapass: businessRuleDatapass,
      flashVars: Env.flashVars,
      position: View.position.set.bind(View.position),
      resize: View.resizeStage.bind(View),
      sendToAgent: _ijsfAdapter2.default.sendDatapassMessage,
      sendCustomerMessage: _ijsfAdapter2.default.sendMessageAsCustomer,
      console: _utils2.default.console.bind(_utils2.default),
      top: Env.win,
      document: Env.document,
      getFormData: Data.CRM.toDatapassString.bind(Data.CRM),
      getAllFormData: Data.CRM.toSimpleObject.bind(Data.CRM),
      updateFormItemResponse: Data.CRM.updateFormItemResponse.bind(Data.CRM),
      getPath: Chat.createPathForDatapass.bind(Chat),
      checkAA: Chat.checkAA.bind(Chat),
      automaton: settings,
      cookies: _utils2.default.cookies,
      getBreadcrumbs: Transitions.getBreadcrumbs.bind(Transitions),
      triggerTransition: Transitions.trigger.bind(Transitions),
      setTimeout: timers.setTimeout.bind(timers),
      setInterval: timers.setInterval.bind(timers),
      forms: Data.CRM.apiData,
      getAutomatonAttribute: Data.DW.getAutomatonAttr.bind(Data.DW),
      setAutomatonAttribute: Data.DW.setAutomatonAttr.bind(Data.DW),
      removeAutomatonAttribute: Data.DW.removeAutomatonAttr.bind(Data.DW),
      IJSF: _ijsfAdapter2.default
    };

    // methods from ACIF v2
    API.ciCloseChat = _ijsfAdapter2.default.closeCI;
    API.ciGrowLayer = API.growLayer;
    API.ciHideLayer = API.hideLayer;
    API.ciMinimize = API.minimize;
    API.ciShowLayer = API.showLayer;
    API.ciShrinkLayer = API.shrinkLayer;

    API.getNodeById = function (id) {
      return a.getRecord(id);
    };

    this.Automaton.Data.StorageClass.create(API, 'public-api');

    try {
      API.emailTranscript = _ijsfAdapter2.default.requestTranscript;
    } catch (e) {} // eslint-disable-line no-empty

    API.getPreviousNode = function () {
      var previousNodeID = Data.get('previousNodeID');
      if (!previousNodeID) {
        return;
      }

      return a.getRecord(previousNodeID);
    };

    /**
     * spawn an automaton
     *
     * @method loadAutomaton
     * @param {Object} [params] an object containing launch params
     * @param {Number} [params.automatonID] automaton id to launch
     * @param {String} [params.location] panel in which to load automaton
     * @param {String} [params.ci] context for new automaton
     * @param {Object} [options={}] Options object
     * @param {Boolean} [options.dependent=true] if false, new automaton will not close with original automaton
     */

    API.loadAutomaton = function (params) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : { dependent: true };

      var automaton = this;

      var newAutomaton = ACIF.loadAutomaton(params, { type: 'automaton', id: automaton.id });

      if (options.dependent) {
        automaton.addDependent(newAutomaton);
      }
    };

    /**
     * close an automaton
     *
     * @method closeAutomaton
     * @param {Object} [params] an object containing launch params
     * @param {Number} [params.automatonID] automaton id to launch
     * @param {String} [params.location] panel in which to load automaton
     * @param {String} [params.ci] context for new automaton
     */

    API.closeAutomaton = function (params) {
      var automaton = this;

      var closedAutomaton = ACIF.closeAutomaton(params);

      automaton.removeDependent(closedAutomaton);
    };

    API.sendFormDataToAgent = function (fieldNames) {
      var datapass = {};

      var Automaton = _this.Automaton;

      var $form = Automaton.View.$viewContainer.find('form');
      Automaton.Forms.validateAllFields($form);

      if (fieldNames) {
        fieldNames.forEach(function (fieldNames) {
          datapass[fieldNames] = _this.Data.CRM.getAnswerValueByQuestionId(fieldNames);
        });
      } else {
        datapass = _this.Data.CRM.getResponseValues();
      }

      a.Data.triggerCustomerResponded();

      _ijsfAdapter2.default.sendDatapassMessage(datapass);
    };

    API.logData = function (element, element_response) {
      a.Data.store.addToLogQueue('custom: ' + element, element_response);
    };

    API.getTargetChatNode = function () {
      return _this.Automaton.settings._targetChatNode;
    };

    a.api = API;
  };

  fn.initializeSessionData = function () {
    if (!this.isSessionInitializing) return;

    var Automaton = this.Automaton;
    var settings = Automaton.settings;

    var initialNode = Automaton.getRecord(settings.initialNode);
    var automatonDataMap = Automaton.options.automatonDataMap;


    var automatonAttributes = _.extend({
      type: settings.type,
      sub_type: settings.sub_type || settings.subType
    }, settings.automatonAttributes, _.pick(automatonDataMap, 'type', 'sub_type'), automatonDataMap.automatonAttributes, {
      name: settings.name,
      automaton_id: Automaton.id,
      acif_version: ACIF.VERSION
    });

    this.Automaton.Data.save({
      automaton_id: this.Automaton.id,
      automatonDataMap: automatonDataMap,
      automaton: {},
      automatonAttributes: automatonAttributes,
      path: [initialNode.id],
      currentNodeModulePath: initialNode.modulePath,
      previousNodeID: null,
      dbSync: this.Automaton.Env.dbSync || undefined
    });
  };

  fn.runAutomatonOnInitHook = function () {
    var Automaton = this.Automaton;
    var self = this;
    var onInitPromise = Automaton.utils.makePromise('automaton onInit hook');

    if (this.isSessionInitializing && Automaton.settings.onInit) {
      var response = Automaton.settings.runHook('onInit');

      if (_utils2.default.isPromise(response)) {
        response.always(setInitialNode).always(onInitPromise.resolve);
      } else {
        setInitialNode(response);
        onInitPromise.resolve();
      }
    } else {
      onInitPromise.resolve();
    }

    return onInitPromise;

    function setInitialNode(response) {
      var isNumber = !!Number(response);
      if (isNumber) {
        ACIF.Utils.throwError('Automaton onInit hook can no longer return an integer. It must return a reference string');
      }

      if (_utils2.default.string.isReferenceString(response)) {
        var initialNode = Automaton.getRecord(response);
        var path = self.Data.get('path');
        self.Data.save('currentNodeModulePath', initialNode.modulePath);
        path.pop();
        path.push(response);
        self.Data.save('path', path);
      }
    }
  };

  fn.persistInitialSessionData = function () {
    if (this.Automaton.settings.onInit) {
      this.Automaton.Data.persistCustomAutomatonProperties();
    }
  };

  fn.doAfterAutomatonIsInitialized = function () {
    this.configureInitialNodePath();
    this.benchmark('init').mark('starting to load first node');
    this.loadFirstNodeOnPageLanding();
    this.isSessionInitializing = false;
    _utils2.default.events.trigger('initialized');
    this.initPromise.resolve(this);
  };

  fn.configureInitialNodePath = function () {
    // Allow automaton to define a "root_path" array, which will override
    // the natural breadcrumbs path and automaton back button behavior

    var Automaton = this.Automaton;
    var settings = Automaton.settings;

    if (Array.isArray(settings.root_path)) {
      var root_path = _.clone(settings.root_path);

      // Trim initialNode from the root_path
      var initial_index = root_path.indexOf(this.Data.storage.path[0]);
      if (initial_index > -1) {
        root_path.splice(initial_index, 1);
      }

      Automaton.root_path = root_path;
    } else {
      Automaton.root_path = [];
    }

    Automaton.Transitions.updateDisplayedPath();
  };

  fn.loadFirstNodeOnPageLanding = function () {
    var Automaton = this.Automaton;

    var node = Automaton.getRecord(this.Data.storage.currentNodeModulePath);

    Automaton.Transitions.loadNode(node);

    this.benchmark('init').mark('finished loading first node');
    this.benchmark('init').end();
  };
});