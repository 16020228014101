define('acif/utils/tryCatch', ['exports', '../environment/is-dev-mode', './logger'], function (exports, _isDevMode, _logger) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (tryFn, catchFn) {
    if ((0, _isDevMode2.default)()) {
      return tryFn();
    } else {
      try {
        return tryFn();
      } catch (e) {
        if (typeof catchFn === 'function') {
          return catchFn(e);
        } else if (typeof catchFn === 'string') {
          (0, _logger2.default)(catchFn);
        }
      }
    }
  };

  var _isDevMode2 = _interopRequireDefault(_isDevMode);

  var _logger2 = _interopRequireDefault(_logger);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }
});