define('storage/utils/frame-messenger', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var FrameMessenger = function () {
    function FrameMessenger(options) {
      _classCallCheck(this, FrameMessenger);

      options = options || {};

      this.name = options.name;
      this.frame = options.frame;
      this.targetName = options.targetName;
      this.targetFrame = options.targetFrame;
      this.targetOrigin = options.targetOrigin;
      this.Promise = options.Promise;

      this.callbackCount = 0;
      this.callbacks = {};
      this.listeners = {};

      if (typeof this.name !== 'string') {
        throw new Error('FrameMessenger must be initialized with a string in the `name` property');
      }

      if (typeof this.targetName !== 'string') {
        throw new Error('FrameMessenger must be initialized with a string in the `targetName` property');
      }

      if (!isFrame(this.frame)) {
        throw new Error('FrameMessenger must be initialized with a frame in the `frame` property');
      }

      if (!isFrame(this.targetFrame)) {
        throw new Error('FrameMessenger must be initialized with a frame in the `targetFrame` property');
      }

      this.frame.addEventListener('message', this._processMessage.bind(this));

      function isFrame(win) {
        return !!(win && win.postMessage && typeof win.postMessage === 'function');
      }
    }

    _createClass(FrameMessenger, [{
      key: 'postMessage',
      value: function postMessage(data, callback, replyId, err) {
        var _this = this;

        var callbackId = 'c' + this.callbackCount++;

        if (err) {
          if (err instanceof Error) {
            err = { name: err.name, message: err.message, stack: err.stack };
          } else if (typeof err === 'object') {
            err = JSON.parse(JSON.stringify(err));
          }
        }

        var messageStr = JSON.stringify({
          __name__: this.targetName,
          __origin_name__: this.name,
          __reply__: callbackId,
          __callback__: replyId,
          __error__: err,
          data: data
        });

        this.targetFrame.postMessage(messageStr, this.targetOrigin);

        if (typeof callback === 'function') {
          this.callbacks[callbackId] = callback;
        } else if (this.Promise) {
          return new this.Promise(function (resolve, reject) {
            _this.callbacks[callbackId] = { resolve: resolve, reject: reject };
          });
        } else {
          // Create a no-op callback if none was provided and Promises are not being used
          this.callbacks[callbackId] = function () {};
        }
      }
    }, {
      key: '_processMessage',
      value: function _processMessage(event) {
        var _this2 = this;

        var messageStr = event.data;
        var message = jsonParse(messageStr);
        var isLegit = event.source === this.targetFrame && message.__name__ === this.name;

        if (!isLegit) return;

        var callbackId = message.__callback__;
        var callback = this.callbacks[callbackId];

        var reply = function reply(err, data, callback) {
          _this2.postMessage(data, callback, message.__reply__, err);
        };

        if (callback) {

          delete this.callbacks[callbackId];

          if (message.__error__) {
            if (callback.reject) {
              callback.reject(message.__error__);
            } else {
              callback(message.__error__);
            }
          } else {
            if (callback.resolve) {
              callback.resolve(message.data);
            } else {
              callback(null, message.data, reply);
            }
          }
        } else if (this._onMessage) {
          this._onMessage(message.data, reply);
        } else {
          // eslint-disable-next-line no-console
          console.error('No callback existed to handle callback ID ' + callbackId);
        }
      }
    }, {
      key: 'onMessage',
      value: function onMessage(callback) {
        this._onMessage = callback;
      }
    }]);

    return FrameMessenger;
  }();

  exports.default = FrameMessenger;


  function jsonParse(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return {};
    }
  }
});