define('acif/utils/logger', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.logError = logError;
  var logger = function () {
    var prefix = 'tc_acif: ';

    var logger = function logger(msg, severity) {
      severity = severity || 'log';
      logger[severity](msg);
    };

    var console = window.console = window.console || {};

    logger.log = typeof console.log !== 'undefined' ? function (msg) {
      console.log(prefix + msg);
    } : $.noop;

    logger.debug = typeof console.debug !== 'undefined' ? function (msg) {
      console.debug(prefix + msg);
    } : logger.log;

    logger.error = function (msg) {
      typeof console.error !== 'undefined' ? console.error(prefix + msg) : logger.log(msg);

      var logText = msg && msg.stack ? msg.stack : msg;

      // FlashPeer's logACIFError will log the first argument on the tagserver. Additionally, the method will
      // append the following properties, semicolon separated...
      // chatID, custID, custIP, siteID and pageID
      return inqFrame.Inq.FlashPeer.logAcifError('ACIF-ERROR: ' + encodeURIComponent(logText) + '; acifVersion: ' + ACIF.VERSION + ';');
    };

    logger.warn = typeof console.warn !== 'undefined' ? function (msg) {
      console.warn(prefix + msg);
    } : logger.log;

    logger.deprecate = function (msg) {
      msg = 'DEPRECATION NOTICE: ' + msg;
      this.warn(msg);
    };

    return logger;
  }();

  exports.default = logger;
  function logError(error) {
    logger(error, 'error');
  }
});