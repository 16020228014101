define('acif/data/alerts', ['exports', '../ci/ijsf-adapter'], function (exports, _ijsfAdapter) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Alerts;


  function Alerts(Automaton) {
    this.Automaton = Automaton;
  }

  var fn = Alerts.prototype;

  fn.checkAlertConditions = function () {
    var _this = this;

    this.surveyAlertConfigs = this.Automaton.getProp('surveyAlerts');
    if (!Array.isArray(this.surveyAlertConfigs)) return;

    $(this.Automaton.Data.DW).one('onEventsReported', function (event, allSuccessfull) {
      if (allSuccessfull) {
        var data = _this.getPostData();
        _ijsfAdapter2.default.processSurveyAlerts(data);
      }
    });
  };

  fn.getPostData = function () {
    return {
      engagementId: _ijsfAdapter2.default.getEngagementId(),
      siteId: _ijsfAdapter2.default.getSiteId(),
      surveyQuestions: this.getResponsesWithEncodedAnswers(),
      surveyAlerts: this.getSurveyAlerts()
    };
  };

  fn.getSurveyAlerts = function () {
    var _this2 = this;

    var alerts = this.surveyAlertConfigs;

    alerts = alerts.map(function (alert) {
      var compiledAlert = _.clone(alert);

      compiledAlert.emailSubject = alert.emailSubject || 'Survey Submission Alert';
      compiledAlert.emailTemplate = _this2.getEmailBody(alert.emailTemplate) || '[all-customer-responses]';

      return compiledAlert;
    });

    return alerts;
  };

  fn.getEmailBody = function (emailTemplateId) {
    if (!emailTemplateId) return;

    var template = this.Automaton.getRecord(emailTemplateId, 'template');
    return template.getHTML().innerHTML;
  };

  fn.getResponsesWithEncodedAnswers = function () {
    var responses = this.Automaton.Data.CRM.getResponseList();

    return responses.map(function (response) {
      return {
        id: response.questionId,
        question: response.questionText,
        answer: response.answerText || ''
      };
    });
  };
});