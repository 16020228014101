define('acif/environment/get-env-var', ['exports', './globals'], function (exports, _globals) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getVar;

  var _globals2 = _interopRequireDefault(_globals);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  var cache = {};

  function getVar(propName) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var value = cache[propName];

    if (value !== undefined) return value;

    var globals = (0, _globals2.default)();

    var win = globals.window;
    var regex = new RegExp(propName + '=?([^&]*|)([&#]|$)', 'i');

    try {
      var match = globals.location.href.match(regex);

      value = (match ? match[1] || true : null) || (sessionStorage ? sessionStorage.getItem(propName) : null) || (localStorage ? localStorage.getItem(propName) : null) || win[propName] || win[propName.replace(/-/g, '_')];

      if (options.persist && value && window.sessionStorage) {
        sessionStorage.setItem(propName, value);
      }
    } catch (e) {}

    cache[propName] = value;

    return value;
  }
});