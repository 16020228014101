define('acif/utils/mixins/jquery', ['../../environment'], function (_environment) {
    var _environment2 = _interopRequireDefault(_environment);

    function _interopRequireDefault(obj) {
        return obj && obj.__esModule ? obj : {
            default: obj
        };
    }

    // taken from http://stackoverflow.com/a/10835425 (with modifications)
    jQuery.fn.removeClassPrefix = function (prefix) {
        this.each(function (i, el) {
            var classes = _.filter(el.className.split(' '), function (c) {
                return c.lastIndexOf(prefix, 0) !== 0;
            });
            el.className = $.trim(classes.join(' '));
        });
        return this;
    };

    // taken from http://stackoverflow.com/a/12218972
    jQuery.fn.values = function (data) {
        var els = this.find(':input').get();

        if (arguments.length === 0) {
            // return all data
            data = {};

            $.each(els, function () {
                if (this.name && !this.disabled && (this.checked || /select|textarea/i.test(this.nodeName) || /text|hidden|password/i.test(this.type))) {
                    if (data[this.name] === undefined) {
                        data[this.name] = [];
                    }
                    data[this.name].push($(this).val());
                }
            });
            return data;
        } else {
            $.each(els, function () {
                if (this.name && data[this.name]) {
                    var names = data[this.name];
                    var $this = $(this);
                    if (Object.prototype.toString.call(names) !== '[object Array]') {
                        names = [names]; //backwards compat to old version of this code
                    }
                    if (this.type == 'checkbox' || this.type == 'radio') {
                        var val = $this.val();
                        var found = false;
                        for (var i = 0; i < names.length; i++) {
                            if (names[i] == val) {
                                found = true;
                                break;
                            }
                        }
                        $this.attr('checked', found);
                    } else {
                        $this.val(names[0]);
                    }
                }
            });
            return this;
        }
    };

    // Due to IOS/Safari bug where focus has to be within a touchstart event
    // Idea from http://stackoverflow.com/questions/18728166/programatically-focus-on-next-input-field-in-mobile-safari
    if (_environment2.default.isIOS) {
        var focus = jQuery.fn.focus;
        jQuery.fn.focus = function () {

            if (arguments.length) {
                return focus.apply(this, arguments);
            } else {
                return this.trigger('touchstart').trigger('focus');
            }
        };
    }
});