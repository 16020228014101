define('acif/data/evaluator', ['exports', '../utils', '../ci/ijsf-adapter'], function (exports, _utils, _ijsfAdapter) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Evaluator;


  function Evaluator(conditions, responses) {
    this.totalEvaluated = 0;
    this.responses = responses;

    var condition = Array.isArray(conditions) ? { and: conditions } : conditions;

    var isValid = this.evaluateConditional(condition);

    this._isSatisfied = isValid;
  }

  var fn = Evaluator.prototype;

  var OPERATORS = {
    'equals': 'equals',
    '==': 'equals',
    '=': 'equals',
    '===': 'equals',
    '!=': 'not-equal',
    '!==': 'not-equal',

    'contains any': 'contains-any',
    'contains none': 'contains-none',
    'contains': 'contains',
    'does not contain': 'not-contain',
    'doesn\'t contain': 'not-contain',
    'starts with': 'starts-with',
    'ends with': 'ends-with',
    'does not start with': 'not-start-with',
    'doesn\'t start with': 'not-start-with',
    'does not end with': 'not-end-with',
    'doesn\'t end with': 'not-end-with',

    '>': 'greater-than',
    '>=': 'greater-than-or-equal-to',
    '<': 'less-than',
    '<=': 'less-than-or-equal-to',
    'is greater than or equal to': 'greater-than-or-equal-to',
    'is greater than': 'greater-than',

    'is less than or equal to': 'less-than-or-equal-to',
    'is less than': 'less-than',

    'in': 'in',
    'not in': 'not-in',

    'is blank': 'is-blank',
    'is not blank': 'is-not-blank',
    'is not': 'not-equal',
    'is': 'equals'
  };

  var STRING_OPS = ['equals', 'not-equal', 'contains-any', 'contains-none', 'contains', 'starts-with', 'not-start-with', 'ends-with', 'not-end-with', 'in', 'not in'];

  fn.and = function (conditions) {
    var _this = this;

    var firstFalsyConditionIndex = _.findIndex(conditions, function (condition) {
      var isSatisfied = _this.evaluateConditional(condition);
      return isSatisfied !== true;
    });

    return firstFalsyConditionIndex === -1;
  };

  fn.or = function (conditions) {
    var _this2 = this;

    var firstTruthyConditionIndex = _.findIndex(conditions, function (condition) {
      return _this2.evaluateConditional(condition);
    });

    return firstTruthyConditionIndex > -1;
  };

  fn.not = function (conditions) {
    return !this.and(conditions);
  };

  fn.nor = function (conditions) {
    return !this.or(conditions);
  };

  fn.evaluateConditional = function (condition) {
    var _this3 = this;

    return _utils2.default.tryCatch(function () {
      return _this3._evaluateConditional(condition);
    }, function (err) {
      try {
        condition = JSON.stringify(condition, null, 4);
      } catch (e) {} // eslint-disable-line

      _utils2.default.console.log('Could not evaluate condition: ' + condition);
      _utils2.default.console.log(err.stack);
    });
  };

  fn._evaluateConditional = function (condition) {
    if (Array.isArray(condition.or)) {
      return this.or(condition.or);
    } else if (Array.isArray(condition.and)) {
      return this.and(condition.and);
    } else if (Array.isArray(condition.not)) {
      return this.not(condition.not);
    } else if (Array.isArray(condition.nor)) {
      return this.nor(condition.nor);
    }

    this.totalEvaluated++;

    var args = Evaluator.parse(condition);

    if (!args) {
      _utils2.default.throwError(condition + ' is not a valid condition');
    }

    var ref = args.ref,
        operator = args.operator,
        expected = args.expected;


    var actualValue = this.getActualValue(ref);

    expected = this.getKeywordReference(expected) || expected;

    operator = Evaluator.operators[operator] ? operator : OPERATORS[operator];

    if (STRING_OPS.indexOf(operator) > -1) {
      expected = normalizeString(expected);
      actualValue = normalizeString(actualValue);
    }

    var fn = Evaluator.operators[operator];

    if (typeof fn === 'function') {

      var isSatisfied = fn(expected, actualValue);
      if (!isSatisfied) {
        this.firstInvalid = condition;
      }
      return isSatisfied;
    } else {
      _utils2.default.console.log(args.operator + ' is not a valid operator');
      return;
    }
  };

  fn.getActualValue = function (ref) {
    var actualValue = this.getKeywordReference(ref);

    if (actualValue) return actualValue;

    var questionIndex = _.findIndex(this.responses, function (response) {
      return response.questionId.toString() === ref.toString();
    });

    if (questionIndex === -1) {
      return;
    }

    var question = this.responses[questionIndex];

    actualValue = question.answerText;

    return actualValue;
  };

  var KEYWORDS = {
    'br-name': _ijsfAdapter2.default.getBusinessRuleName,
    'co-browse': '',
    'agent-group-id': _ijsfAdapter2.default.getAgentGroupId,
    'agent-group-name': _ijsfAdapter2.default.getAgentGroupName,
    'business-unit-id': _ijsfAdapter2.default.getBusinessUnitId,
    'business-unit-name': _ijsfAdapter2.default.getBusinessUnitName
  };

  var keywordsRegex = new RegExp('^(' + Object.keys(KEYWORDS).join('|') + ')$', 'i');

  fn.getKeywordReference = function (ref) {
    if (typeof ref !== 'string') return;

    var agentAttrRegex = /^agent-attribute\.(.*)/i;
    var agentAttrMatch = ref.match(agentAttrRegex);

    if (agentAttrMatch) {
      var agentAttrName = agentAttrMatch[1];
      return _ijsfAdapter2.default.getAgentAttr(agentAttrName);
    }

    var keywordMatch = ref.match(keywordsRegex);

    if (keywordMatch) {
      try {
        var keyword = keywordMatch[1];
        return KEYWORDS[keyword]();
      } catch (e) {} // eslint-disable-line
    }
  };

  function normalizeString(val) {
    if (Array.isArray(val)) {
      return val.map(function (el) {
        return normalizeString(el);
      });
    }

    return String(val).toLowerCase();
  }

  fn.isSatisfied = function () {
    return this._isSatisfied;
  };

  var op = Evaluator.operators = {};

  op['equals'] = function (expected, actual) {
    return expected === actual;
  };

  op['not-equal'] = function (expected, actual) {
    return !op.equals(expected, actual);
  };

  op['contains'] = function (expected, actual) {
    return actual.indexOf(expected) > -1;
  };

  op['not-contain'] = function (expected, actual) {
    return !op.contains(expected, actual);
  };

  op['contains-any'] = function (expected, actual) {
    return _.findIndex(expected, function (val) {
      return actual.match(val);
    }) > -1;
  };

  op['contains-none'] = function (expected, actual) {
    return !op['contains-any'](expected, actual);
  };

  op['starts-with'] = function (expected, actual) {
    return actual.indexOf(expected) === 0;
  };

  op['not-start-with'] = function (expected, actual) {
    return !op['starts-with'](expected, actual);
  };

  op['ends-with'] = function (expected, actual) {
    return !!actual.match(new RegExp(expected + '$', 'i'));
  };

  op['not-end-with'] = function (expected, actual) {
    return !op['ends-with'](expected, actual);
  };

  op['is-blank'] = function (expected, actual) {
    return String(actual || '').trim() === '';
  };

  op['is-not-blank'] = function (expected, actual) {
    return !op['is-blank'](expected, actual);
  };

  op['in'] = function (expected, actual) {
    return expected.indexOf(actual) > -1;
  };

  op['not-in'] = function (expected, actual) {
    return !op['in'](expected, actual);
  };

  op['greater-than'] = function (expected, actual) {
    return Number(actual) > Number(expected);
  };

  op['greater-than-or-equal-to'] = function (expected, actual) {
    return Number(actual) >= Number(expected);
  };

  op['less-than'] = function (expected, actual) {
    return Number(actual) < Number(expected);
  };

  op['less-than-or-equal-to'] = function (expected, actual) {
    return Number(actual) <= Number(expected);
  };

  Evaluator.parse = function (cond) {
    if (typeof cond === 'string') {
      return this.parse.string(cond);
    } else if (Array.isArray(cond)) {
      return this.parse.array(cond);
    }
  };

  Evaluator.parse.string = function (str) {
    var ops = Object.keys(OPERATORS).map(function (op) {
      return op.replace(/\s+/g, '[\\s_-]');
    }).join('|');
    var regex = new RegExp('^([\\w-\\.\\[\\]\'"]+)\\b\\s*(' + ops + ')\\s*\\b([\\w-\\.\\[\\]\'"\\s,]+)$', 'i');
    var match = str.match(regex);

    if (match) {
      var ref = match[1];
      var operator = match[2];
      var expected = match[3];

      if (operator === 'is' && expected === 'blank') {
        operator = 'is-blank';
        expected = null;
      } else if (operator === 'is not' && expected === 'blank') {
        operator = 'is-not-blank';
        expected = null;
      } else {
        operator = OPERATORS[operator];
      }

      var expectAnArray = !!operator.match(/^(|not-)in|contains-any|contains-none$/);
      if (expectAnArray) {
        expected = expected.split(/\s*,\s*/g);
      }

      return { ref: ref, operator: operator, expected: expected };
    }
  };

  Evaluator.parse.array = function (arr) {
    var ref = arr.shift();
    var operator = arr.shift();
    var expected = arr;

    if (expected.length) {
      var expectAnArray = operator.toString().match(/^(|not-)in$/i);
      if (!expectAnArray) {
        expected = expected[0];
      }
    } else {
      expected = operator;
      operator = expected instanceof RegExp ? 'regex' : 'equals';
    }

    return {
      ref: ref,
      operator: operator,
      expected: expected
    };
  };
});