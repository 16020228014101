define('acif/utils/events', ['exports', './tryCatch', './logger'], function (exports, _tryCatch, _logger) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _tryCatch2 = _interopRequireDefault(_tryCatch);

  var _logger2 = _interopRequireDefault(_logger);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  var events = {

    trigger: function trigger(eventName) {
      var args = Array.prototype.slice.call(arguments);
      args.shift();

      this.handlers.forEach(function (handler) {
        if (typeof handler[eventName] === 'function') {
          (0, _tryCatch2.default)(function () {
            handler[eventName].apply(null, args);
          }, function (error) {
            var msg = 'An error occurred in the ' + eventName + ' event handler. Received error:\n' + error.message + '\n' + handler[eventName].toString();
            (0, _logger2.default)(msg, 'error');
          });
        }
      });
    },

    handlers: [],

    loadHandlers: function loadHandlers(handlers) {
      this.handlers = this.handlers.concat(handlers);
    },

    addListener: function addListener(name, handler) {
      var listener = {};
      listener[name] = handler;
      this.loadHandlers(listener);
    }
  };

  exports.default = events;
});