define('acif/utils/underscore', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = underscore;
  function underscore(string) {
    if (!string) return;

    if (Array.isArray(string)) {
      return string.map(underscore);
    }

    string = String(string);

    return string.replace(/\W+/g, ' ').replace(/[^\w\s]+/g, '').trim().replace(/\s+/g, ' ').replace(/([a-z\d])([A-Z]+)/g, '$1_$2').replace(/[-\s]+/g, '_').toLowerCase();
  }
});