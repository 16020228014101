define('acif/models/Style', ['exports', '../ci/ijsf-adapter'], function (exports, _ijsfAdapter) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Style;


  function Style(css) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    this.css = css.replace(/{{clientMediaBaseUrl}}/g, _ijsfAdapter2.default.getClientMediaBaseUrl());

    _.extend(this, options);
  }

  var fn = Style.prototype = { constructor: Style };

  fn._modelType = 'style';

  fn.addNamespace = function (ns) {
    var _this = this;

    var regex = /#viewport[^{]*/gm;
    var matches = this.css.match(regex) || [];

    matches.forEach(function (str) {
      var nsSelector = str.replace(/([\.#])/gm, '$1' + ns);
      _this.css = _this.css.replace(str, nsSelector);
    });

    return this;
  };

  fn.updateTooltipClassName = function () {
    var tooltipClassName = 'inq-' + this.Automaton.id + '-ui-tooltip';
    this.css = this.css.replace(/#[\w-]*viewport\s+(.*\.[\w-]*tooltip)\b/g, '$1');
    this.css = 'body .' + tooltipClassName + ' { background: white; z-index: ' + this.Automaton.View.$skin.css('z-index') + ';}\n' + this.css;
    return this;
  };

  fn.addToDOM = function () {
    var Automaton = this.Automaton;

    var id = 'tc-' + Automaton.options.location + '-styles';
    var doc = Automaton.Env.document;

    // remove any existing style tags (if the automaton was
    // previously loaded on the same page)
    $('#' + id, doc).remove();

    var styleEl = Style.loadStyleSheet(this.css, id, null, doc);

    $(styleEl).attr('acif-automaton-id', Automaton.id);

    return this;
  };

  fn.getCSS = function () {
    return this.css;
  };

  fn.preloadImages = function () {
    var regex = /url\(['"]?([^'"\)]+)['"]?\)/g,
        img = void 0,
        match = void 0;

    while (match = regex.exec(this.css)) {
      img = new Image();
      img.src = match[1];
    }

    return this;
  };

  Style.loadStyleSheet = function (css, id, external, doc) {
    var el = doc.createElement(external ? 'link' : 'style');

    el.type = 'text/css';

    if (id) {
      el.id = id;
    }

    if (external) {
      $(el).attr('href', css).attr('rel', 'stylesheet');
    } else if (el.styleSheet) {
      el.styleSheet.cssText = css;
    } else {
      el.appendChild(doc.createTextNode(css));
    }

    $('head', doc).append(el);

    return el;
  };
});