define('acif/view/formify/precompiled/formify-type-select', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (obj) {
    var __t,
        __p = '',
        __j = Array.prototype.join,
        print = function print() {
      __p += __j.call(arguments, '');
    };
    with (obj || {}) {
      __p += '<fieldset class="formify-fieldset-select" name="' + ((__t = field.id) == null ? '' : __t) + '" acif-field-type="' + ((__t = field.type_attr) == null ? '' : __t) + '" ' + ((__t = field._attrs) == null ? '' : __t) + '>\n  <label class="formify-label">' + ((__t = field.label) == null ? '' : __t) + '</label>\n  <select class="formify-input" ' + ((__t = field._multiple_attr + field._min_attr + field._max_attr) == null ? '' : __t) + ' >\n\n    ';
      if (!field.multiple) {
        __p += '\n      <option value="">' + ((__t = field.placeholder) == null ? '' : __t) + '</option>\n    ';
      }
      __p += '\n\n    ';
      _.each(field.options, function (rawOption) {
        var option = {};
        option.label = rawOption.label || rawOption;
        option.value = rawOption.value || rawOption;

        __p += '\n    <option value="' + ((__t = option.value) == null ? '' : __t) + '">' + ((__t = option.label) == null ? '' : __t) + '</option>\n\n    ';
      });
      __p += '\n  </select>\n  ' + ((__t = field.validate._html) == null ? '' : __t) + '\n</fieldset>';
    }
    return __p;
  };
});