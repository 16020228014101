define('acif/view/formify/precompiled/formify-header', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (obj) {
    var __t,
        __p = '',
        __j = Array.prototype.join,
        print = function print() {
      __p += __j.call(arguments, '');
    };
    with (obj || {}) {
      __p += '<header class="formify-header" acif-aria-speak>\n    ';
      if (formify.heading) {
        __p += '\n    <h1 class="formify-heading">' + ((__t = formify.heading) == null ? '' : __t) + '</h1>\n    ';
      }
      __p += '\n    <p class="formify-heading-required">Required items indicated with *.</p>\n</header>';
    }
    return __p;
  };
});