define('acif/view/FieldsetView', ['exports', '../utils/throwError', '../environment'], function (exports, _throwError, _environment) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _throwError2 = _interopRequireDefault(_throwError);

  var _environment2 = _interopRequireDefault(_environment);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = FieldsetView;


  function FieldsetView(element) {
    this.el = element;
    this.$el = $(element);
    this.$inputs = this.$el.find(':input');
    this.$invalids = this.$el.find('[acif-invalid]');

    this.name = this.$el.attr('name') || '';
    this.type = this.$el.attr('type') || this.$el.attr('acif-type') || '';

    this.$el.removeAttr('name');

    this.$el.addClass('pristine');

    this.$invalids.attr('hidden', 'true');

    if (this.type) {
      this.addTypeAttributeToInputs();
    } else {
      this.type = this.$inputs.first().attr('type') || (this.$inputs.length ? this.$inputs[0].tagName.toLowerCase() : '');
    }

    if (this.type) {
      this.$el.addClass(this.type + '-fieldset');
      this.$el.attr('acif-field-type', this.type);
    }

    if (this.name) {
      this.addNameAttributeToInputs();
    } else {
      this.name = this.$inputs.first().attr('name');
    }

    if (!this.name) {
      var fieldsetClone = this.$el.clone();
      var fieldsetHTML = $('<div>').append(fieldsetClone).html();
      var msg = 'Fieldset with the content below must contain a name attribute.\n\n';
      (0, _throwError2.default)(msg + fieldsetHTML);
    }

    // Add value attribute to <option> elements without value attributes
    if (this.type.match(/^select/i)) {
      this.$el.find('option:not([value])').each(function () {
        var $option = $(this);
        $option.attr('value', $option.text().trim());
      });
    }

    this.$el.attr('acif-field-name', this.name);

    if (this.$el.is('[required]')) {
      this.$inputs.first().prop('required', true);
      this.$el.attr('acif-required', true);
      this.$el.removeAttr('required');
    }

    if (this.$el.is('[acif-masked]')) {
      this.$el.removeAttr('acif-masked');
      this.$inputs.attr('acif-masked', true);
    }

    if (this.type.match(/checkbox|radio/)) {
      this.wrapInputsInLabelElements();
    }
  }

  var fn = FieldsetView.prototype = { constructor: FieldsetView };

  fn.addTypeAttributeToInputs = function () {
    if (_environment2.default.isIE8) {
      // Older version of IE do not allow setting the type and name attributes
      // on input elements. Therefore, new input elements must be
      // created with the correct type, which then replace the original
      // input elements.

      var self = this;

      this.$inputs.each(function () {
        var $this = $(this);
        var value = $this.is('[value=""]') ? '' : $this.val();
        var $input = $('<input type="' + self.type + '"' + (self.name ? 'name="' + self.name + '"' : '') + '/>');

        if (value) {
          $input.attr('value', value);
        }

        $this.replaceWith($input);
      });

      this.$inputs = this.$el.find(':input');
    } else {

      this.$inputs.attr('type', this.type);
    }
  };

  fn.addNameAttributeToInputs = function () {

    if (_environment2.default.isIE8 && this.type.match(/^select/i)) {

      // Older version of IE do not allow setting the name attributes
      // on select elements. Therefore, new select elements must be
      // created with the correct name, which then replace the original
      // select elements.

      var $select = this.$inputs.first();
      var options = $select.html();
      var $newSelect = $('<select name="' + this.name + '" >' + options + '</select>');

      $select.replaceWith($newSelect);

      this.$inputs = this.$el.find(':input');
    } else {

      this.$inputs.not('[name]').prop('name', this.name);
    }
  };

  fn.wrapInputsInLabelElements = function () {
    this.$inputs.each(function () {
      var $this = $(this);
      var $label = $this.closest('label');

      if (!$label.length) {
        (0, _throwError2.default)('Checkbox and radio <input> elements must be wrapped in <label> elements.');
      }

      // If a checkbox or radio element has no value attribute, then
      // give it a value attribute set to its label's text content
      if (!$this.is('[value]') || $this.is('[value=""]')) {
        var value = $label.text().trim();
        $this.attr('value', value);
      }
    });
  };
});