define('acif/view/formify/templates-precompiled', ['exports', './precompiled/formify-master', './precompiled/formify-footer', './precompiled/formify-header', './precompiled/formify-type', './precompiled/formify-type-checkbox', './precompiled/formify-type-email', './precompiled/formify-type-label', './precompiled/formify-type-radio', './precompiled/formify-type-radio-scale', './precompiled/formify-type-select', './precompiled/formify-type-text', './precompiled/formify-type-textarea'], function (exports, _formifyMaster, _formifyFooter, _formifyHeader, _formifyType, _formifyTypeCheckbox, _formifyTypeEmail, _formifyTypeLabel, _formifyTypeRadio, _formifyTypeRadioScale, _formifyTypeSelect, _formifyTypeText, _formifyTypeTextarea) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _formifyMaster2 = _interopRequireDefault(_formifyMaster);

  var _formifyFooter2 = _interopRequireDefault(_formifyFooter);

  var _formifyHeader2 = _interopRequireDefault(_formifyHeader);

  var _formifyType2 = _interopRequireDefault(_formifyType);

  var _formifyTypeCheckbox2 = _interopRequireDefault(_formifyTypeCheckbox);

  var _formifyTypeEmail2 = _interopRequireDefault(_formifyTypeEmail);

  var _formifyTypeLabel2 = _interopRequireDefault(_formifyTypeLabel);

  var _formifyTypeRadio2 = _interopRequireDefault(_formifyTypeRadio);

  var _formifyTypeRadioScale2 = _interopRequireDefault(_formifyTypeRadioScale);

  var _formifyTypeSelect2 = _interopRequireDefault(_formifyTypeSelect);

  var _formifyTypeText2 = _interopRequireDefault(_formifyTypeText);

  var _formifyTypeTextarea2 = _interopRequireDefault(_formifyTypeTextarea);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = {
    'formify': _formifyMaster2.default,
    'formify-footer': _formifyFooter2.default,
    'formify-header': _formifyHeader2.default,
    'formify-type': _formifyType2.default,
    'formify-type-checkbox': _formifyTypeCheckbox2.default,
    'formify-type-email': _formifyTypeEmail2.default,
    'formify-type-label': _formifyTypeLabel2.default,
    'formify-type-radio': _formifyTypeRadio2.default,
    'formify-type-radio-scale': _formifyTypeRadioScale2.default,
    'formify-type-select': _formifyTypeSelect2.default,
    'formify-type-text': _formifyTypeText2.default,
    'formify-type-textarea': _formifyTypeTextarea2.default
  };
});