define('acif/utils/benchmark', ['exports', '../environment/is-dev-mode', './logger'], function (exports, _isDevMode, _logger) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _isDevMode2 = _interopRequireDefault(_isDevMode);

  var _logger2 = _interopRequireDefault(_logger);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = createBenchmark;


  function createBenchmark() {
    var disableBenchmarking = void 0;
    try {
      disableBenchmarking = !((0, _isDevMode2.default)() || window.sessionStorage && window.sessionStorage.getItem('tc_acif_benchmark'));
    } catch (e) {}

    if (disableBenchmarking) {
      var noop = function noop() {
        return noop;
      };
      return noop.start = noop.mark = noop.end = noop;
    }

    var benches = {};

    return function () {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'anon';

      var bench = benches[name] = benches[name] || {
        marks: []
      };

      var benchmark = mark;
      benchmark.start = start;
      benchmark.mark = mark;
      benchmark.end = end;

      return benchmark;

      function start(msg) {
        msg = msg || 'Staring benchmark';

        bench.startTime = bench.lastMarkTime = Date.now();
        bench.markNum = 0;
        return this;
      }

      function mark(msg) {
        if (!bench.startTime) return this;

        var now = Date.now();
        var sinceStartTime = now - bench.startTime;
        var duration = now - bench.lastMarkTime;

        bench.markNum++;
        bench.lastMarkTime = now;

        msg = msg || 'Mark: ' + bench.markNum;
        msg += ', Lapsed: ' + duration + 'ms';
        msg += ', Total: ' + sinceStartTime + 'ms';

        bench.marks.push({
          msg: msg,
          duration: duration,
          total: sinceStartTime
        });

        return this;
      }

      function end() {
        if (!bench.startTime) {
          return;
        }

        var lapsed = Date.now() - bench.startTime;
        var msg = 'Benchmark: ' + name + ' - Total time = ' + lapsed + 'ms';

        if (window.console && console.group) {
          console.group(msg);
        } else {
          (0, _logger2.default)(msg);
        }

        bench.marks.forEach(function (mark, i) {
          var percent = Math.floor(1000 * mark.duration / lapsed) / 10 + '%';
          (0, _logger2.default)(++i + ') [' + percent + '] ' + mark.msg);
        });

        if (window.console && console.groupEnd) {
          console.groupEnd();
        }

        delete benches[name];
      }
    };
  }
});