define('storage/top-frame/index', ['exports', '../utils/frame-messenger', './local-storage-api', './session-storage-api', './id-manager'], function (exports, _frameMessenger, _localStorageApi, _sessionStorageApi, _idManager) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = storageTopFrame;

  var _frameMessenger2 = _interopRequireDefault(_frameMessenger);

  var _localStorageApi2 = _interopRequireDefault(_localStorageApi);

  var _sessionStorageApi2 = _interopRequireDefault(_sessionStorageApi);

  var _idManager2 = _interopRequireDefault(_idManager);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function storageTopFrame() {
    var inQ = window.inQ = window.inQ || {};

    var iframeUrl = getIFrameUrl();

    var iframe = loadIframe();

    loadFrameMessenger(iframe);

    function loadIframe() {
      var iframe = document.createElement('iframe');
      var jsUrl = inQ.ENV === 'test' ? $('script').filter('[src*="acif.js"], [src*="acif-debug.js"]').last().attr('src') : $('script').last().attr('src');

      // Prepare data to pass into different origin iframe
      var data = {
        siteId: (0, _idManager2.default)('site'),
        sessionId: (0, _idManager2.default)('session'),
        engagementId: (0, _idManager2.default)('engagement'),
        tabId: (0, _idManager2.default)('tab'),
        jsUrl: jsUrl,
        targetOrigin: getTargetOrigin(location.href)
      };

      // Enforce https
      iframeUrl = iframeUrl.replace(/^https?/, 'https');
      iframe.name = encodeURIComponent(JSON.stringify(data));
      iframe.src = iframeUrl;
      iframe.style.display = 'none';

      var isClientJSHostingEnabled = Inq.API.get('noJSHostingEnabled');
      if (isClientJSHostingEnabled) {
        iframe.sandbox = 'allow-scripts allow-same-origin';
      }

      document.body.appendChild(iframe);

      return iframe;
    }

    function loadFrameMessenger(iframe) {
      // Get origin for storage.html page
      var frameMessenger = new _frameMessenger2.default({
        frame: window,
        name: 'tc-storage-top-frame',
        targetFrame: iframe.contentWindow,
        targetName: 'tc-storage-iframe',
        targetOrigin: getTargetOrigin(iframeUrl)
      });

      frameMessenger.onMessage(function (data, reply) {
        var Storage = void 0;

        if (data.code === 'initial storage data') {
          Storage = new _localStorageApi2.default(frameMessenger, data.storage);
        } else if (data.code === 'local storage failed') {
          Storage = new _sessionStorageApi2.default(data.storage);
        }

        if (Storage) {
          inQ.Storage = Storage;
          Inq.fireCustomEvent('StorageReady');
        }
      });

      return frameMessenger;
    }

    function getTargetOrigin(url) {
      var a = document.createElement('a');
      a.href = url;

      var protocol = a.protocol,
          hostname = a.hostname,
          port = a.port;

      return protocol + '//' + hostname + (port ? ':' + port : '');
    }

    function getIFrameUrl() {
      var isClientJSHostingEnabled = Inq.API.get('noJSHostingEnabled');

      if (isClientJSHostingEnabled) {
        return Inq.API.get('staticClientURL') + '/acif/storage.html';
      }

      var skinUrl = inqFrame.Inq.urls.skinURL.replace(/flash\/$/, '');

      if (ACIF.baseUrl && ACIF.baseUrl.match('localhost')) return ACIF.baseUrl + '/storage.html';

      return ACIF.isLoadedNatively ? inqFrame.Inq.urls.mediaRootURL + '/media/launch/acif/storage.html' : skinUrl + 'assets/local-storage/storage.html';
    }
  }
});