define('acif/transitions/TransitionEvaluator', ['exports', '../utils'], function (exports, _utils) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = TransitionEvaluator;


  function TransitionEvaluator(transitionName, Automaton) {
    this.name = transitionName;
    this.Automaton = Automaton;
    this.index = -1;
    this.entries = this.findMatchingTransitionEntries(transitionName);
    this.evaluateAllEntriesPromise = new $.Deferred();
    this.nextEntry();
  }

  var fn = TransitionEvaluator.prototype = { constructor: TransitionEvaluator };

  fn.findMatchingTransitionEntries = function (transitionName) {
    var node = this.Automaton.node || {};
    var nodeTransitions = node.transitions || {};
    var automatonTransitions = this.Automaton.settings.transitions || {};
    var entries = [].concat(automatonTransitions[transitionName]).concat(nodeTransitions[transitionName]);

    return _.compact(entries);
  };

  fn.nextEntry = function () {
    this.index++;

    if (this.index < this.entries.length) {
      this.entry = this.entries[this.index];
      this.evaluate();
    } else {
      _utils2.default.console('Transition event "' + this.name + '" was triggered, but did not result in a node transition.');
      this.evaluateAllEntriesPromise.reject();
    }
  };

  fn.evaluate = function () {
    var entry = this.entry;

    // Allow a transition entry to be a simple number or string.
    // Numbers should represent the ID of the next node to enter.
    // Permitted strings are 'back' and 'start_over'.
    if (_.isNumber(entry) || _.isString(entry)) {
      this.chooseNode(entry);
      return;
    }

    var target = entry.target;

    // If a target propery isn't defined on the transition entry,
    // then consider the transition entry to be falsey and skip it.
    if (!target) {
      this.nextEntry();
      return;
    }

    // The automaton_id property should be a single ID or array of
    // automaton ID numbers. The transition entry will only be
    // considered truthy if the specified automaton ID(s) match
    // the currently running automaton ID
    if (entry.automaton_id) {
      var automatonsToMatch = [].concat(entry.automaton_id);
      if (automatonsToMatch.indexOf(this.Automaton.settings.id) === -1) {
        this.nextEntry();
        return;
      }
    }

    if (entry.condition && !this.conditionPassed()) {
      this.nextEntry();
      return;
    }

    var checkAA = _.isBoolean(entry.AA) || _.isBoolean(entry.inHOP) || _.isBoolean(entry.outsideHOP) || _.isBoolean(entry.chatSlots) || _.isBoolean(entry.queueSlots) || !!entry.targetChatNode;
    if (checkAA) {
      this.checkAgentAvailability();
      return;
    }

    this.chooseNode(target);
  };

  fn.chooseNode = function (node_id) {
    if (typeof node_id === 'string' && !_utils2.default.string.isReferenceString(node_id)) {
      var keyword = node_id;
      switch (keyword) {
        case 'back':
          // node_id = this.Automaton.Data.Storage.data.previousNodeID;
          node_id = this.Automaton.Data.storage.previousNodeID;
          break;
        case 'close':
          return this.Automaton.close();
        case 'start_over':
          node_id = this.Automaton.settings.initialNode;
          break;
        default:
          _utils2.default.throwError('Transition keyword "' + keyword + '" not recognized.');
      }
    }

    this.evaluateAllEntriesPromise.resolve(node_id);
  };

  fn.conditionPassed = function () {
    var _this = this;

    var passed = false;
    var condition = this.entry.condition;

    _utils2.default.tryCatch(function () {
      passed = _this.evaluateCondition(condition);
    }, function (err) {
      _this.onEvalFailure(err);
    });

    return passed;
  };

  fn.evaluateCondition = function (condition) {
    var automaton = this.Automaton.settings;
    var passed = false;
    var condFn = void 0;

    if (typeof condition === 'function') {
      condFn = condition;
    } else if (typeof condition === 'string') {
      if (typeof automaton[condition] === 'function') {
        condFn = automaton[condition];
      } else if (typeof this.Automaton.node[condition] === 'function') {
        condFn = this.Automaton.node[condition];
      } else {
        var that = this; // eslint-disable-line
        var vars = ['api = that.Automaton.api', 'forms = api.forms', '_ = forms._'];

        vars = vars.map(function (declaration) {
          return '\tvar ' + declaration + ';';
        }).join('\n');
        eval('condFn = function(node) {\n' + vars + '\n\treturn ( ' + condition + ' );\n};');
      }
    }

    passed = condFn.call(this.Automaton.node, this.Automaton.node);

    return passed;
  };

  fn.onEvalFailure = function (error) {
    var msg = 'Failed to evaluate transition entry # ' + (this.index + 1) + ' of transition event named "' + this.transitionName + '". Received error: ' + error.message;
    _utils2.default.console(msg, 'error');
  };

  fn.checkAgentAvailability = function () {
    var _this2 = this;

    var entry = this.entry;
    var targetChatNode = entry.targetChatNode || entry.target;
    var selectedNode = this.Automaton.getRecord(targetChatNode);

    var params = _.extend({}, selectedNode, entry);

    this.Automaton.Chat.checkAA(params).then(function (response) {
      var proceed = true;
      if (proceed && _.isBoolean(entry.AA)) {
        proceed = entry.AA === response.AA;
      }
      if (proceed && _.isBoolean(entry.inHOP)) {
        proceed = entry.inHOP === response.inHOP;
      }
      if (proceed && _.isBoolean(entry.outsideHOP)) {
        proceed = entry.outsideHOP === response.outsideHOP;
      }
      if (proceed && _.isBoolean(entry.chatSlots)) {
        var chatSlotsExist = response.status === 'online' && response.AA === true;
        proceed = entry.chatSlots === chatSlotsExist;
      }
      if (proceed && _.isBoolean(entry.queueSlots)) {
        var queueSlotsExist = response.status === 'busy' && response.AA === true;
        proceed = entry.queueSlots === queueSlotsExist;
      }

      if (proceed) {
        _this2.chooseNode(entry.target);
      } else {
        _this2.nextEntry();
      }
    });
  };

  fn.getPromise = function () {

    return this.evaluateAllEntriesPromise;
  };
});