define('acif/models/Node', ['exports', './Model', '../utils'], function (exports, _Model, _utils) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Model2 = _interopRequireDefault(_Model);

  var _utils2 = _interopRequireDefault(_utils);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Node;


  function Node(properties, options) {
    _Model2.default.call(this, properties, options);

    this.loadCustomNodeProperties();
    this.validateActions();
  }

  var proto = new _Model2.default();

  Node.prototype = proto;

  proto.constructor = Node;

  proto._modelType = 'node';

  proto.loadCustomNodeProperties = function () {
    var _this = this;

    var properties = this.Automaton.nodeProperties;

    if (typeof properties !== 'object') {
      return;
    }

    var tags = (this.tags || '').toString().replace(',', ' ').split(/\s+/);

    tags.push(this.type.toLowerCase());

    tags.forEach(function (tag) {
      if (properties[tag]) {
        _.extend(_this, properties[tag]);
      }
    });
  };

  var ACTIONS = ['closeAutomaton', 'console', 'fireAssistedEvent', 'fireCustomEvent', 'growLayer', 'hideLayer', 'loadAutomaton', 'position', 'resize', 'sendFormDataToAgent', 'sendToAgent', 'showLayer', 'shrinkLayer'];

  /**
   * Run custom action set in Node's "actions" property.
   *
   * @example
   * // Node - View
   * <button data-action="sayHiToAgent;exit">Datapass then exit</button>
   *
   * // Node - Custom Data
   * actions: {
   *  sayHiToAgent: {
   *    action: 'sendToAgent',
   *    params: 'Hello!'
   *  }
   * }
   *
   * @param {string} actionName
   * @return {boolean} Indicates whether or not a custom action was executed
   */
  proto.runCustomAction = function (actionName) {
    var actions = this.actions,
        Automaton = this.Automaton;

    var customAction = actions ? actions[actionName] : null;

    if (!isObject(customAction)) return false;

    var action = customAction.action,
        params = customAction.params;


    var apiAction = Automaton.api[action];
    apiAction.call(Automaton, params);

    return true;
  };

  proto.validateActions = function () {
    var actions = this.actions;


    var hasNodeActions = !!actions;
    if (hasNodeActions) {

      Object.keys(actions).forEach(function (customAction) {

        var actionObj = actions[customAction];
        if (!isObject(actionObj)) {
          _utils2.default.throwError('Custom action "' + customAction + '" must be an object.');
        }

        var action = actionObj.action;


        var isValidAction = ACTIONS.indexOf(action) > -1;
        if (!isValidAction) {
          _utils2.default.throwError('Unrecognized action "' + action + '" found in "' + customAction + '"\nSupported actions: ' + ACTIONS);
        }
      });
    }

    return true;
  };

  function isObject(value) {
    return !!(value && typeof value === 'object' && !Array.isArray(value));
  }
});