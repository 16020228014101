define("acif/environment/location", ["exports"], function (exports) {
	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var _location;

	try {
		_location = window.top.location;
	} catch (e) {
		_location = parent.parent.location;
	}

	exports.default = _location;
});