define('acif/environment/index', ['exports', './flashvars', './globals', './get-env-var', './is-dev-mode'], function (exports, _flashvars, _globals, _getEnvVar, _isDevMode) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _flashvars2 = _interopRequireDefault(_flashvars);

  var _globals2 = _interopRequireDefault(_globals);

  var _getEnvVar2 = _interopRequireDefault(_getEnvVar);

  var _isDevMode2 = _interopRequireDefault(_isDevMode);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Environment;


  function Environment(automatonOptions) {
    var globals = (0, _globals2.default)();
    var _top = automatonOptions.top;
    var _win = automatonOptions.frame;
    var _document = _win.document;
    var is_top_frame = _win === _top;
    var isPersistentWindow = !!globals.location.href.match('inqChat.html');
    var previewContainerEl = document.getElementById('acif-preview-container');
    var is_preview_mode = !!previewContainerEl;
    var flashVars = (0, _flashvars2.default)();
    var deviceType = flashVars.deviceType || '';
    var os = flashVars.operatingSystemType || '';
    var env = (0, _getEnvVar2.default)('nuance-env') || 'production';

    this.devMode = (0, _isDevMode2.default)();
    this.win = globals.window;
    this.flashVars = flashVars;
    this.location = globals.location;
    this.is_top_frame = is_top_frame;
    this.top = _top;
    this.document = _document;
    this.isIE7 = navigator.appVersion.indexOf('MSIE 7.') !== -1;
    this.isIE8 = typeof document.addEventListener === 'undefined';
    this.isAndroidMobile = !!(deviceType.match(/phone|tablet/i) && os.match(/android/i));
    this.isIOS = !!os.match(/ios/i);
    this.isIOS9 = !!navigator.userAgent.match(/iPhone OS 9/i);
    this.isFF = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    this.is_touch_enabled = 'ontouchstart' in window;
    this.is_preview_mode = is_preview_mode;
    this.previewContainerEl = previewContainerEl;
    this.isPersistentWindow = isPersistentWindow;

    this.dbSync = this.getDbSync();
    this.dbSyncClient = this.getDbSyncClient();
    this.showEvents = this.get('nuance-show-events', { persist: true });
    this.environment = env;
    this.isTest = !!env.match(/^test|agvsptest01/i);
    this.isDev = !!env.match(/^dev/i);
    this.isProd = !!env.match(/^prod/i);
  }

  var fn = Environment.prototype = { constructor: Environment };

  fn.get = _getEnvVar2.default;

  fn.getDbSyncClient = function () {
    return (0, _getEnvVar2.default)('tc-client-id');
  };

  fn.getDbSync = function () {
    return (0, _getEnvVar2.default)('tc-db-sync', { persist: true });
  };
});