define('acif/data/storage-mixin', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getStorageMaker;


  var fn = {};

  function getStorageMaker(Data) {
    var automatonHash = Data.Automaton.hash;
    var LocalStorage = Data.Storage;

    function Storage(name) {
      var storageKey = automatonHash + '::' + name;

      this.storageKey = storageKey;
      this.LocalStorage = LocalStorage;
      this.data = LocalStorage.get(storageKey);

      if (!this.data) {
        var data = {};
        LocalStorage.save(this.storageKey, data);
        this.data = data;
      }
    }

    Storage.prototype = fn;

    Storage.create = function (instance, name) {
      var storage = new Storage(name);
      _.each(fn, function (method, methodName) {
        instance[methodName] = method.bind(storage);
      });

      return storage.getAll();
    };

    return Storage;
  }

  fn.save = function (path, value) {
    var _this = this;

    if (typeof path === 'object') {
      _.each(path, function (value, key) {
        _this.save(key, value);
      });
      return;
    }

    path = this.storageKey + '.' + path;
    this.LocalStorage.save(path, value);
  };

  fn.get = function (path, defaultValue) {
    path = this.storageKey + '.' + path;
    var val = this.LocalStorage.get(path);

    if (val === undefined && defaultValue !== undefined) {
      this.save(path, defaultValue);
      val = defaultValue;
    }

    return val;
  };

  fn.getAll = function () {
    return this.data;
  };

  fn.emptyStorage = function () {
    this.data = {};
    this.LocalStorage.save(this.storageKey, this.data);
  };
});