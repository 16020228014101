define('acif/view/forms', ['exports', '../utils', '../data/evaluator'], function (exports, _utils, _evaluator) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _evaluator2 = _interopRequireDefault(_evaluator);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Forms;


  function Forms(Automaton) {
    this.Automaton = Automaton;
    this.View = Automaton.View;
    this.errors = {};
    this.serializedValuesArray = null;
    this.NS = this.View.NS;
    this.onChange = _.throttle(this.onChange.bind(this));
  }

  var fn = Forms.prototype = { constructor: Forms };

  ///////////////////////////////////////////////////////////////////////
  // Allows for a form to only contain one field with a group of       //
  // radio buttons which, when clicked, cause the form to be submitted //
  ///////////////////////////////////////////////////////////////////////
  fn.submitOnClick = function (label) {
    var $label = $(label);
    $label.find('input').prop('checked', true);
    var form = $label.closest('form')[0];
    this.submitForm(form);
  };

  fn.submitForm = function (form) {
    var _this = this;

    var Transitions = this.Automaton.Transitions;
    var $form = $(form);
    var nextNode = $form.attr('acif-next-node');
    var nextTransition = $form.attr('acif-next-transition');
    var NS = this.NS;

    if (!nextNode && !nextTransition) {
      _utils2.default.throwError('Form element is missing one of the following required attributes: "acif-next-node" or "acif-next-transition".  See Confluence documentation.');
    }

    this.errors = {};
    this.serializedValuesArray = $form.serializeArray();
    this.validateAllFields($form);

    var errorNames = Object.keys(this.errors);
    var formHasErrors = !!errorNames.length;

    if (formHasErrors) {
      // Place focus on the first invalid form field and scroll to fieldset
      var $invalids = this.View.$viewport.find('.' + NS + 'invalid :input, .' + NS + 'invalid:input');
      var $firstInvalid = $invalids.first();
      $firstInvalid.focus();

      this.View.$viewContainer.scrollTop(0);
      var fieldSetPosition = $firstInvalid.closest('fieldset').position();

      if (fieldSetPosition) {
        this.View.$viewContainer.scrollTop(fieldSetPosition.top);
      }

      return _utils2.default.console('Form had errors in these fields: ' + errorNames.join(', '));
    }

    _utils2.default.promisify(this.Automaton.node.runHook('onFormSubmit')).then(function (response) {
      _this.Automaton.Data.checkAlertConditions();

      if (nextTransition) {
        nextNode = Transitions.evaluate(nextTransition);
      }

      if (_utils2.default.isPromise(nextNode)) {
        nextNode.then(function (node) {
          Transitions.selectNextNode(node);
        });
      } else {
        Transitions.selectNextNode(nextNode);
      }
    })
    // the "onFormSubmit" hook should throw to cancel form processing, so we ignore errors.
    .catch(function () {});
  };

  fn.validateAllFields = function ($form) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var self = this;
    $form.find(':input:data(acif-original-input)').not(function () {
      return !!$(this).closest('[acif-field-enabled=no]').length;
    }).not(':data(acif-skip-validation)').not('button, input[type=submit]').not('[type=radio]:not(:checked)').each(function () {
      self.validateField(this, options);
    });

    // Validate radio fieldsets with no selected values
    $form.find('fieldset').not(function () {
      return !!$(this).closest('[acif-field-enabled=no]').length;
    }).has('[type=radio]:data(acif-original-input)').not(':has(:checked)').each(function () {
      self.validateField(this, options);
    });
  };

  fn.validateField = function (el) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var $field = $(el);
    var $form = $field.closest('form');

    var inputGroupName = $field.attr('acif-input-group');
    if (inputGroupName) {
      this.validateInputGroup(inputGroupName, $form, options);
    } else {
      this.validateIndividualField($field, options);
    }
  };

  fn.validateIndividualField = function ($field, options) {
    var error = options.existingError || '';
    var attrs = this.getAttributes($field);
    var isTextField = $field.is(_utils2.default.isTextbox);
    var $form = $field.closest('form');

    var isGroupField = !!$field.attr('acif-input-group');
    var isFieldEmpty = !($field.val() && $field.val().trim().length);

    var skipValidation = isGroupField && isFieldEmpty || error;

    if (!skipValidation) {

      if (isTextField) {
        error = this.validateTextField($field, attrs);
      } else if ($field.is('select')) {
        error = this.validateSelect($field, attrs);
      } else if ($field.has('input[type=radio], input[type=checkbox]')) {

        error = this.validateCheckbox(attrs);

        $field = $field.closest('fieldset').add(this.View.$viewport.find('form fieldset[acif-field-name="' + name + '"]')).first();
      }
    }

    var $fieldset = $field.closest('fieldset');

    if (!options.validateFieldsSilently) {
      this.clearFieldErrors($fieldset, attrs);
    }

    if (error) {
      if (!options.validateFieldsSilently) {
        this.markFieldInvalid($form, $fieldset, attrs, error);
        this.markFormInvalid($form);
      }

      this.disableAcifToggleDisabledSubmit($form);
    } else {
      if (!options.validateFieldsSilently) {
        this.markFieldAsValid($form, $fieldset, attrs);
      }
    }

    this.Automaton.Data.CRM.addResponse(attrs.response);
  };

  fn.validateInputGroup = function (inputGroupName, $form) {
    var _this2 = this;

    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var isGroupContainingErrors = false;

    var $groupFields = $form.find('[acif-input-group="' + inputGroupName + '"]');
    var $groupConstraints = $form.find('[acif-input-group-constraint="' + inputGroupName + '"]');

    $groupConstraints.each(function (index, element) {
      var $groupConstraint = $(element);
      var max = parseInt($groupConstraint.attr('max'));
      var min = parseInt($groupConstraint.attr('min'));

      var $groupInputsWithValues = $groupFields.filter(function (index, input) {
        return $(input).val().trim().length > 0;
      });

      var isTooFew = !isNaN(min) && $groupInputsWithValues.length < min;
      var isTooMany = !isNaN(max) && $groupInputsWithValues.length > max;

      var isGroupInvalid = isTooFew || isTooMany;

      if (isGroupInvalid) {
        isGroupContainingErrors = true;
        $groupConstraint.removeAttr('hidden');
      } else {
        $groupConstraint.attr('hidden', 'true');
      }
    });

    $groupFields.each(function (index, element) {
      var error = isGroupContainingErrors ? 'group' : '';
      options.existingError = error;
      _this2.validateIndividualField($(element), options);
    });
  };

  fn.validateTextField = function ($field, attrs) {
    if (attrs.required && attrs.value === '') {
      return 'required';
    } else if (attrs.type === 'email' && !this.isValidEmail(attrs.value)) {
      return 'pattern';
    } else if (attrs.pattern && !this.isValidPattern(attrs)) {
      return 'pattern';
    } else if (attrs.min && attrs.value * 1 < attrs.min) {
      return 'min';
    } else if (attrs.max && attrs.value * 1 > attrs.max) {
      return 'max';
    } else if (attrs.minlength && attrs.value.length < attrs.minlength) {
      return 'minlength';
    } else if (attrs.maxlength && attrs.value.length > attrs.maxlength) {
      return 'maxlength';
    }
  };

  fn.validateSelect = function ($field, attrs) {
    // @todo: add back support for select menus allowing multiple selections
    var values = $field.parent().values();
    values = values[attrs.name][0];
    if (attrs.required && !values) {
      return 'required';
    } else if (attrs.min && values.length < attrs.min) {
      return 'min';
    } else if (attrs.max && values.length > attrs.max) {
      return 'max';
    }
  };

  fn.validateCheckbox = function (attrs) {
    var values = attrs.value === null ? [] : [].concat(attrs.value);

    if (attrs.required && values.length === 0) {
      return 'required';
    } else if (attrs.min && values.length < attrs.min) {
      return 'min';
    } else if (attrs.max && values.length > attrs.max) {
      return 'max';
    }
  };

  fn.markFieldInvalid = function ($form, $fieldset, attrs, error) {
    var _View = this.View,
        NS = _View.NS,
        $viewContainer = _View.$viewContainer;

    var errorClass = _.dasherize('invalid-' + attrs.name + '-' + error);

    this.errors[attrs.name] = true;

    $viewContainer.addClass(NS + errorClass);
    $fieldset.removeClass(NS + 'valid').addClass(NS + 'invalid').addClass(NS + 'invalid-' + error);

    var $invalid = void 0;

    if (error === 'group') {
      $invalid = $form.find('[acif-input-group-constraint="' + attrs.inputGroupName + '"]:visible');
    } else {
      $invalid = $fieldset.find('[acif-invalid="' + error + '"]');
    }

    $invalid.removeAttr('hidden');

    if (this.View.isAriaEnabled) {
      this.View.Aria.makeInvalid($fieldset, $invalid);
    }
  };

  fn.clearFieldErrors = function ($fieldset, attrs) {
    var NS = this.NS,
        View = this.View;

    var $invalids = $fieldset.find('[acif-invalid]');

    View.$viewContainer.removeClassPrefix(NS + 'invalid-' + _.dasherize(attrs.name) + '-');

    $fieldset.removeClassPrefix(NS + 'invalid-');

    $invalids.attr('hidden', 'true');

    if (View.isAriaEnabled) {
      View.Aria.resetErrorMessages($fieldset);
    }
  };

  fn.markFieldAsValid = function ($form, $fieldset, attrs) {
    var NS = this.NS;
    var fieldName = attrs.name;

    delete this.errors[fieldName];

    $fieldset.removeClass(NS + 'invalid').addClass(NS + 'valid');

    this.addValidationClassesToForm($form);
  };

  fn.addValidationClassesToForm = function ($form) {
    if (!_.isEmpty(this.errors)) {
      this.markFormInvalid($form);
      this.disableAcifToggleDisabledSubmit($form);
    } else if (this.allVisibleRequiredFieldsHaveValue()) {
      this.markFormAsValid($form);
    }
  };

  fn.markFormInvalid = function ($form) {
    var NS = this.NS;

    this.View.$viewContainer.addClass(NS + 'invalid-form');

    $form.removeClass(NS + 'form-pristine').removeClass(NS + 'form-dirty').removeClass(NS + 'valid-form').addClass(NS + 'form-invalid');
  };

  fn.disableAcifToggleDisabledSubmit = function ($form) {
    $form.find('[acif-toggle-disabled-submit]').attr('disabled', true);
  };

  fn.markFormAsValid = function ($form) {
    var NS = this.NS;
    var $disabledSubmit = $form.find('[acif-toggle-disabled-submit][disabled]');

    this.View.$viewContainer.removeClass(NS + 'invalid-form');

    $disabledSubmit.removeAttr('disabled');
    $form.removeClass(NS + 'form-pristine').removeClass(NS + 'form-dirty').removeClass(NS + 'form-invalid').addClass(NS + 'valid-form');
  };

  fn.allVisibleRequiredFieldsHaveValue = function () {
    var $viewContainer = this.View.$viewContainer;

    // exclude all inputs hidden by toggleFields & trottleFields method
    var $requiredFields = $viewContainer.find(':input').not(function () {
      return !!$(this).closest('[acif-field-enabled=no]').length;
    }).filter('[required], [aria-required]');

    var isValid = true;

    $requiredFields.each(function () {
      var hasValue = void 0;
      if ($(this).is('select')) {
        if ($(this).find(':selected').length > 0) {
          hasValue = !!$(this).find(':selected').val().trim();
        } else {
          hasValue = false;
        }
      } else if ($(this).is('[type=radio]')) {
        var _name = $(this).prop('name');
        hasValue = $viewContainer.find('[name="' + _name + '"]:checked').val();
      } else {
        hasValue = !!$(this).val().trim();
      }

      if (!hasValue) {
        isValid = false;

        // Return false to stop the jQuery.fn.each loop
        return false;
      }
    });

    return isValid;
  };

  fn.getSerializedValuesArray = function () {
    if (!this.serializedValuesArray) {
      this.serializedValuesArray = this.Automaton.View.$viewport.find('form').serializeArray();
    }

    return this.serializedValuesArray;
  };

  fn.toggleFields = function ($dependants) {
    var self = this;
    var $form = this.View.$viewContainer.find('form');
    var toggledContent = false;

    $dependants = $dependants || $('[acif-base-field-name]', $form);
    if (!$dependants.length) {
      $dependants = $('[acif-conditional]', $form);
    }

    $dependants.each(function () {
      var $currentDependant = $(this);
      var shouldShowElement = void 0;
      var hasComplexConditional = $currentDependant.is('[acif-conditional]');
      if (hasComplexConditional) {
        var _name2 = $currentDependant.attr('acif-field-name');
        var fieldObject = self.Automaton.node.fieldsList[_name2];
        var responseList = self.Automaton.Data.CRM.getResponseList();
        shouldShowElement = new _evaluator2.default(fieldObject.conditions, responseList).isSatisfied();
      } else {
        shouldShowElement = self.shouldShowElement($currentDependant, $form);
      }

      var notInDom = !self.View.$viewport.find($currentDependant).length;

      if (shouldShowElement) {
        if (notInDom || $currentDependant.is(':hidden')) {
          toggledContent = true;
          $currentDependant.show().attr('acif-field-enabled', 'yes');
        }
      } else {
        if (!notInDom) {
          self.resetField(this);
        }
        toggledContent = true;
        $currentDependant.hide().attr('acif-field-enabled', 'no');
      }
    });

    this.validateAllFields($form, { validateFieldsSilently: true });

    return toggledContent;
  };

  fn.shouldShowElement = function ($currentElement, $form) {
    var show = false;
    var name = $currentElement.attr('acif-base-field-name');
    var valueEquals = $.trim($currentElement.attr('acif-base-field-value') || '');
    var valueAny = $.trim($currentElement.attr('acif-base-field-value-any') || '');
    var valueAll = $.trim($currentElement.attr('acif-base-field-value-all') || '');
    var valueContains = $.trim($currentElement.attr('acif-base-field-value-contains') || '');
    var valueNot = $.trim($currentElement.attr('acif-base-field-value-not') || '');
    var $baseField = $form.find('[acif-field-name="' + name + '"]');
    var baseFieldValues = $baseField.values();

    // fieldValue is an array of 1 or more values, which is necessary
    // to support all the comparison functions
    var fieldValue = baseFieldValues[name];
    var splitRegex = /\s*;\s*/g;

    if (valueEquals) {
      // jshint eqeqeq:false
      show = fieldValue == valueEquals;
      // jshint eqeqeq:true
    } else if (valueAny) {
      valueAny = valueAny.split(splitRegex);
      show = !!_.intersection(fieldValue, valueAny).length;
    } else if (valueAll) {
      valueAll = valueAll.split(splitRegex);
      show = _.intersection(fieldValue, valueAll).length === valueAll.length;
    } else if (valueContains) {
      // convert to string for contains fn
      if (fieldValue && fieldValue.length === 1) {
        fieldValue = fieldValue[0];
      }
      show = _.contains(fieldValue, valueContains);
    } else if (valueNot) {
      valueNot = valueNot.split(splitRegex);
      show = !_.intersection(valueNot, fieldValue).length;
    }

    return show;
  };

  fn.throttleElements = function () {
    var $form = this.View.$viewContainer.find('form');
    var $elementsToThrottle = $('[acif-base-exposure-rate]', $form);

    $elementsToThrottle.each(function () {
      var $currentElement = $(this);
      var shouldExpose = false;
      var exposureRate = $currentElement.attr('acif-base-exposure-rate') || '';
      var randExposureRate = _.random(99) + 1; // 1 to 100

      if (exposureRate) {
        shouldExpose = randExposureRate <= exposureRate;
      }

      if (!shouldExpose) {
        $currentElement.hide().attr('acif-field-enabled', 'no');
        // prevents this elem from being shown dynamically
        $currentElement.removeAttr('acif-base-field-name');
      }
    });
  };

  fn.markOriginalInputs = function () {
    this.View.$viewport.find(':input').data('acif-original-input', true);
  };

  fn.resetField = function (fieldset) {
    var self = this;
    var $fieldset = $(fieldset);
    var $nestedFieldsets = $fieldset.find('fieldset');

    if ($nestedFieldsets.length) {
      return $nestedFieldsets.each(function () {
        self.resetField(this);
      });
    }

    var fieldName = $fieldset.attr('acif-field-name') || $fieldset.find(':input').first().attr('name');
    var $inputs = $fieldset.find(':input');
    var $firstInput = $inputs.first();
    var type = $fieldset.attr('acif-field-type') || $firstInput.attr('type') || '';
    var NS = this.NS;

    if (type.match(/radio|checkbox/)) {
      $inputs.prop('checked', false);
    } else if ($firstInput.is('select')) {
      $firstInput.prop('selectedIndex', 0);
    } else {
      $inputs.val('');
    }

    $fieldset.removeClass(NS + 'valid').removeClass(NS + 'invalid');

    $('[aria-invalid]', $fieldset).removeAttr('aria-invalid');
    $('[acif-invalid]', $fieldset).attr('aria-hidden', true).attr('hidden', true);

    if ($fieldset.is('[acif-base-field-name]')) {
      $fieldset.hide().attr('acif-field-enabled', 'no');
    }

    this.Automaton.Data.CRM.clearResponse(fieldName);

    // Also reset any fields that depend on the current field
    $('[acif-base-field-name="' + fieldName + '"]', this.Automaton.View.$viewContainer).each(function () {
      var $this = $(this);
      if ($this.is('fieldset')) {
        self.resetField($this);
      } else {
        $this.find('fieldset').each(function () {
          self.resetField(this);
        });
      }
    });
  };

  fn.onFocus = function (el) {
    var NS = this.NS;
    var $el = $(el);
    var $fieldset = $el.closest('fieldset');

    this.Automaton.View.$lastFocusedElement = $el;

    $fieldset.addClass(NS + 'focus').removeClass(NS + 'pristine').removeClass(NS + 'filled').removeClass(NS + 'empty').removeClass(NS + 'blurred');

    $el.closest('form').removeClass(NS + 'form-pristine').removeClass(NS + 'valid-form').addClass(NS + 'form-dirty');
  };

  fn.onBlur = function (el) {
    var NS = this.NS;
    var $fieldset = $(el).closest('fieldset');
    var inputHasValue = el.value !== '';
    var filledOrEmpty = inputHasValue ? 'filled' : 'empty';

    $fieldset.removeClass(NS + 'focus').addClass(NS + 'blurred').addClass(NS + filledOrEmpty);
  };

  fn.onChange = function (el) {
    // Null out the serializedValuesArray so it will be recalculated
    // before any validation processes occur.
    this.serializedValuesArray = null;

    this.validateField(el);

    var $el = $(el);
    var name = $el.attr('name');
    var $scrollParent = $el.scrollParent();
    var oldScrollTop = $scrollParent.scrollTop();
    var $dependants = this.Automaton.View.$viewport.find('form [acif-base-field-name="' + name + '"]');
    var $scrollField = $el.closest('[acif-scroll-on-change]');
    var scrollFieldOffset = $scrollField.offset();
    if (!$dependants.length) {
      $dependants = this.Automaton.View.$viewport.find('form [acif-conditional]');
    }
    var toggledContent = this.toggleFields($dependants);

    // Revert to original scroll height in case toggling a field
    // may have changed the scroll position
    $scrollParent.scrollTop(oldScrollTop);

    if (toggledContent && $scrollField.length) {
      this.scrollToTop($scrollField, scrollFieldOffset);
    }
  };

  fn.storeCurrentValue = function (input) {
    var $input = $(input);
    $input.attr('acif-original-value', $input.val());
  };

  fn.fireOnChangeManually = function (input) {
    var _this3 = this;

    var $input = $(input);
    setTimeout(function () {
      var val = $input.val();
      var valueDidChange = val !== $input.attr('acif-original-value');
      if (valueDidChange) {
        _this3.onChange(input);
      }
    }, 0);
  };

  fn.toggleCheckbox = function (input) {
    // due to iOS not triggering focus event on checkbox we must trigger onFocus manually
    if (this.View.Env.isIOS) {
      this.onFocus(input);
    }

    var $input = $(input);

    if ($input.is(':checked')) {
      $input.attr('checked', 'checked');
    } else {
      $input.removeAttr('checked');
    }
  };

  fn.scrollToTop = function ($field, oldFieldOffset) {
    var $scroller = $field.scrollParent(),
        scrollTop = $scroller.scrollTop(),
        scrollerHeight = $scroller.height(),
        fieldTop = $field.offset().top,
        fieldDistanceToBottom = scrollerHeight - (fieldTop + $field.height()),
        scrollerScrollHeight = $scroller[0].scrollHeight,
        hiddenScrollHeight = scrollerScrollHeight - scrollerHeight,
        maxScroll = scrollerScrollHeight - (scrollerHeight + scrollTop),
        scrollTo = Math.min(maxScroll, hiddenScrollHeight, fieldTop);

    if (scrollTo > 100 || fieldDistanceToBottom < 100) {
      if (fieldTop - scrollTo < oldFieldOffset.top) {
        $scroller.animate({ scrollTop: scrollTop += scrollTo }, 500);
      } else {
        $scroller.scrollTop(scrollTop + scrollTo);
      }
    }
  };

  fn.clearLastFocusedElement = function () {
    this.Automaton.View.$lastFocusedElement = null;
  };

  fn.isValidEmail = function (email) {
    return 'string' !== typeof email ? false : /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  };

  fn.isValidPattern = function (attr) {
    var matchLiterals = /^\/([\w\W]*)\/([gimuy]*)$/.exec(attr.pattern) || [];
    var pattern = matchLiterals[1] || attr.pattern;
    var flags = matchLiterals[2] || 'gim';
    var regex = new RegExp('^' + pattern + '$', flags);

    return !!attr.value.match(regex);
  };

  fn.getAttributes = function (el) {
    var $el = $(el);
    var isValidArgument = $el.is(':input') || $el.is('fieldset');

    if (!isValidArgument) {
      _utils2.default.throwError('Forms.getAttributes() expected an input or fieldset to be passed.');
    }

    var answerTypes = this.Automaton.Data.CRM.answerTypes;
    var $fieldset = $el.closest('fieldset');
    var $form = $fieldset.closest('form');
    var $inputs = $fieldset.find(':input');
    var $input = $el.is(':input') ? $el : $inputs.first();
    var type = $input.attr('type') || $fieldset.attr('acif-field-type') || $input[0].tagName.toLowerCase();
    var isRadioCheckbox = !!type.match(/^(radio|checkbox)$/);
    var name = $input.attr('name') || $fieldset.attr('acif-field-name');
    var fieldsList = this.Automaton.node.fieldsList || {};
    var fieldObj = fieldsList[name] || {};
    var $legend = $fieldset.find('legend, label').first();
    var questionText = fieldObj.label || $legend.data('original-text') || '';
    questionText = questionText.replace(/\*+$/, '');

    var questionNumber = $form.find('fieldset').index($fieldset) + 1;
    var answerNumber = $fieldset.find(':input').index($input) + 1;
    var required = $input.is('[required], [aria-required]') || $fieldset.is('[required], [aria-required], [acif-required]');

    var isFieldDisabled = $fieldset.attr('acif-field-enabled') === 'no';

    var multiple = $input.is('[multiple]') || $fieldset.is('[multiple]');
    var isMultiple = type === 'checkbox' || multiple;
    var isFreeForm = !type.match(/^(radio|checkbox|select|button)$/);

    var isMasked = $input.is('[acif-masked]') || $fieldset.is('[acif-masked]');
    var value = isRadioCheckbox ? $input.is(':checked') ? $input.val() : null : $.trim($input.val() || '');
    var answerText = value === null ? null : isRadioCheckbox ? $input.closest('label').data('original-text') || $input.closest('label').text().trim() : value;

    if (type === 'select') {
      var $options = $input.find('option');
      var $selectedOption = $options.filter('[value="' + value + '"]');
      answerNumber = $options.index($selectedOption);
    } else if (isMultiple) {
      var serializedValuesArray = this.getSerializedValuesArray();
      var values = _.where(serializedValuesArray, { name: name });

      value = _.pluck(values, 'value');
      answerText = value;

      // Get the answer numbers for questions that allow multiple
      // answers. The answer numbers will be used later to set
      // survey alert conditions in the business rules.
      var answerArray = [];

      $inputs.each(function () {
        var answerText = $(this).closest('label').text().trim();
        answerArray.push(answerText);
      });

      answerNumber = answerText.map(function (text) {
        return answerArray.indexOf(text.trim()) + 1;
      }).sort();
    }

    var attr = {
      type: type,
      name: name,
      value: value,
      multiple: multiple,
      required: required,
      min: ($input.attr('min') || $fieldset.attr('min')) * 1,
      max: ($input.attr('max') || $fieldset.attr('max')) * 1,
      inputGroupName: $input.attr('acif-input-group'),
      minlength: $input.attr('minlength') * 1,
      maxlength: $input.attr('maxlength') * 1,
      pattern: $input.attr('pattern')
    };

    if (isMasked) {
      answerText = '((masked{[' + answerText + ']}masked))';
      value = '((masked{[' + value + ']}masked))';
    }

    var response = {
      questionText: questionText,
      questionId: name,
      // For questions that allow multiple answers,
      // arrays are used for answerText, answerId, and answerNumber
      answerText: answerText,
      answerId: isFreeForm ? '' : value,
      answerType: isFreeForm ? answerTypes.FREE_FORM : answerTypes.NON_FREE_FORM,
      answerValue: value,
      questionNumber: questionNumber,
      answerNumber: answerNumber,
      isFieldDisabled: isFieldDisabled,
      isMasked: isMasked
    };

    attr.response = response;

    return attr;
  };
});