define('acif/view/view', ['exports', '../utils', '../ci/ijsf-adapter', '../models/Style', './aria', './exit', './FileUpload', './position', './NodeView', '../ci/index'], function (exports, _utils, _ijsfAdapter, _Style, _aria, _exit, _FileUpload, _position, _NodeView, _index) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  var _Style2 = _interopRequireDefault(_Style);

  var _aria2 = _interopRequireDefault(_aria);

  var _exit2 = _interopRequireDefault(_exit);

  var _FileUpload2 = _interopRequireDefault(_FileUpload);

  var _position2 = _interopRequireDefault(_position);

  var _NodeView2 = _interopRequireDefault(_NodeView);

  var _index2 = _interopRequireDefault(_index);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  var tooltipClassName = '-ui-tooltip';

  exports.default = View;


  function View(Automaton) {
    this.Automaton = Automaton;
    this.Env = Automaton.Env;
    this.NS = Automaton.namespace;

    this.Aria = new _aria2.default(this);
    this.Exit = new _exit2.default(this);
    this.position = new _position2.default(this);
  }

  var fn = View.prototype = { constructor: View };

  fn.initializeViewport = function () {
    var Automaton = this.Automaton;
    var settings = this.Automaton.settings;

    _utils2.default.benchmark('init').mark('initializeViewport');

    this.setFrames();

    this.disableBackAndForwardCaching();

    if (this.Env.is_touch_enabled) {
      this.makeButtonsTapResponsive();
    }

    this.loadViewport();

    this.Exit.registerExitListener();

    this.isAriaEnabled = !!(settings.ariaEnabled && !this.Env.isIE7);

    if (this.isAriaEnabled) {
      this.Aria.init(this);
    }

    this.viewportSettings = Automaton.settings.viewport;

    _utils2.default.benchmark('init').mark('starting to load CSS');

    if (settings.style) {
      this.loadStyles();
    }

    _utils2.default.benchmark('init').mark('finished loading CSS');

    if (settings.escToClose) {
      this.enableCloseOnEscape();
    }

    if (settings.lightbox) {
      this.createLightbox();
    }

    this.position.fit();
  };

  fn.saveOriginalQuestionText = function () {
    this.$viewport.find('legend,label,h1,h2,h3,[acif-node]').each(function (index, element) {
      var $element = $(element);
      var questionText = $element.text().trim();
      $element.data('original-text', questionText);
    });
  };

  fn.registerEventListeners = function () {
    var _this = this;

    var self = this;
    var Automaton = this.Automaton;
    var hash = Automaton.hash,
        Forms = Automaton.Forms,
        Transitions = Automaton.Transitions;


    // Note: We are intentionally not using event delegation any longer
    // because on certain websites, the base page intercepts the events
    // before our listeners get a chance to react.

    function selectElThen(fn) {
      return function (evt) {
        _utils2.default.benchmark('btn click').start();
        self.setActiveEvent(evt, this);
        fn.call(this);
        return false;
      };
    }

    var v = this.$viewport;

    // Bind node inputs to CI Framework
    var $inputs = $('input, textarea, select', v);
    _ijsfAdapter2.default.updateInputListeners(hash, $inputs);

    // Node transition event handlers
    $('[acif-node]', v).on('click', selectElThen(function () {
      var node_id = $(this).attr('acif-node');
      Transitions.selectNextNode(node_id);
    }));

    $('[acif-transition]', v).on('click', selectElThen(function () {
      var transitionEventName = $(this).attr('acif-transition');
      Transitions.trigger(transitionEventName);
    }));

    // We currently will not log a question_answer response when
    // users click on a acif-action element
    $('[acif-action]', v).on('click', selectElThen(function () {
      self.selectAction(this);
    }));

    // File Upload
    $('input[type="file"][acif-upload-to-agent]', v).on('change', function (event) {
      var value = this.value || '';
      if (value.length) {
        new _FileUpload2.default(event, Automaton);
      }
    });

    // Forms event handlers
    $('form', v).on('submit', function (e) {
      e.preventDefault();
      self.setActiveEvent(e, self.Env.document.activeElement);
      Forms.submitForm(this);
      return false;
    });

    var $submitOnClickButtons = $('form[acif-submit-on-click] label:has(input)', v);
    $submitOnClickButtons.on('click', selectElThen(function () {
      Forms.submitOnClick(this);
      return false;
    }));

    $('form[acif-validate-onkeyup] :input', v).on('keyup', function () {
      Forms.validateField(this);
    });

    $('form :input', v).on('focus', function () {
      Forms.onFocus(this);
    });

    $('form :input', v).on('blur', function () {
      Forms.onBlur(this);
    });

    $('form :input', v).on('change', function () {
      Forms.onChange(this);
    });

    $('form :input[acif-auto-format=currency]', v).each(function (index, element) {
      var $input = $(element);

      _this.hideAttributes($input, ['acif-auto-format', 'acif-thousands-separator', 'acif-currency-symbol', 'acif-decimal-separator']);

      $input.on('input', _this.autoFormatCurrencyField);
    });

    $('form select', v).on('focus', function () {
      Forms.storeCurrentValue(this);
    });

    $('form select', v).on('keydown', function () {
      Forms.fireOnChangeManually(this);
    });

    $('form :input[type=checkbox], form :input[type=radio]', v).on('change', function () {
      Forms.toggleCheckbox(this);
    });

    // Due to a Google TalkBack quirk, the element to receive
    // focus should not already have focus
    if (this.isAriaEnabled && this.Env.isAndroidMobile) {
      $('select', v).on('change', function () {
        self.Aria.returnFocusToDropdown(this);
      });
    }
  };

  fn.initializeNode = function () {
    var _this2 = this;

    var Automaton = this.Automaton;

    _utils2.default.benchmark('btn click').mark('renderNode start');

    var nodeView = new _NodeView2.default(Automaton.node, Automaton);

    var $html = nodeView.$container;

    if (this.isAriaEnabled) {
      this.Aria.enableAriaHtml($html);
      this.Aria.saveInitialDescribedby($html);
    }

    this.setCircularTabbing($html);

    var isOnInitialNode = Automaton.node.id === Automaton.displayedPath[0];

    if (isOnInitialNode) {
      nodeView.hideBackButtons();
    }

    nodeView.addNamespace(this.NS);

    nodeView.addIdsToDivTags();

    var renderNode = function renderNode() {
      _this2.renderNode($html, nodeView);
    };

    var hasAnimation = Automaton.node.animation || Automaton.settings.animation;

    if (this.$viewContainer && hasAnimation) {
      var animationSettings = _.defaults({}, Automaton.node.animation, Automaton.settings.animation);

      this.runAnimation(this.$viewContainer, $html, animationSettings).then(renderNode).catch(function (error) {
        ACIF.Utils.logger(error);
      });

      return;
    }

    if (this.$viewContainer) {
      this.$viewContainer.remove();
    }

    renderNode();
  };

  fn.renderNode = function ($html, nodeView) {
    var Automaton = this.Automaton;

    this.$viewContainer = $html;

    // Apply acif-is-base-field attribute to all base field form fields.
    // Used to ensure dynamic content is correctly focused after displayed.
    $('form [acif-base-field-name]', $html).each(function () {
      var name = $(this).attr('acif-base-field-name');
      $('form fieldset[acif-field-name="' + name + '"]', $html).attr('acif-is-base-field', true);
    });

    // Throttle elements according to their exposure rate
    Automaton.Forms.throttleElements();

    $html.prependTo(this.$viewportOutlet || this.$viewport);

    // Due to the poor reliability of validating forms on submission, we mark original inputs to validate on submit
    Automaton.Forms.markOriginalInputs();

    // Toggle any form fields that should only be visible based on other field values
    Automaton.Forms.toggleFields();

    nodeView.initializeAccordionMenus();

    nodeView.initializeTooltips();
    nodeView.initializeDatepickers();

    this.saveOriginalQuestionText();

    this.registerEventListeners();

    if (this.isAriaEnabled) {
      this.Aria.placeInitialFocusOnView();
    }

    // Resize panel height based on the node panel settings
    var panelSettings = _utils2.default.get(Automaton, 'node.panel') || _utils2.default.get(Automaton, 'settings.panel') || {};
    var panelId = _utils2.default.get(Automaton, 'options.panel');
    var height = panelSettings.height,
        width = panelSettings.width;

    var shouldResizePanelWidth = !!(!_index2.default.isHorizontalPanel(panelId) && width);
    var shouldResizePanelHeight = !!(!_index2.default.isVerticalPanel(panelId) && height);

    if (shouldResizePanelWidth && shouldResizePanelHeight) {
      _ijsfAdapter2.default.setElementPosition(panelId, { width: width, height: height });
    } else {
      shouldResizePanelWidth ? _ijsfAdapter2.default.setElementPosition(panelId, { width: width }) : null;
      shouldResizePanelHeight ? _ijsfAdapter2.default.setElementPosition(panelId, { height: height }) : null;
    }

    var viewportSettings = Automaton.node.viewport || {};
    if (Automaton.isSessionInitializing) {
      var automatonViewportSettings = Automaton.settings.viewport || {};
      viewportSettings.width = viewportSettings.width || automatonViewportSettings.width;
      viewportSettings.height = viewportSettings.height || automatonViewportSettings.height;
      viewportSettings.position = viewportSettings.position || automatonViewportSettings.position;
    }

    if (viewportSettings.width || viewportSettings.height) {
      _index2.default.resizeStage({
        width: viewportSettings.width,
        height: viewportSettings.height
      });
    }

    // Do not resize the CI height if a node declared the viewport's height property
    if (Automaton.settings.autoResizeHeight && !viewportSettings.height) {
      this.autoResizeHeight();
    }

    if (viewportSettings.position) {
      this.position.set(viewportSettings.position);
    }

    $(Automaton).trigger('didRenderNode', Automaton.node.id);

    _utils2.default.benchmark('btn click').mark('renderNode end').end();

    if (top.loadAcifEvent) {
      top.loadAcifEvent = null;
    }

    // Prevent event propagation
    return false;
  };

  fn.autoResizeHeight = function () {
    this.$viewport.css({
      top: '',
      left: '',
      right: '',
      bottom: '',
      height: ''
    });

    var skinHeight = this.$skin.height();
    var xframe = this.$xframe.height();
    var viewHeight = this.$viewContainer.outerHeight(true);
    var minHeight = this.Automaton.settings.minHeight || 0;
    var newHeight = skinHeight - xframe + viewHeight - minHeight;

    this.$viewport.css({
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: '100%'
    });

    this.resizeStage(null, newHeight);
  };

  fn.resizeStage = function (width, height) {
    var Env = this.Env;

    if (Env.flashVars.deviceType === 'Phone') {
      return;
    }
    if (!this.$skin.length && !this.$viewport) {
      return;
    }

    var mark = _utils2.default.benchmark('resizeStage').start();

    if (!Env.isPersistentWindow) {
      var maxHeight = $(this.Env.top).height();
      var maxWidth = $(this.Env.top).width();
      var currentHeight = this.$skin.height() || this.$viewport.height();
      var currentWidth = this.$skin.width() || this.$viewport.width();

      width = Math.min(width || currentWidth, maxWidth);
      height = Math.min(height || currentHeight, maxHeight);
    }

    mark('setup');
    _ijsfAdapter2.default.resizeStage(width, height);
    mark('IJSF.resizeStage()');
    this.position.fit();
    mark('view.position.fit()');

    this.$viewport.siblings().hide();
    mark.end();
  };

  fn.getTabbables = function ($html) {
    $html = $html || this.$viewport;
    var tabbableSelectors = ':input,a,button,[tabindex]';
    return $html.find(tabbableSelectors).not(':hidden');
  };

  fn.setFrames = function () {
    this.$xframe = this.Automaton.options.$container;
    this.$skin = this.Automaton.options.$skin;
  };

  fn.makeButtonsTapResponsive = function () {
    var _this3 = this;

    // Read more at https://github.com/ftlabs/fastclick
    if (this.Env.top.FastClick) return;

    var fastClickJsLibraryUrl = ACIF.baseUrl + '/assets/FastClick/fastclick-1.0.6.js';
    this.Env.top.document.body.appendChild(document.createElement('script')).src = fastClickJsLibraryUrl;

    this.Automaton.utils.doAfter({
      condFn: function condFn() {
        return !!_this3.Env.top.FastClick;
      },
      afterFn: function afterFn() {
        try {
          if (_this3.Env.top.FastClick.attach) {
            _this3.Env.top.FastClick.attach(_this3.$viewport[0]);
          } else {
            new _this3.Env.top.FastClick(_this3.$viewport[0]);
          }
        } catch (e) {
          _utils2.default.console('Failed to initialize FastClick.', 'error');
        }
      },
      poll: 10,
      maxWait: 2000
    });
  };

  fn.loadViewport = function () {
    this.$xframe.children().hide().attr('aria-hidden', 'true');

    this.$viewport = $('<div>').attr('id', this.NS + 'viewport').addClass(_utils2.default.namespaceString(this.NS, 'viewport acif-wrapping')).css({
      overflowY: 'auto',
      backgroundColor: 'white',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: '100%',
      boxSizing: 'border-box'
    }).appendTo(this.$xframe);

    if (!this.Env.is_preview_mode) {
      this.reAttachViewportToDOM();
    }
  };

  fn.reAttachViewportToDOM = function () {
    var _this4 = this;

    var loadedInDomStart = new Date().getTime();
    var interval = this.Automaton.utils.timers.setInterval(function () {
      if (_this4.$viewport.parent()[0] !== _this4.$xframe[0]) {
        _utils2.default.console('re-appending $viewport after ' + (new Date().getTime() - loadedInDomStart) + 'ms');
        // @todo: is this going to cause an issue with event listeners?
        _this4.$viewport.appendTo(_this4.$xframe);
        if (_this4.isAriaEnabled) {
          _this4.Aria.placeFocusOnView();
        }

        clearInterval(interval);
      }
    }, 10);

    this.Automaton.utils.timers.setTimeout(function () {
      clearInterval(interval);
    }, 10 * 1000);
  };

  fn.createLightbox = function () {
    var self = this;
    var settings = this.Automaton.settings;
    var lightboxSettings = typeof settings.lightbox === 'object' ? settings.lightbox : {};

    var $body = $('body', this.Env.top.document);
    var customLightboxStyles = _.pick(lightboxSettings, ['background', 'background-color', 'backgroundColor', 'opacity', 'zIndex', 'z-index']);
    var lightboxEl = this.Env.top.document.createElement('DIV');
    var $lightbox = $(lightboxEl);
    var lightboxStyles = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '999999px',
      height: '999999px',
      background: 'black',
      opacity: 0.5,
      zIndex: 2147000000
    };

    _.extend(lightboxStyles, customLightboxStyles);

    $lightbox.attr('id', 'inq-lightbox-' + this.Automaton.namespace).addClass('inq-lightbox').appendTo($body).css(lightboxStyles);

    if (lightboxSettings.closeOnEscape) {
      $body.on('keydown.inq-lightbox', function (e) {
        if (e.keyCode === $.ui.keyCode.ESCAPE) {
          self.Exit.unload();
          $body.off('.inq-lightbox');
        }
      });
    }

    if (lightboxSettings.closeOnClick) {
      $lightbox.one('click', this.Exit.unload.bind(this.Exit));
    }

    this.$lightbox = $lightbox;
  };

  fn.loadStyles = function () {
    var jqueryuiStylesheetUrl = ACIF.baseUrl + '/assets/jquery-ui/jquery-ui.min.css';
    var flatpickrStylesheetURL = ACIF.baseUrl + '/assets/flatpickr/flatpickr.min.css';

    _Style2.default.loadStyleSheet(jqueryuiStylesheetUrl, null, true, this.Automaton.Env.document);
    _Style2.default.loadStyleSheet(flatpickrStylesheetURL, null, true, this.Automaton.Env.document);

    // due to a delay loading the remote flatpickr stylesheet, the flatpickr element is not hidden soon enough
    _Style2.default.loadStyleSheet('#tcChat_Skin .flatpickr-calendar { display: none; }', null, false, this.Automaton.Env.document);

    var style = this.Automaton.getRecord(this.Automaton.settings.style, 'style');

    style.addNamespace(this.NS);

    var settings = this.Automaton.settings;

    if (settings.tooltips) {
      style.updateTooltipClassName();

      var options = typeof settings.tooltips === 'object' ? settings.tooltips : {};
      options.tooltipClass = tooltipClassName;
      options.position = options.position || {};
      options.position.within = this.Env.top;

      this.$viewport.tooltip(options);
    }

    style.addToDOM();
    style.preloadImages();
  };

  fn.selectAction = function (element) {
    var action = $(element).attr('acif-action');
    var actions = action.split(/[\s;,]+/g);
    var Automaton = this.Automaton;
    var panel = Automaton.options.panel;

    var nextNodeId = void 0;

    actions.forEach(function (action) {
      switch (action) {
        case 'exit':
          Automaton.close();
          break;
        case 'close-panel':
          _index2.default.closePanel(panel, Automaton.Env.isPersistentWindow);
          break;
        case 'back':
          {
            var breadcrumbs = _utils2.default.generateBreadcrumbs(Automaton.displayedPath);
            nextNodeId = breadcrumbs[breadcrumbs.length - 2];
            break;
          }
        case 'start_over':
        case 'start-over':
          nextNodeId = Automaton.displayedPath[0];
          break;
        default:
          {
            var ranCustomAction = Automaton.node.runCustomAction(action);
            if (!ranCustomAction) {
              _utils2.default.throwError('Unrecognized acif-action: "' + action + '"');
            }
          }
      }
    });

    if (nextNodeId) {
      this.Automaton.Transitions.selectNextNode(nextNodeId);
    }

    return false;
  };

  fn.enableCircularTabbing = function ($html, _ref) {
    var shouldTrapFocus = _ref.shouldTrapFocus;

    var skin = this.$skin[0];
    var minimizeButton = $('[id$=btnMinimize_img]:visible', skin)[0];
    var firstButton = minimizeButton || this.Exit.$exitButton[0];
    var tabbables = this.getTabbables($html);

    var lastEl = tabbables.length ? tabbables[tabbables.length - 1] : firstButton;

    // If shouldTrapFocus is enabled, focus is not allowed to go to the
    // chat window and is contained within the automaton view container.
    if (shouldTrapFocus) {
      firstButton = tabbables.get(0);
      lastEl = tabbables.get(-1);
      skin = this.Automaton.options.$container[0];
    }

    _ijsfAdapter2.default.setCircularTabbing(skin, firstButton, lastEl);
  };

  // Store a reference to the element that triggered the event
  // handler. Used to grab the element's text content when logging
  // data to the server for the Node Element_Response dimension
  fn.setActiveEvent = function (evt, element) {
    this.Automaton.activeEvent = {
      event: evt,
      element: element,
      $element: $(element),
      currentNode: this.Automaton.node,
      previousNode: this.Automaton.api.getPreviousNode()
    };
  };

  fn.enableCloseOnEscape = function () {
    var self = this;

    $('body', this.Env.document).on('keyup.' + this.NS, function (e) {
      if (e.keyCode === 27) {
        $('body', self.Env.document).off('.' + self.NS);
        self.Exit.unload();
        self.Exit.closeCI();
      }
    });
  };

  fn.disableBackAndForwardCaching = function () {
    //  - https://msdn.microsoft.com/library/ie/dn265017(v=vs.85).aspx
    //  - http://stackoverflow.com/questions/2638292/after-travelling-back-in-firefox-history-javascript-wont-run
    //  - https://developer.mozilla.org/en-US/docs/Web/Events/pageshow
    $(this.Env.top).on('unload.inq-acif beforeunload.inq-acif', $.noop);
  };

  fn.getQuestions = function () {
    var questions = [];
    var Forms = this.Automaton.Forms;

    this.$viewport.find('fieldset').each(function () {
      var attrs = Forms.getAttributes(this);

      questions.push({
        questionText: attrs.response.questionText,
        questionId: attrs.response.questionId
      });
    });

    return questions;
  };

  fn.hideAttributes = function ($element, attributes) {
    attributes.forEach(function (attribute) {
      var attributeValue = $element.attr(attribute);
      $element.removeAttr(attribute);
      $element.data(attribute, attributeValue);
    });
  };

  fn.autoFormatCurrencyField = function (event) {
    var $input = $(event.target);

    var start = this.selectionStart;
    var end = this.selectionEnd;

    var currencySymbol = $input.data('acif-currency-symbol');
    var thousandsSeparator = $input.data('acif-thousands-separator');
    var decimalSeparator = $input.data('acif-decimal-separator');

    var originalValue = $input.val();

    var originalCommasBeforeCursor = (originalValue.slice(0, start).match(/,/g) || []).length;

    var value = originalValue.replace(/\D/g, '');

    if (!value) return;

    var isDeletingZero = value === '00';
    if (isDeletingZero) {
      $input.val('');
      return;
    }

    var change = value.slice(-2);
    var amount = value.slice(0, -2);

    if (change.length === 1) {
      change = '0' + change;
    }

    amount = amount.replace(/^0*/, '');

    if (thousandsSeparator) {
      amount = amount.replace(/(\d)(?=(\d{3})+$)/g, '$1' + thousandsSeparator);
    }

    if (!amount.length) {
      amount = '0';
    }

    var outputValue = '' + currencySymbol + amount + decimalSeparator + change;

    var modifiedCommasBeforeCursor = (outputValue.slice(0, start).match(/,/g) || []).length;

    $input.val(outputValue);

    if (originalValue.length !== start) {
      start = start + (modifiedCommasBeforeCursor - originalCommasBeforeCursor);
      end = end + (modifiedCommasBeforeCursor - originalCommasBeforeCursor);
      this.setSelectionRange(start, end);
    }
  };

  fn.setCircularTabbing = function ($html) {
    var Automaton = this.Automaton;
    var enableCircularTabbing = Automaton.getProp('circularTabbing');
    var shouldTrapFocus = Automaton.getProp('shouldTrapFocus');

    if (enableCircularTabbing) {
      this.enableCircularTabbing($html, { shouldTrapFocus: shouldTrapFocus });
    } else if (enableCircularTabbing === false) {
      _ijsfAdapter2.default.removeCircularTabbing();
    }
  };

  fn.runAnimation = function ($currentViewContainer, $nextViewContainer, settings) {
    if (settings.type === 'slide') {
      return this.triggerSlideAnimation($currentViewContainer, $nextViewContainer, settings);
    }

    var promise = new $.Deferred();

    // Reject promise if no matching animations are found
    return promise.reject('Animation type \'' + settings.type + '\' is invalid');
  };

  fn.triggerSlideAnimation = function ($currentViewContainer, $nextViewContainer, settings) {
    var $viewport = this.$viewport;
    var viewportWidth = $viewport.parent().outerWidth();
    var promise = this.Automaton.utils.makePromise('trigger slide animation');

    $viewport.css({
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    });

    var $overlay = $('<div></div>').css({
      background: 'none',
      height: '100%',
      width: '100%',
      position: 'absolute',
      zIndex: 999
    });

    $viewport.prepend($overlay);

    $currentViewContainer.attr('id', 'view-container-animation');

    var viewContainerStyles = {
      display: 'inline-block',
      position: 'relative',
      whiteSpace: 'normal',
      height: '100%',
      width: viewportWidth + 'px'
    };

    $currentViewContainer.css(viewContainerStyles);
    $nextViewContainer.css(viewContainerStyles);

    if (settings.direction === 'right') {
      $viewport.css({
        right: '-' + viewportWidth + 'px',
        transform: 'translateX(-' + viewportWidth + 'px)'
      });

      $currentViewContainer.before($nextViewContainer);
    } else {
      $viewport.css({
        left: '-' + viewportWidth + 'px',
        transform: 'translateX(' + viewportWidth + 'px)'
      });

      $currentViewContainer.after($nextViewContainer);
    }

    parent.requestAnimationFrame(function () {
      $viewport.css({
        transition: settings.duration ? settings.duration / 1000 + 's' : '0.2s',
        transitionTimingFunction: 'linear',
        transform: 'none'
      });

      $viewport.one('transitionend', function () {
        $currentViewContainer.remove();
        $overlay.remove();

        $viewport.css({
          left: '0px',
          overflow: '',
          right: '0px',
          transform: '',
          transition: '',
          transitionTimingFunction: '',
          whiteSpace: ''
        });

        $nextViewContainer.css({
          display: '',
          height: 'auto',
          position: '',
          whiteSpace: '',
          width: ''
        });

        promise.resolve();
      });
    });

    return promise;
  };
});