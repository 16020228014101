define('storage/top-frame/local-storage-api', ['exports', '../utils/get', '../utils/set', './id-manager'], function (exports, _get, _set, _idManager) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _get2 = _interopRequireDefault(_get);

  var _set2 = _interopRequireDefault(_set);

  var _idManager2 = _interopRequireDefault(_idManager);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = StorageApi;


  function StorageApi(frameMessenger, storage) {
    this.frameMessenger = frameMessenger;
    this.storage = storage;
  }

  var fn = StorageApi.prototype;

  fn.peek = function (path, scope, callback) {
    if (!scope || typeof scope === 'function') {
      callback = scope;
      scope = 'session';
    }

    var engagementId = (0, _idManager2.default)('engagement');

    if (scope === 'engagement' && !engagementId) return;

    var store = this._getStore(scope);

    if (!store) {
      // eslint-disable-next-line no-console
      console.error('"' + scope + '" is not a valid TC Storage scope. Expected one of: engagment, tab, session, lifetime, or custom');
      return;
    }

    var item = (0, _get2.default)(store, path);

    return typeof callback === 'function' ? callback(null, item) : item;
  };

  fn._getStore = function (scope) {
    var store = this.storage[scope];
    var id = (0, _idManager2.default)(scope);

    if (id !== store.id) {
      store = this.storage[scope] = { id: id, data: {} };
    }

    return store.data;
  };

  fn.get = function (path, scope, callback) {
    if (!scope || typeof scope === 'function') {
      callback = scope;
      scope = 'session';
    }

    var engagementId = (0, _idManager2.default)('engagement');

    this.frameMessenger.postMessage({
      method: 'get',
      params: { path: path, scope: scope, engagementId: engagementId }
    }, callback);
  };

  fn.set = function (path, value, scope) {
    if (typeof path === 'object') {
      value = path.value;
      scope = path.scope;
      path = path.path || path.key;
    }

    var engagementId = (0, _idManager2.default)('engagement');

    scope = scope || 'session';

    // Cannot set engagement storage when no engagement is active
    if (scope === 'engagement' && !engagementId) return;

    var store = this._getStore(scope);
    (0, _set2.default)(store, path, value);

    this.frameMessenger.postMessage({
      method: 'set',
      params: {
        path: path,
        value: value,
        scope: scope,
        engagementId: engagementId
      }
    }, function (err, response) {
      if (err) {
        return window.inqFrame.Inq.log(err, 'error');
      }

      this.storage = response.storage;

      if (response.changes) {
        Inq.fireCustomEvent('StorageChanged', response.changes);
      }
    });
  };
});