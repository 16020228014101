define('acif/view/formify/precompiled/formify-type', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (obj) {
    var __t,
        __p = '',
        __j = Array.prototype.join,
        print = function print() {
      __p += __j.call(arguments, '');
    };
    with (obj || {}) {
      __p += '';

      var groupItem = groupItem ? groupItem : false;
      var field = groupItem || field;

      __p += '\n\n';
      if (field.type === 'text') {
        __p += '\n\n    <div  acif-template="formify-type-text">' + ((__t = getSubTemplate("formify-type-text")(Object.assign({ field: field }, obj))) == null ? '' : __t) + ' </div>\n\n';
      } else if (field.type === 'email') {
        __p += '\n\n    <div  acif-template="formify-type-email">' + ((__t = getSubTemplate("formify-type-email")(Object.assign({ field: field }, obj))) == null ? '' : __t) + ' </div>\n\n';
      } else if (field.type === 'textarea') {
        __p += '\n\n    <div  acif-template="formify-type-textarea">' + ((__t = getSubTemplate("formify-type-textarea")(Object.assign({ field: field }, obj))) == null ? '' : __t) + ' </div>\n\n';
      } else if (field.type === 'select') {
        __p += '\n\n    <div  acif-template="formify-type-select">' + ((__t = getSubTemplate("formify-type-select")(Object.assign({ field: field }, obj))) == null ? '' : __t) + ' </div>\n\n';
      } else if (field.type === 'radio') {
        __p += '\n\n    ';
        if (field.scale) {
          __p += '\n      <div  acif-template="formify-type-radio-scale">' + ((__t = getSubTemplate("formify-type-radio-scale")(Object.assign({ field: field }, obj))) == null ? '' : __t) + ' </div>\n    ';
        } else {
          __p += '\n      <div  acif-template="formify-type-radio">' + ((__t = getSubTemplate("formify-type-radio")(Object.assign({ field: field }, obj))) == null ? '' : __t) + ' </div>\n    ';
        }
        __p += '\n\n';
      } else if (field.type === 'checkbox') {
        __p += '\n\n    <div  acif-template="formify-type-checkbox">' + ((__t = getSubTemplate("formify-type-checkbox")(Object.assign({ field: field }, obj))) == null ? '' : __t) + ' </div>\n\n';
      } else if (field.type === 'label') {
        __p += '\n\n    <div  acif-template="formify-type-label">' + ((__t = getSubTemplate("formify-type-label")(Object.assign({ field: field }, obj))) == null ? '' : __t) + ' </div>\n\n';
      }
      __p += '';
    }
    return __p;
  };
});