define('acif/ci/index', ['exports', '../utils', './push-content', './ijsf-adapter'], function (exports, _utils, _pushContent, _ijsfAdapter) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _pushContent2 = _interopRequireDefault(_pushContent);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  var listeners = {};

  exports.default = {
    listeners: listeners,
    findInSkin: findInSkin,
    getBusinessRuleAutomatonParams: getBusinessRuleAutomatonParams,
    getStage: getStage,
    getSkin: getSkin,
    initializePanel: initializePanel,
    closePanel: closePanel,
    isHorizontalPanel: isHorizontalPanel,
    isMainPanel: isMainPanel,
    isVerticalPanel: isVerticalPanel,
    reinstatePanel: reinstatePanel,
    resizePanel: resizePanel,
    resizeStage: resizeStage,
    toPanelName: toPanelName
  };


  listeners.onChatClosed = function () {
    resetCiData();
  };

  listeners.onAgentMsg = function (agentMessageData) {
    _pushContent2.default.extractAgentScriptCommands(agentMessageData);
  };

  listeners.onAgentDataPass = function (data) {
    var launchParams = void 0;

    try {
      launchParams = JSON.parse(data.agentDataPass.data.messageText);
    } catch (e) {
      ACIF.Utils.throwError('Unable to parse messageText. Received: ' + data.agentDataPass.data.messageText);
    }

    if (!launchParams) return;

    _pushContent2.default.processAgentDataPass(launchParams);
  };

  /**
   * Returns an array of automaton parameters configured in the
   * business rules XML (for pre-chat automatons)
   */
  function getBusinessRuleAutomatonParams() {
    var activeRule = _ijsfAdapter2.default.getActiveRule();

    _ijsfAdapter2.default.logDiagnosticInfoToServer('getBusinessRuleAutomatonParams called ActiveRuleId: ' + activeRule.id);

    var params = activeRule.getAutomatonParams();
    var map = _ijsfAdapter2.default.getAutomatonDataMap();
    var hasLegacyAutomatonRuleParams = !!map.acif_id;

    params = params.concat(_utils2.default.get(parent, 'NUANCE_PREVIEW.automatons') || []);

    params.forEach(function (param) {
      var datapass = param.datapass || {};
      param.context = param.context || 'ci';
      param.automatonID = param.automatonID || param.id || param.automatonId;

      param.location = param.location || _utils2.default.getAutomatonProp(param.automatonID, 'attributes.panel.location') || 'center';

      param.datapass = _.extend(map, datapass);
    });

    if (params.length) return params;
    if (!hasLegacyAutomatonRuleParams) return [];

    // If a business rule does not have any <automaton-params> tags,
    // and if the rule has acif_id in its automatonDataMap,
    // and if the acif-config.js file does not contain the specified automaton,
    // and if the skin contains an #acifV2Frame element,
    // then assume that the rule intends to load the automaton into
    // ACIF v2 within the XForms XFrame element (with id acifV2Frame).

    var isActive = !!require.entries['Automatons/automatons/' + map.acif_id];

    var v2Frame = findInSkin('div[data-acif-v2-frame]');
    var v2FrameId = v2Frame.attr('id');

    var hasV2Frame = !!(v2Frame && v2Frame.length);

    var v2FramePanelName = v2FrameId && v2FrameId.replace(/^tcChat_/, '');

    var shouldUseAcifV2 = hasV2Frame && !isActive;
    if (shouldUseAcifV2 && !_ijsfAdapter2.default.isChatConnected()) {
      // should we show the XFrame after a delay to then verify that
      // the xframe isn't alreaady loaded?

      // show the XFrame layer to toggle the init attribute to true
      _ijsfAdapter2.default.showLayer(v2FramePanelName);
      return [];
    }

    var location = map.location || _utils2.default.getAutomatonProp(map.acif_id, 'attributes.panel.location') || 'center';

    var panelName = toPanelName(location);

    var $panel = getPanel(panelName);
    var hasPanel = !!($panel && $panel.length);

    if (hasPanel && hasV2Frame) {
      _ijsfAdapter2.default.hideLayer(v2FramePanelName);
    }

    var shouldUseParamsFromDataMap = !!(hasV2Frame || hasPanel);

    if (shouldUseParamsFromDataMap && isActive) {
      params.push({
        datapass: map,
        location: hasPanel ? location : 'V2Frame',
        context: 'ci',
        automatonID: map.acif_id,
        shouldSuppress: map.should_suppress
      });
    }

    return params;
  }

  function getStage(context, ciLayerName) {
    var $skin = void 0,
        frame = void 0,
        $container = void 0,
        _document = void 0;

    if (context === 'ci') {
      var skin = getSkin();
      $skin = skin.$skin;
      frame = skin.frame;
      _document = skin.document;
      $container = $('[id$="' + ciLayerName + '"]', _document.body);
    } else if (context === 'page') {
      $container = $('#' + ciLayerName, parent.document);
      frame = parent;
      _document = parent.document;
    } else {
      throw new Error('Expected automaton "context" parameter to equal "ci" or "page". Received "' + context + '"');
    }

    if (!$container || !$container.length) {
      throw new Error('Could not find automaton container element "' + ciLayerName + '"');
    }

    return {
      frame: frame,
      $container: $container,
      $skin: $skin,
      document: _document
    };
  }

  function getSkin() {
    var skinEl = parent.document.getElementById('tcChat_Skin');
    var frame = void 0,
        _document = void 0;

    if (skinEl) {
      frame = parent;
      _document = parent.document;
    } else {
      skinEl = inqFrame.frameElement;
      _document = inqFrame.document;
      frame = inqFrame;
    }

    return {
      $skin: $(skinEl),
      frame: frame,
      document: _document
    };
  }

  function resetCiData() {
    ACIF.loaded = {};
  }

  function initializePanel(options, automatonSettings) {
    var panelId = options.panel;
    var Utils = ACIF.Utils;
    var automatonWidth = Utils.get(automatonSettings, 'panel.width');
    var automatonHeight = Utils.get(automatonSettings, 'panel.height');

    ACIF.ci = ACIF.ci || {};
    ACIF.ci[panelId] = ACIF.ci[panelId] || {};
    ACIF.ci[panelId].automatonId = options.automatonId;

    var DEFAULT_PANEL_WIDTH = 350;
    var DEFAULT_PANEL_HEIGHT = 200;
    automatonWidth = automatonWidth ? parseInt(automatonWidth) : null;
    automatonHeight = automatonHeight ? parseInt(automatonHeight) : null;
    var hasAutomatonDimensions = !!(automatonHeight || automatonWidth);

    // Main panels will be absolutley positioned
    // Horizontal panels will only consider the height attribute or use the default
    // Vertical panels will only consider the width attribute or use the default
    // Custom panels will pass both height & width with no default
    var panelOptions = {};

    if (isHorizontalPanel(panelId)) {
      panelOptions.height = automatonHeight || DEFAULT_PANEL_HEIGHT;
    } else if (isVerticalPanel(panelId)) {
      panelOptions.width = automatonWidth || DEFAULT_PANEL_WIDTH;
    } else if (hasAutomatonDimensions) {
      panelOptions.height = automatonHeight;
      panelOptions.width = automatonWidth;
    }

    var isLeftPanel = /left/ig.test(panelId);
    if (isLeftPanel) {
      shiftStage({ x: -panelOptions.width });
    }
    var isTopPanel = /top/ig.test(panelId);
    if (isTopPanel) {
      shiftStage({ y: -panelOptions.height });
    }

    var hasPanelDimensions = !!(panelOptions.height || panelOptions.width);
    var shouldResize = !isMainPanel(panelId) && hasPanelDimensions;

    if (shouldResize) {
      resizePanel(panelId, panelOptions);
    }

    var v2FramePanelId = toPanelName('V2Frame');

    if (!v2FramePanelId || panelId !== v2FramePanelId) {
      _ijsfAdapter2.default.showLayer(panelId);
    }
  }

  function closePanel(panelId, isPersistentWindow) {
    if (!doesPanelExist(panelId)) {
      ACIF.Utils.throwError(panelId + ' does not exist and therefore cannot be closed.');
    }

    var hasAutomatonDimensions = !!(ACIF.ci[panelId].width > 0 || ACIF.ci[panelId].height > 0);

    // Main panels will be absolutley positioned
    // Horizontal panels will only set the height attribute to 0
    // Vertical panels will only set the height attribute to 0
    // Custom panels will pass both "panel" height & width to 0
    var panelOptions = {};

    if (isHorizontalPanel(panelId)) {
      panelOptions.height = 0;
    } else if (isVerticalPanel(panelId)) {
      panelOptions.width = 0;
    } else if (hasAutomatonDimensions) {
      panelOptions.height = 0;
      panelOptions.width = 0;
    }

    var $panel = getPanel(panelId);
    var panelWidth = $panel.width();
    var panelHeight = $panel.height();

    var shouldResize = !isMainPanel(panelId) && hasAutomatonDimensions;
    if (shouldResize) {
      resizePanel(panelId, panelOptions);
    }

    var isLeftPanel = /left/ig.test(panelId);
    var shouldShift = !isPersistentWindow;

    if (isLeftPanel && shouldShift) {
      shiftStage({ x: panelWidth });
    }

    var isTopPanel = /top/ig.test(panelId);
    if (isTopPanel && shouldShift) {
      shiftStage({ y: panelHeight });
    }

    _ijsfAdapter2.default.hideLayer(panelId);

    if (ACIF.loadedStack.length) {
      var automatonId = JSON.parse(ACIF.loadedStack.slice(-1)).params.automatonID;

      ACIF.getAutomatonById(automatonId).setAsActive();
    } else {
      _ijsfAdapter2.default.setFocusOnChatInput();
    }
  }

  function resizePanel(panelId, options) {
    var width = options.width,
        height = options.height;

    var $panel = getPanel(panelId);

    if (!$panel) {
      ACIF.Utils.throwError(panelId + ' does not exist and therefore cannot be resized.');
    }

    var deltaWidth = 0,
        deltaHeight = 0;
    var hasHeight = height >= 0;
    var hasWidth = width >= 0;

    if (!(hasHeight || hasWidth)) {
      ACIF.Utils.throwError('Automaton does not contain property "panel" with a specified height or width');
    }

    if (hasHeight) {
      var panelHeight = $panel.height();
      deltaHeight = height - panelHeight;
      _ijsfAdapter2.default.setElementPosition(panelId, { height: height });
      ACIF.ci[panelId].height = height;
    }

    if (hasWidth) {
      var panelWidth = $panel.width();
      deltaWidth = width - panelWidth;
      _ijsfAdapter2.default.setElementPosition(panelId, { width: width });
      ACIF.ci[panelId].width = width;
    }

    var isDesktop = _ijsfAdapter2.default.getDeviceType() !== 'Phone';
    var shouldExtend = isExtendedPanel(panelId);

    if (isDesktop && shouldExtend) {
      extendStage({
        width: deltaWidth,
        height: deltaHeight
      });
    }
  }

  // Restore width or height of extended or custom panel when CI is reloaded
  function reinstatePanel(panelId) {

    if (isVerticalPanel(panelId)) {
      var width = ACIF.ci[panelId].width;
      _ijsfAdapter2.default.setElementPosition(panelId, { width: width });
    } else if (isHorizontalPanel(panelId)) {
      var height = ACIF.ci[panelId].height;
      _ijsfAdapter2.default.setElementPosition(panelId, { height: height });
    } else if (!isMainPanel(panelId)) {
      // Custom
      var customDimensions = getCustomPanelDimensions(panelId);
      _ijsfAdapter2.default.setElementPosition(panelId, customDimensions);
    }
  }

  function shiftStage() {
    var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _offset$x = offset.x,
        x = _offset$x === undefined ? 0 : _offset$x,
        _offset$y = offset.y,
        y = _offset$y === undefined ? 0 : _offset$y;


    var $skin = ACIF.$skin;

    var _$skin$position = $skin.position(),
        left = _$skin$position.left,
        top = _$skin$position.top;

    var moveLeft = Math.max(left + x, 0);
    var moveTop = Math.max(top + y, 0);
    _ijsfAdapter2.default.moveStage('ABSOLUTE', moveLeft, moveTop);
  }

  function resizeStage() {
    var dimensions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var $skin = ACIF.$skin;
    var currentStageWidth = $skin.width();
    var currentStageHeight = $skin.height();
    var width = dimensions.width,
        height = dimensions.height;


    var newStageWidth = width || currentStageWidth;
    var newStageHeight = height || currentStageHeight;
    _ijsfAdapter2.default.resizeStage(newStageWidth, newStageHeight);
  }

  function extendStage() {
    var dimensions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var $skin = ACIF.$skin;
    var _dimensions$width = dimensions.width,
        width = _dimensions$width === undefined ? 0 : _dimensions$width,
        _dimensions$height = dimensions.height,
        height = _dimensions$height === undefined ? 0 : _dimensions$height;


    var stageWidth = $skin.width();
    var stageHeight = $skin.height();

    var newStageWidth = stageWidth + width;
    var newStageHeight = stageHeight + height;

    _ijsfAdapter2.default.resizeStage(newStageWidth, newStageHeight);
  }

  function doesPanelExist(panelId) {
    var $panel = getPanel(panelId);
    return !!$panel.length;
  }

  function findInSkin(selector) {
    var $skin = getSkin().$skin;

    var isDivSkin = $skin.is('div');

    if (isDivSkin) {
      return $skin.find(selector);
    }
    // IFrame skins require .contents()
    return $skin.contents().find(selector);
  }

  function getPanel(panelId) {
    var $skin = getSkin().$skin;

    var isDivSkin = $skin.is('div');

    if (isDivSkin) {
      return $skin.find('[id$="' + panelId + '"]');
    }
    // IFrame skins require .contents()
    return $skin.contents().find('[id$="' + panelId + '"]');
  }

  function toPanelName(location) {
    if (location === 'V2Frame') {
      var $panel = findInSkin('[data-acif-v2-frame]');
      var panelId = $panel.attr('id') || '';
      return panelId.replace(/^tcChat_/, '');
    }

    return 'acif' + location.split(/\W+/g).map(function (s) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    }).join('');
  }

  function getCustomPanelDimensions(panelId) {
    var _ACIF$ci$panelId = ACIF.ci[panelId],
        width = _ACIF$ci$panelId.width,
        height = _ACIF$ci$panelId.height;

    var panelDimensions = {};
    if (width) panelDimensions.width = width;
    if (height) panelDimensions.height = height;
    return panelDimensions;
  }

  function isMainPanel(panelId) {
    var MAIN_PANELS = ['acifCenter', 'acifInnerFull', 'acifOuterFull'];
    if (MAIN_PANELS.indexOf(panelId) > -1) return true;

    var $panel = findInSkin('div[id$="' + panelId + '"][data-acif-v2-frame]');
    return !!$panel.length;
  }

  function isExtendedPanel(panelId) {
    return isVerticalPanel(panelId) || isHorizontalPanel(panelId);
  }

  function isVerticalPanel(panelId) {
    var VERTICAL_PANELS = ['acifOuterLeft', 'acifInnerLeft', 'acifInnerRight', 'acifOuterRight'];
    return VERTICAL_PANELS.indexOf(panelId) > -1;
  }

  function isHorizontalPanel(panelId) {
    var HORIZONTAL_PANELS = ['acifOuterTop', 'acifTranscriptTop', 'acifTranscriptBottom', 'acifOuterBottom'];
    return HORIZONTAL_PANELS.indexOf(panelId) > -1;
  }
});