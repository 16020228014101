define('acif/utils/isNumber', ['exports'], function (exports) {
    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var isNumber = function isNumber(value) {
        value = (value || '').toString();
        var isNumeric = !!value.match(/^\d+\.?\d?$/);
        return typeof value === 'number' || isNumeric;
    };

    exports.default = isNumber;
});