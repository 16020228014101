define('acif/view/exit', ['exports', '../utils', '../ci/ijsf-adapter', '../ci/index'], function (exports, _utils, _ijsfAdapter, _index) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  var _index2 = _interopRequireDefault(_index);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Exit;


  function Exit(View) {
    this.View = View;
    this.NS = View.NS;
    this.Data = View.Automaton.Data;
    this.Env = View.Automaton.Env;
    this.timers = View.Automaton.utils.timers;
  }

  var fn = Exit.prototype = { constructor: Exit };

  fn.registerExitListener = function () {
    var _this = this;

    _utils2.default.events.addListener('error', this.onError.bind(this));

    _ijsfAdapter2.default.addListener('onChatClosed', function (evt) {
      var currentNode = _this.View.Automaton.node;
      _this.unload(currentNode);
    });

    this.$exitButton = this.getExitButton();

    // When loading post-chat surveys, the IJSF gives up responsibility to close the CI
    // after the customer clicks the close button the 2nd time.  The IJSF expects the
    // ACIF to manage the closing of the CI after a post-chat survey is loaded.
    var automatonType = this.View.Automaton.settings.type;
    if (automatonType === 'satisfactionSurvey') {
      this.$exitButton.on('click', function () {
        _ijsfAdapter2.default.closeCI();
      });
    }
  };

  fn.getExitButton = function () {
    var $context = this.View.$skin.is('iframe') ? this.View.Automaton.options.$container.closest('body') : this.View.$skin;

    return $context.find('[id$=btnCloseChat_img]');
  };

  fn.unloaded = false;

  fn.unload = function () {
    var exitNode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (this.unloaded) return;

    this.cleanup();

    this.Data.save('currentNodeModulePath', null);
    this.Data.triggerEnded(exitNode);

    var _View$Automaton$optio = this.View.Automaton.options,
        context = _View$Automaton$optio.context,
        panel = _View$Automaton$optio.panel,
        startedBy = _View$Automaton$optio.startedBy;


    if (context === 'ci') {

      ACIF.ciPanelAutomatonMap[location] = null;

      var shouldMinimize = !!exitNode && exitNode.minimize === true;
      var isMainPanel = _index2.default.isMainPanel(panel);
      var isAgentPushed = !!startedBy && startedBy.type === 'agent';

      if (shouldMinimize) {
        _ijsfAdapter2.default.minimizeStage();
        _index2.default.closePanel(panel, this.View.Automaton.Env.isPersistentWindow);
      } else if (isAgentPushed) {
        _index2.default.closePanel(panel, this.View.Automaton.Env.isPersistentWindow);
      } else if (isMainPanel && !exitNode.isEngagingChat) {
        _ijsfAdapter2.default.closeCI();
      } else {
        _index2.default.closePanel(panel, this.View.Automaton.Env.isPersistentWindow);
      }
    }

    return false;
  };

  fn.cleanup = function () {
    this.unloaded = true;
    this.timers.clearTimers();
    this.removeListeners();

    this.View.$viewport.remove();

    // Assign an empty div to the $viewport object in case
    // other parts of the ACIF try to invoke jQuery methods
    // on the viewport after it has already been removed
    // from the DOM.  Only an issue presently for trying
    // to extract question displayed to customer while
    // routing to a chat node.
    this.View.$viewport = $('<div>');

    if (this.View.$lightbox) {
      this.View.$lightbox.remove();
    }
  };

  fn.removeListeners = function () {
    var NS = '.' + this.NS;

    _ijsfAdapter2.default.updateInputListeners(this.View.Automaton.hash);

    $(this.Env.top).off('.inq-acif').off(NS);
    $(this.Env.document).off('.inq-acif').off(NS);
  };

  fn.closeCI = function () {
    this.timers.clearTimers();

    try {
      _ijsfAdapter2.default.closeCI();
    } catch (e) {
      _utils2.default.logger.error('Failed to close CI. Received error: ' + e.message);
    }
  };

  fn.onError = function () {
    this.removeListeners();
    this.unloaded = true;
  };
});