define('acif/view/FileUpload', ['exports', '../ci/ijsf-adapter'], function (exports, _ijsfAdapter) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = FileUpload;


  function FileUpload(event, Automaton) {
    this.Automaton = Automaton;
    var node = Automaton.node;

    var messageOptions = node.fileUpload && node.fileUpload.messages ? node.fileUpload.messages : {};
    this.messages = {
      typeError: messageOptions.typeError || 'Invalid file format',
      sizeError: messageOptions.sizeError || 'File exceeds the allowed file size',
      badError: messageOptions.badFileError || 'Bad file, may be corrupt',
      serverError: messageOptions.serverError || 'Error occured while uploading the file',
      success: messageOptions.success || 'The file uploaded successfully'
    };

    _ijsfAdapter2.default.uploadFile(event, _.extend(this.messages, {
      cb: this.onUpload.bind(this)
    }));
  }

  var fn = FileUpload.prototype;

  fn.onUpload = function (status) {
    var Automaton = this.Automaton;
    Automaton.api.fileUploadStatus = status;
    Automaton.Transitions.trigger('fileUpload');

    var $skin = Automaton.View.$skin;
    $skin.find('[acif-file-upload-status]').text(this.messages[status]);
  };
});