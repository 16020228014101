define('acif/chat/chat', ['exports', '../environment/is-dev-mode', '../ci/ijsf-adapter', '../utils'], function (exports, _isDevMode, _ijsfAdapter, _utils) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _isDevMode2 = _interopRequireDefault(_isDevMode);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  var _utils2 = _interopRequireDefault(_utils);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = Chat;


  function Chat(Automaton) {
    this.Automaton = Automaton;
    this.engageChatCalled = false;
  }

  var fn = Chat.prototype = { constructor: Chat };

  fn.loadChatNode = function (chatNode) {
    var _this = this;

    var _Automaton = this.Automaton,
        Data = _Automaton.Data,
        Transitions = _Automaton.Transitions;


    this.node = chatNode;

    Transitions.runNodeEntry(chatNode).then(function (didReTransition) {
      if (didReTransition) {
        return;
      }

      if (chatNode.viewport) {
        _this.setChatWindowSizeAndPosition();
      }

      if (!_this.engageChatCalled) {
        _this.engageChat();
      }

      Data.Storage.persist();
    });
  };

  fn.logChatOutcome = function (chatNode) {
    var Data = this.Automaton.Data;

    var questions = [];

    Data.triggerContentSentToCustomer(chatNode, questions);
    Data.triggerEnded(chatNode);
  };

  fn.setChatWindowSizeAndPosition = function () {
    var View = this.Automaton.View;
    var settings = this.node.viewport || {};

    if (settings.height || settings.width) {
      View.resizeStage(settings.width, settings.height);
    }

    if (settings.position) {
      View.position.set(settings.position);
    }
  };

  fn.resolveChatNode = function (chatNode) {
    var Automaton = this.Automaton;
    var ignoreAA = Automaton.activeEvent ? Automaton.activeEvent.$element.is('[acif-ignore-aa]') : false;
    var skipAACheck = !chatNode.chatRouter.checkAgentAvailability || ignoreAA;

    Automaton.utils.timers.clearTimers();

    if (skipAACheck) {
      var promise = Automaton.utils.makePromise();
      return promise.resolve(chatNode.id);
    }

    delete Automaton.settings._targetChatNode;

    this.Automaton.settings.runHook('willCheckAvailability', chatNode);
    chatNode.runHook('willCheckAvailability');

    return this.checkAA(chatNode).then(function (response) {
      if (response.AA && response.inHOP) {
        return chatNode.id;
      }

      Automaton.settings._targetChatNode = chatNode.id;

      var chatRouter = chatNode.chatRouter;

      if (response.outsideHOP && chatRouter.outsideHopNode) {
        _utils2.default.console('Outside hours of operation. Routing to outside HOP node');
        return chatRouter.outsideHopNode;
      } else if (chatRouter.agentsBusyNode) {
        _utils2.default.console('All agents are busy. Routing to agents busy node.');
        return chatRouter.agentsBusyNode;
      } else {
        _utils2.default.console('No agents available. Customer will be queued.');
        return chatNode.id;
      }
    });
  };

  fn.checkAA = function () {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var chatRouter = params.chatRouter || {
      businessUnit: params.business_unit_id,
      agentGroup: params.agent_group_id
    };

    var getProp = this.Automaton.getProp.bind(this.Automaton);
    var data = {};
    var agentAttributes = chatRouter.agentAttributes || getProp(['chatRouter.agentAttributes', 'agentAttributes']);
    var agentGroupId = chatRouter.agentGroup || getProp(['chatRouter.agentGroup', 'agentGroup']);
    var buID = chatRouter.businessUnit || getProp(['chatRouter.businessUnit', 'businessUnit']);
    var qThreshold = params.qThreshold || chatRouter.qThreshold || getProp(['chatRouter.qThreshold', 'qThreshold']);

    data.buID = buID;
    data.siteID = _ijsfAdapter2.default.getSiteId();

    if (!data.buID) {
      _utils2.default.throwError('Business Unit ID must be specified in order to check for agent availability.');
    }

    if (!data.siteID) {
      _utils2.default.throwError('Site ID must be specified in order to check for agent availability.');
    }

    if (agentGroupId) {
      data.agID = agentGroupId;
    }

    if (qThreshold) {
      data['q-thresh'] = qThreshold;
    }

    // Create a JSON array string for the agent attributes
    if (agentAttributes) {
      var attributes = agentAttributes.split(';');
      var attributesArray = [];

      _.each(attributes, function (attribute) {
        var parts = attribute.split(',');
        var name = (parts[0] || '').trim();
        var value = (parts[1] || '').trim();
        attributesArray.push({ name: name, value: value });
      });

      data.agentAttributes = JSON.stringify(attributesArray);
    }

    this.aaData = data;

    this.aaPromise = this.Automaton.utils.makePromise('agent availability check');

    // Don't check AA status from server if we already checked
    // AA in the past 5 seconds for the same paramters.
    var cachedStatus = this.checkAAFromCache(data);
    if (cachedStatus && _.now() - cachedStatus.timestamp < 5000) {
      this.aaPromise.resolve(cachedStatus.status);
      return this.aaPromise;
    }

    var uniqueData = _.clone(data);

    //adding randomString to the agent availability url to avoid browser from caching previous requests.
    uniqueData['no-cache'] = Date.now();

    _utils2.default.console('Checking agent availability for: ' + JSON.stringify(uniqueData));

    var agentsAvailabilityCheckURL = _ijsfAdapter2.default.getAgentsAvailabilityCheckUrl();

    var timestamp = 't' + Date.now();
    uniqueData.callback = 'ACIF._jsonpCallbacks.' + timestamp;
    window.ACIF._jsonpCallbacks[timestamp] = this.aaCallback.bind(this);

    var aaParams = $.param(uniqueData);

    agentsAvailabilityCheckURL += '?' + aaParams;

    document.body.appendChild(document.createElement('script')).src = agentsAvailabilityCheckURL;

    return this.aaPromise;
  };

  fn.aaCallback = function (response) {
    response.AA = response.availability === 'true';
    response.inHOP = response.inHOP === 'true';
    response.online = response.status === 'online';
    response.outsideHOP = !response.inHOP;
    this.updateAAStatusCache(this.aaData, response);

    this.aaData = null;

    this.aaPromise.resolve(response);
  };

  fn.aaStatusCache = {};

  fn.checkAAFromCache = function (params) {
    var id = JSON.stringify(params);
    return this.aaStatusCache[id];
  };

  fn.updateAAStatusCache = function (params, status) {
    var id = JSON.stringify(params);
    this.aaStatusCache[id] = {
      status: status,
      timestamp: _.now()
    };
  };

  fn.createPathForDatapass = function (pathPrefix) {
    var _this2 = this;

    pathPrefix = pathPrefix || 'Selections';

    var pathString = '';
    var breadcrumbs = _utils2.default.generateBreadcrumbs(this.Automaton.Data.storage.path);

    breadcrumbs = breadcrumbs.map(function (item) {
      var node = _this2.Automaton.getRecord(item);
      return node.breadcrumb || node.name;
    });

    if (breadcrumbs.length) {
      var i = 0;
      breadcrumbs = breadcrumbs.map(function (crumb) {
        return '\n    ' + ++i + ') ' + crumb;
      });
      pathString += '- ' + pathPrefix + ':' + breadcrumbs.join('');
    }

    return pathString;
  };

  fn.engageChat = function (settings) {
    this.engageChatCalled = true;

    this.logChatOutcome(this.node);

    var s = settings || {};
    var n = this.node;
    var prop = this.Automaton.getProp.bind(this.Automaton);
    var enableCircularTabbing = prop('circularTabbing');
    var publicUserId = s.publicUserId || prop('publicUserId', n);
    var agentDatapass = s.agentDatapass || prop(['chatRouter.agentOutcome', 'agentDatapass'], n) || '';
    var agentName = s.agentName || prop('chatRouter.agentName', n);
    var autoOpener = (s.autoOpener || prop(['chatRouter.clientOutcome', 'autoOpener'], n) || '') + '';
    var agentAttributes = (s.agentAttributes || prop('chatRouter.agentAttributes', n) || '') + '';
    var businessUnitID = (s.businessUnitID || prop('chatRouter.businessUnit', n) || '') + '';
    var agentGroupId = (s.agentGroupId || prop('chatRouter.agentGroup', n) || '') + '';
    var pathPrefix = (s.path_prefix || prop('path_prefix', n) || '') + '';
    var scriptTreeId = (s.scriptTreeId || prop(['chatRouter.scriptTree', 'scriptTreeId', 'scriptTreeID'], n) || '') + '';
    var hideLayers = $.trim(s.hideLayers || prop('chatRouter.hideLayers', n) || '');
    var showLayers = $.trim(s.showLayers || prop('chatRouter.showLayers', n) || '');
    var deferChatRouting = s.deferChatRouting || prop('deferChatRouting', n) || false;
    var routingAllocationSpec = s.routingAllocationSpec || prop('routingAllocationSpec', n);
    var isWebRTCAudio = s.isWebRTCAudio || prop('isWebRTCAudio', n) || false;
    var isWebRTCVideo = s.isWebRTCVideo || prop('isWebRTCVideo', n) || false;
    var virtualAssistantId = s.virtualAssistantId || prop('chatRouter.virtualAssistantId', n);
    var phone = s.phone || '';
    var guidePath = typeof s.guidePath !== 'undefined' ? s.guidePath : prop('guidePath', n);
    var formData = '';
    var datapass = [];

    if (guidePath !== false) {
      guidePath = typeof guidePath === 'string' ? guidePath : this.createPathForDatapass(pathPrefix);
    }
    if (s.formData !== false) {
      formData = typeof s.formData === 'string' ? s.formData : this.Automaton.Data.CRM.toDatapassString();
    }

    if (guidePath) {
      datapass.push(guidePath);
    }
    if (formData) {
      datapass.push(formData);
    }
    if (agentDatapass) {
      datapass.push(agentDatapass);
    }

    agentDatapass = datapass.join('\n');
    agentDatapass = agentDatapass ? '\n' + agentDatapass : '';

    var params = {
      automatonID: this.Automaton.id,
      context: 'ci',
      location: this.Automaton.options.location,
      isEngagingChat: true
    };

    ACIF.closeAutomaton(params);

    if (hideLayers) {
      _.each(hideLayers.split(/[\s,;]+/), function (layer) {
        _ijsfAdapter2.default.hideLayer(layer);
      });
    }

    if (showLayers) {
      showLayers = showLayers.split(/[\s,;]+/);
      if (showLayers.indexOf('chat') === -1) {
        showLayers.push('chat');
      }
    } else {
      showLayers = ['chat'];
    }

    showLayers.forEach(function (layer) {
      _ijsfAdapter2.default.showLayer(layer);
    });

    var chatParams = {
      agentAttrs: agentAttributes,
      agentGroupId: agentGroupId,
      agentName: agentName,
      agentOutcome: agentDatapass,
      businessUnitID: businessUnitID,
      circularTabbing: enableCircularTabbing,
      clientOutcome: autoOpener,
      // will close CI on btnCallEnd click (WebRTC)
      closeOnEnd: true,
      phone: phone,
      publicUserId: publicUserId,
      routingAllocSpecs: routingAllocationSpec,
      scriptTreeId: scriptTreeId,
      automatonId: virtualAssistantId
    };

    if (isWebRTCAudio) {
      _ijsfAdapter2.default.engageAudio(chatParams);
    } else if (isWebRTCVideo) {
      _ijsfAdapter2.default.engageVideo(chatParams);
    } else if (deferChatRouting) {
      _ijsfAdapter2.default.setEngageParameters(chatParams);
    } else {
      _ijsfAdapter2.default.engageChat(chatParams);
    }

    if ((0, _isDevMode2.default)()) {
      var msg = 'Routing customer to chat with the following parameters:';
      for (var key in chatParams) {
        msg += '\n** ' + key + ': "' + chatParams[key] + '"';
      }
      _utils2.default.console(msg);
    }
  };
});