define('acif/view/aria', ['exports', '../utils', '../ci', '../utils/mixins'], function (exports, _utils, _ci) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _utils2 = _interopRequireDefault(_utils);

  var _ci2 = _interopRequireDefault(_ci);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  var get = _utils2.default.get,
      throwError = _utils2.default.throwError,
      namespaceString = _utils2.default.namespaceString;
  exports.default = Aria;


  function Aria(View) {
    this.View = View;
  }

  var fn = Aria.prototype = { constructor: Aria };

  fn.init = function (view) {
    this.createSpeaker();

    if (this.View.Env.isAndroidMobile) {
      var invalidClass = this.View.NS + 'invalid';
      var self = this;
      $(this.View.$viewport).on('focus', 'fieldset.' + invalidClass + ' :input', function () {
        self.speakErrorMessageOnFocus(this);
      });
    }
  };

  fn.createSpeaker = function () {
    var speakerDiv = this.View.Env.document.createElement('DIV');
    var $speaker = $(speakerDiv);

    var speakerRole = this.View.Env.isAndroidMobile ? 'presentation' : 'log';

    $speaker.attr({
      'id': 'tc-acif-aria-speaker',
      'role': speakerRole,
      'aria-live': 'polite',
      'aria-relevant': 'additions'
    });

    $speaker.css({
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      width: '1px'
    });

    $speaker.appendTo(this.View.$viewport);

    this.$speaker = $speaker;
  };

  fn.enableAriaHtml = function ($html) {
    var Env = this.View.Env;

    this.$speaker.children().hide().attr('aria-hidden', true);

    // Ensure all button elements have type of button
    $html.find('button:not([type])').attr('type', 'button');

    this.repositionAncestorLabels($html);

    // Make all acif-node, acif-transition, and acif-action elements
    // behave properly with the keyboard and speak the preferred content
    // when using screen readers
    $html.find('[acif-node], [acif-transition], [acif-action]').not(':input, a, button').not(':has(":input, a, button")').each(function () {
      var $this = $(this),
          content = $this.html(),
          $button = $('<button type="button"></button>'),
          $span = $('<span></span>').html(content).appendTo($button),
          title = $this.attr('title'),
          labelledby = $this.attr('aria-labelledby'),
          describedBy = $this.attr('aria-describedby');

      if (title) {
        $this.removeAttr('title');
        // Google Talkback has bug that causes screen reader to only read
        // the title attribute (if it exists) and then not read the
        // text content of the element
        if (Env.isAndroidMobile) {
          title = $this.text() + '. ' + title;
        }
        $button.attr('title', title);
      }

      // Allow the first button inside a list to speak the parent's
      // aria-describedby reference when the first button is focused
      if ($this.parent().is('[aria-describedby]')) {
        var listDescribedBy = $this.parent().attr('aria-describedby');
        var buttonID = _.uniqueId('tc-');
        $this.parent().removeAttr('aria-describedby');
        $span.attr('id', buttonID);

        if (Env.isAndroidMobile) {
          labelledby = listDescribedBy + ' ' + buttonID;
        } else {
          describedBy = listDescribedBy;
          labelledby = buttonID;
        }
      }

      if (labelledby) {
        $this.removeAttr('aria-labelledby');
        $button.attr('aria-labelledby', labelledby);
      }

      if (describedBy) {
        $this.removeAttr('aria-describedby');
        $button.attr('aria-describedby', describedBy);
      }

      $this.html($button);
    });

    $html.find('[role="menu"]').each(function () {
      $(this).find('> *').attr('role', 'menuitem');
    });

    if ($html.find('form').length) {
      this.enableAriaForm($html);
    }
  };

  fn.saveInitialDescribedby = function ($html) {
    $html.find('[aria-describedby]').each(function (index, element) {
      var $element = $(element);

      var originalDescribedby = $element.attr('aria-describedby');
      $element.data('original-aria-describedby', originalDescribedby);
    });
  };

  fn.placeInitialFocusOnView = function () {
    var _this2 = this;

    var $headerToBeSpoken = this.View.$viewport.find('[acif-aria-speak]');
    var textToBeSpoken = $headerToBeSpoken.text();
    var elementToBeFocused;

    var initialFocusElement = void 0;

    var isInitialNode = get(this, 'View.Automaton.displayedPath.length') === 1;
    if (isInitialNode) {
      initialFocusElement = get(this, 'View.Automaton.settings.initialFocusElement');
    }

    initialFocusElement = get(this, 'View.Automaton.node.initialFocusElement') || initialFocusElement;

    if (initialFocusElement) {
      elementToBeFocused = _ci2.default.findInSkin(initialFocusElement)[0];
    }

    if (!elementToBeFocused) {
      elementToBeFocused = this.getFirstSelectableElement();
    }

    if (!elementToBeFocused) {
      elementToBeFocused = this.View.Exit.$exitButton;
      textToBeSpoken = textToBeSpoken || this.getVisibleViewportText();
    }

    this.elementToBeFocused = elementToBeFocused;

    var speakDelay = this.View.Env.isIOS ? 5000 : 500;

    if (textToBeSpoken) {
      setTimeout(function () {
        _this2.speak(textToBeSpoken);
      }, speakDelay);
    }

    this.placeFocusOnView();

    if (this.View.Automaton.node.initialReadableElementIds) {
      this.enableAriaLabelledBy();
    }
  };

  fn.placeFocusOnView = function () {
    // This return statement below is to address a bug which causes
    // the window.onFocus listener to not be removed when the automaton
    // terminates and removes all event listeners
    if (!this.View.$viewport || !window) {
      return;
    }

    var $elementToBeFocused = this.getElementToBeFocused();
    var focusDelay = this.View.Env.flashVars.browserType.toUpperCase() === 'SAFARI' ? 1000 : 500;

    if (this.View.Env.isIOS9) {
      this.speakElementToBeFocused($elementToBeFocused);
    }

    $elementToBeFocused.blur();

    // Presence of 'data-first-focus' indicates CI that resize of chat is not required,
    // because if we set focus programmatically,
    // keyboard won't be shown, and chat could be resized to half of the screen.
    // Later this attribute will be removed in ChatTextFocusMonitorImplIphoneSafari7#calculateViewableArea,
    // and second focus will launch resize of view to the proper size.
    $elementToBeFocused.attr('data-first-focus', 'first-focus');

    setTimeout(function () {
      $elementToBeFocused.focus();
    }, focusDelay);
  };

  fn.enableAriaLabelledBy = function () {
    var namespace = this.View.NS;
    var readableElementIds = namespaceString(namespace, this.View.Automaton.node.initialReadableElementIds);
    var $elementToBeFocused = this.getElementToBeFocused();
    var originalAriaLabelledByValue = $elementToBeFocused.attr('aria-labelledby');
    var ariaLabelledByValue = readableElementIds + ' ' + (originalAriaLabelledByValue || '');
    var tagName = $elementToBeFocused.prop('tagName');
    var $screenReaderElement = void 0;
    var screenReaderElementId = namespace + _.uniqueId();

    if (tagName === 'BUTTON' || tagName === 'A') {
      $screenReaderElement = $('<div></div>');
      $screenReaderElement.attr('id', screenReaderElementId);
      $screenReaderElement.attr('style', 'position:absolute;right:-99999px;opacity:0;');
      $screenReaderElement.append(document.createTextNode($elementToBeFocused.text()));
      $elementToBeFocused.prepend($screenReaderElement);
    }

    if ($screenReaderElement) {
      ariaLabelledByValue = ariaLabelledByValue + ' ' + screenReaderElementId;
    }

    $elementToBeFocused.attr('aria-labelledby', ariaLabelledByValue);

    var restoreAriaLabelledByValue = function restoreAriaLabelledByValue() {
      if (originalAriaLabelledByValue) {
        $elementToBeFocused.attr('aria-labelledby', originalAriaLabelledByValue);
      } else {
        $elementToBeFocused.removeAttr('aria-labelledby');
      }

      if ($screenReaderElement) {
        $screenReaderElement.remove();
      }
    };

    if (this.View.Env.flashVars.deviceType === 'Phone') {
      setTimeout(restoreAriaLabelledByValue, 6000);
    } else {
      $elementToBeFocused.one('blur', restoreAriaLabelledByValue);
    }
  };

  fn.getElementToBeFocused = function () {
    return this.View.$lastFocusedElement || $(this.elementToBeFocused);
  };

  fn.speakElementToBeFocused = function ($elementToBeFocused) {
    var _this3 = this;

    var $fieldset = $elementToBeFocused.closest('fieldset');

    if (!$fieldset.length) {
      return;
    }

    $fieldset = $fieldset.clone().appendTo(this.View.$xframe).find(':hidden').remove().end();

    var textToBeSpoken = $fieldset.text().trim();

    var required = $fieldset.find(':input').first().is('[required],[aria-required]');

    $fieldset.remove();

    if (!textToBeSpoken) {
      return;
    }

    var fieldType = $fieldset.attr('acif-field-type') || '';

    textToBeSpoken += '. ' + fieldType + ' field' + (required ? '. required' : '');

    setTimeout(function () {
      _this3.speak(textToBeSpoken);
    }, 1000);
  };

  fn.speakErrorMessageOnFocus = function (el) {
    var errorMessage = $(el).closest('fieldset').find('[acif-invalid]:visible').text().trim();
    if (errorMessage) {
      this.speak(errorMessage);
    }
  };

  fn.returnFocusToDropdown = function (select) {
    var $select = $(select);
    setTimeout(function () {
      $select.blur().focus();
    }, 1000);
  };

  fn.speak = function (text) {
    var messageDiv = this.View.Env.document.createElement('DIV');
    var $message = $(messageDiv);

    $message.text(text);
    $message.appendTo(this.$speaker);

    setTimeout(function () {
      $message.siblings().hide().attr('aria-hidden', true);
    }, 10 * 1000);
  };

  fn.enableAriaForm = function ($html) {
    var $fieldsets = $html.find('fieldset');

    this.View.$viewport.find('form[aria-enabled]').removeAttr('aria-enabled');

    var self = this;
    $fieldsets.each(function () {
      self.enableAriaFieldset(this);
    });
  };

  fn.enableAriaFieldset = function (fieldsetEl) {
    var $fieldset = $(fieldsetEl);

    if ($fieldset.is('[acif-aria-ignore]')) {
      return;
    }

    var $inputs = $fieldset.find(':input');
    var $firstInput = $inputs.first();
    var $invalids = $fieldset.find('[acif-invalid]');
    var fieldsetName = $fieldset.attr('acif-field-name') || $firstInput.attr('name') || '';
    var name = _.dasherize(fieldsetName);
    var describedByID = $fieldset.attr('aria-describedby');
    var legendLabelID = name + '-label';
    var labelledby = legendLabelID;
    var type = $firstInput.attr('type') || $fieldset.attr('type') || '';
    var isRequired = $fieldset.is('[required], [aria-required]') || !!$fieldset.find(':input[required], :input[aria-required]').length;
    var $legend = $fieldset.find('legend').first();

    if (!$legend.length) {
      $legend = $fieldset.find('label').first().filter(function () {
        // return $(this).children().length === 0;
        return $(this).find(':input').length === 0;
      });
    }

    if (!$legend.length) {
      throwError('Fieldset for "' + fieldsetName + '" is missing a leading <label> or <legend> element.');
    }

    if (!name) {
      var fieldsetClone = $fieldset.clone();
      var fieldsetHTML = $('<div>').append(fieldsetClone).html();
      var msg = 'Fieldset with the content below must contain a name attribute to support aria-enabled behavior.\n\n';
      throwError(msg + fieldsetHTML);
    }

    $fieldset.removeAttr('name').removeAttr('type');

    if (describedByID) {
      $legend = this.View.$viewport.find('#' + describedByID);
      $fieldset.removeAttr('aria-describedby');
    }

    $invalids.each(prepareInvalids);

    if (!$legend.attr('id')) {
      $legend.attr('id', legendLabelID);
    }

    if (type.match(/radio|checkbox/)) {

      $inputs.each(wrapInputs);

      labelledby = legendLabelID + ' ' + $firstInput.attr('aria-labelledby');

      // Placing a tabindex on the fieldset enables JAWS to read the
      // fieldset's label when it receives focus.
      if (type === 'checkbox' && this.View.Env.flashVars.browserType === 'IE') {
        $fieldset.attr('tabindex', 0);
      }
    }

    $firstInput.attr('aria-labelledby', labelledby);

    if ($fieldset.hasClass('formify-fieldset-radio') || $fieldset.hasClass('formify-scale-item')) {
      $fieldset.attr('role', 'radiogroup').attr('aria-labelledby', legendLabelID);
      $inputs.removeAttr('aria-labelledby');
    }

    // Replace the "required" attribute with the aria-required attribute
    // The required attribute must be removed due to a JAWS bug in Firefox
    // that causes fields to be announced as "invalid entry" if they contain
    // the required attribute.  See: http://stackoverflow.com/questions/20386266/how-to-prevent-jaws-from-saying-invalid-entry-on-required-fields
    $inputs.filter('[required]').attr('aria-required', 'true').removeAttr('required');

    if (this.View.Env.isAndroidMobile && isRequired) {
      makeRequiredLabel();
    }

    function prepareInvalids() {
      var $invalid = $(this);
      var invalid = $invalid.attr('acif-invalid');

      $invalid.attr('id', 'invalid-' + invalid + '-' + name).attr('hidden', 'true').attr('aria-hidden', 'true');
    }

    function wrapInputs() {
      var _this4 = this;

      var $input = $(this);
      var $label = $input.closest('label');

      if (!$label.length) {
        throwError('Radio and checkbox <input> elements must be wrapped inside <label> elements');
      }

      var text = $label.text().trim();
      var $children = $label.children();
      var $textLabel = $children.filter(function () {
        return $(_this4).text().trim() === text;
      });

      var textLabelId;
      if ($textLabel.length === 1) {
        textLabelId = $textLabel.attr('id');
      } else {
        $textLabel = $('<span>').text(text);
        $label.empty().append($input).append($textLabel);
      }

      textLabelId = textLabelId || _.uniqueId('tc-');

      $textLabel.attr('id', textLabelId);

      $input.attr('aria-labelledby', textLabelId);
    }

    function makeRequiredLabel() {
      var legend = $legend.text().trim();
      if (!legend.match(/\*$/)) {
        $legend.text(legend + ' *');
      }
    }
  };

  fn.resetErrorMessages = function ($fieldset) {
    var $messages = $fieldset.find('[acif-invalid]');
    var $invalidInputs = $fieldset.find('[aria-invalid]');

    $messages.attr('aria-hidden', 'true').removeAttr('role');

    $invalidInputs.removeAttr('aria-invalid');
    $invalidInputs.each(resetDescribedBy);
  };

  function resetDescribedBy() {
    var $input = $(this);
    var describedBy = $input.attr('aria-describedby') || '';

    describedBy = describedBy ? describedBy.replace(/[\w-_]*invalid-[\w-_]+/g, '') : null;

    $input.attr('aria-describedby', describedBy);
  }

  fn.makeInvalid = function ($fieldset, $invalid) {
    var $inputs = $fieldset.find(':input');
    var $firstInput = $inputs.first();

    var originalDescribedby = $firstInput.data('original-aria-describedby') || '';

    var describedBy = originalDescribedby + ' ' + $invalid.attr('id');

    $invalid.removeAttr('aria-hidden');
    $inputs.attr('aria-invalid', 'true');
    $firstInput.attr('aria-describedby', describedBy);
  };

  fn.createScreenReaderDiv = function (text) {
    var promptDiv = this.View.Env.document.createElement('DIV');
    var $prompt = $(promptDiv).text(text);
    $prompt.css({
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      width: '1px'
    });

    $prompt.attr('aria-hidden', true);

    return $prompt;
  };

  fn.repositionAncestorLabels = function ($html) {
    var _this = this;
    $html.find('[aria-labelledby]').each(function () {
      var $this = $(this);
      var labelledby = $this.attr('aria-labelledby');
      var $label = $html.find('#' + labelledby);
      var isLabelOnAncestorElement = !!$this.closest($label).length;

      if (!isLabelOnAncestorElement) {
        return;
      }

      var newLabelId = _.uniqueId('tc-');
      var labelText = $label.text();
      var $newLabel = _this.createScreenReaderDiv(labelText);

      $newLabel.attr('id', newLabelId);
      $this.attr('aria-labelledby', newLabelId);
      $newLabel.insertBefore($this);
    });
  };

  fn.getFirstSelectableElement = function () {
    var $mainHtml = this.View.$viewport.find('[role=main]');
    $mainHtml = $mainHtml.length ? $mainHtml : this.View.$viewport;
    return this.View.getTabbables($mainHtml).first()[0];
  };

  fn.getVisibleViewportText = function () {
    var $viewClone = this.View.$viewContainer.clone();
    $viewClone.appendTo(this.View.$xframe).find(':hidden').remove().end().remove();

    return $viewClone.text();
  };
});