define('acif/view/formify/precompiled/formify-footer', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (obj) {
    var __t,
        __p = '',
        __j = Array.prototype.join,
        print = function print() {
      __p += __j.call(arguments, '');
    };
    with (obj || {}) {
      __p += '<footer class="formify-footer">\n  <button class="formify-submit" type="submit" ' + ((__t = formify._toggle_attr) == null ? '' : __t) + '>' + ((__t = formify.submit) == null ? '' : __t) + '</button>\n</footer>';
    }
    return __p;
  };
});