define('storage/iframe/storage', ['exports', '../utils/get', '../utils/set'], function (exports, _get, _set) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _get2 = _interopRequireDefault(_get);

  var _set2 = _interopRequireDefault(_set);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = TcStorage;


  function TcStorage(options) {

    this.ids = {
      site: options.siteId,
      session: options.sessionId,
      engagement: options.engagementId,
      tab: options.tabId
    };

    this._storageKey = 'nuance-acif-' + options.siteId;

    var storage = this._getStorage();

    this.options = options;

    this._initStore(storage, 'session');
    this._initStore(storage, 'engagement');
    this._initStore(storage, 'tab');

    storage.lifetime = storage.lifetime || {
      data: {}
    };

    storage.custom = storage.custom || {
      data: {},
      registry: {}
    };

    this._purgeExpiredItems(storage);

    this._ieSyncFix();
    this._setStorage(storage);
  }

  TcStorage.get = function (key) {
    var item = localStorage.getItem(key);

    if (!item) return item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      console.error('"' + key + '" is not a valid localStorage JSON string');
    }

    return item;
  };

  var fn = TcStorage.prototype = { constructor: TcStorage };

  fn._initStore = function (storage, scope) {
    storage[scope] = storage[scope] || {};

    var id = this.ids[scope];

    if (id !== storage[scope].id) {
      storage[scope] = {
        id: id,
        data: {}
      };
    }
  };

  fn._ieSyncFix = function () {
    // https://connect.microsoft.com/IE/feedback/details/812563/ie-11-local-storage-synchronization-issues

    var noop = function noop() {};

    if (document.addEventListener) {
      document.addEventListener('storage', noop, false);
    } else if (document.attachEvent) {
      document.attachEvent('onstorage', noop);
    } else {
      document.onstorage = noop;
    }

    // Overwrite method to set and remove a dummy property
    this._ieSyncFix = function () {
      localStorage.setItem('inq-dummy', 1);
      localStorage.removeItem('inq-dummy');
    };
  };

  fn.set = function (params) {
    if (params.engagementId) {
      this.ids.engagement = params.engagementId;
    }

    var path = params.path;
    var value = params.value;
    var scope = params.scope;
    var storage = this._getStorage();
    var store = this._getStore(storage, scope);
    var expires = void 0;

    if (!store) {
      if (scope) {
        expires = this._getExpirationDate(scope);
        scope = 'custom';
      } else {
        scope = 'session';
      }
      store = this._getStore(storage, scope);
    }

    var oldValue = (0, _get2.default)(store, path);

    (0, _set2.default)(store, path, value);

    if (expires) {
      storage.custom.registry[path] = expires.getTime();
    }

    this._setStorage(storage);
    this._ieSyncFix();

    var changes = oldValue === value ? undefined : {
      path: path,
      previous: oldValue,
      current: value,
      scope: scope,
      expires: expires
    };

    return { storage: storage, changes: changes };
  };

  fn._setStorage = function (storage) {
    storage = JSON.stringify(storage);
    localStorage.setItem(this._storageKey, storage);
  };

  fn.get = function (params) {
    if (params.engagementId) {
      this.ids.engagement = params.engagementId;
    }

    var scope = params.scope || 'session';
    var isValidScope = ['engagement', 'tab', 'session', 'lifetime', 'custom'].indexOf(scope) > -1;

    if (!isValidScope) {
      throw new Error('"' + scope + '" is not a valid TC Storage scope. Expected one of: engagement, tab, session, lifetime, or custom');
    }

    var storage = this._getStorage();
    var store = this._getStore(storage, scope);

    return (0, _get2.default)(store, params.path);
  };

  fn._getStore = function (storage, scope) {
    var store = storage[scope];

    if (scope.match(/custom|lifetime/i)) {
      return store.data;
    }

    var id = this.ids[scope];

    if (id !== store.id) {
      store = storage[scope] = { id: id, data: {} };
    }

    return store.data;
  };

  fn._getStorage = function () {
    var data = localStorage.getItem(this._storageKey);
    return data ? JSON.parse(data) : {};
  };

  fn.getAllData = function () {
    return this._getStorage();
  };

  fn._getExpirationDate = function (expires) {
    var date = void 0;

    if (expires instanceof Date) {
      date = expires;
    } else if (typeof expires === 'number') {
      date = new Date(expires);
    } else if (typeof expires === 'string') {
      date = stringToDate(expires);
    }

    var isValidExpirationDate = date instanceof Date && date > new Date();

    return isValidExpirationDate ? date : stringToDate('1d');
  };

  // 1M3d6h2m12s
  function stringToDate(str) {
    var date = new Date();

    var year = (str.match(/(\d+)y/i) || [])[1];
    var month = (str.match(/(\d+)M/) || [])[1];
    var day = (str.match(/(\d+)d/i) || [])[1];
    var hours = (str.match(/(\d+)h/i) || [])[1];
    var minutes = (str.match(/(\d+)m/) || [])[1];
    var seconds = (str.match(/(\d+)s/i) || [])[1];

    if (year) {
      date.setFullYear(date.getFullYear() + Number(year));
    }

    if (month) {
      date.setMonth(date.getMonth() + Number(month));
    }

    if (day) {
      date.setDate(date.getDate() + Number(day));
    }

    if (hours) {
      date.setHours(date.getHours() + Number(hours));
    }

    if (minutes) {
      date.setMinutes(date.getMinutes() + Number(minutes));
    }

    if (seconds) {
      date.setSeconds(date.getSeconds() + Number(seconds));
    }

    return date;
  }

  fn._purgeExpiredItems = function (storage) {
    var now = new Date().getTime();
    var registry = storage.__registry__;

    for (var path in registry) {
      var expires = registry[path];

      if (now < expires) continue;

      (0, _set2.default)(storage, path, undefined);

      delete registry[path];
    }
  };
});