define('acif/view/formify/precompiled/formify-type-textarea', ['exports'], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (obj) {
    var __t,
        __p = '',
        __j = Array.prototype.join,
        print = function print() {
      __p += __j.call(arguments, '');
    };
    with (obj || {}) {
      __p += '<fieldset class="formify-fieldset-textarea" name="' + ((__t = field.id) == null ? '' : __t) + '" acif-field-type="' + ((__t = field.type_attr) == null ? '' : __t) + '" ' + ((__t = field._attrs) == null ? '' : __t) + '>\n  <label class="formify-label">' + ((__t = field.label) == null ? '' : __t) + '</label>\n  <textarea class="formify-input" ' + ((__t = field.validate._attrs + field._placeholder_attr) == null ? '' : __t) + '></textarea>\n  ' + ((__t = field.validate._html) == null ? '' : __t) + '\n</fieldset>';
    }
    return __p;
  };
});