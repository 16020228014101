define('storage/index', ['exports', 'storage/iframe', 'storage/top-frame'], function (exports, _iframe, _topFrame) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = storage;

  var _iframe2 = _interopRequireDefault(_iframe);

  var _topFrame2 = _interopRequireDefault(_topFrame);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  /**
   * In this file we execute either iframe or top-frame modules contextually
   * if isFrame is truthy, we execute the iframe module else, we execute the
   * top-frame module
   */
  function storage() {
    var isIframe = typeof inqFrame === 'undefined';
    if (isIframe) {
      (0, _iframe2.default)();
    } else {
      (0, _topFrame2.default)();
    }
  }
});