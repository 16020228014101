define('acif/ci/push-content', ['exports', './ijsf-adapter', '../utils/getAutomatonProp', '../utils/logger'], function (exports, _ijsfAdapter, _getAutomatonProp, _logger) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  var _getAutomatonProp2 = _interopRequireDefault(_getAutomatonProp);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  exports.default = {
    extractAgentScriptCommands: extractAgentScriptCommands,
    processAgentDataPass: processAgentDataPass
  };


  function extractAgentScriptCommands(agentMessageData) {
    var agentMessageText = agentMessageData.textLine;

    var $lastMessage = $('<div>').append(agentMessageText).find('[acif-automaton-id], [data-automaton-id]');

    var automatonId = $lastMessage.attr('acif-automaton-id') || $lastMessage.attr('data-automaton-id');
    var context = $lastMessage.attr('acif-context') || $lastMessage.attr('data-context') || 'ci';
    var panel = $lastMessage.attr('acif-panel') || $lastMessage.attr('data-panel') || (0, _getAutomatonProp2.default)(automatonId, 'attributes.panel.location');
    var action = $lastMessage.attr('acif-action') || $lastMessage.attr('data-action');

    var isAgentShowAutomatonMessage = action === 'show' && automatonId && panel;
    var isAgentHideAutomatonMessage = action === 'hide' && automatonId && panel;
    var isAgentAutomatonMessage = !!(isAgentShowAutomatonMessage || isAgentHideAutomatonMessage);

    if (!isAgentAutomatonMessage) return;

    var params = {
      automatonID: automatonId,
      context: context,
      location: panel
    };

    var startedBy = {
      type: 'agent',
      id: _ijsfAdapter2.default.getAgentId()
    };

    if (isAgentShowAutomatonMessage) {
      $.when(ACIF.onStorageReady, ACIF.onConfigsReady).then(function () {
        ACIF.loadAutomaton(params, startedBy);
      }).catch(_logger.logError);
    } else if (isAgentHideAutomatonMessage) {
      ACIF.closeAutomaton(params);
    } else {
      ACIF.Utils.throwError('The action \'' + action + '\' sent by the agent can not be handled by the ACIF');
    }
  }

  function processAgentDataPass(launchParams) {
    var params = _.pick(launchParams.data, 'automatonID', 'location', 'context', 'datapass');
    params.location = params.location || (0, _getAutomatonProp2.default)(params.automatonID, 'attributes.panel.location');

    if (launchParams.action === 'loadAutomaton') {

      var startedBy = {
        type: 'agent',
        id: _ijsfAdapter2.default.getAgentId()
      };

      $.when(ACIF.onStorageReady, ACIF.onConfigsReady).then(function () {
        ACIF.loadAutomaton(params, startedBy);
      }).catch(_logger.logError);
    } else if (launchParams.action === 'closeAutomaton') {

      ACIF.closeAutomaton(params);
    }
  }
});