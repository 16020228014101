define("acif/automaton/utils/timers", ["exports"], function (exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Timers;


  function Timers() {
    this._timeouts = [];
    this._intervals = [];
  }

  var fn = Timers.prototype = { constructor: Timers };

  fn.setTimeout = function (fn, ms) {
    var timerId = setTimeout(fn, ms);
    this._timeouts.push(timerId);
    return timerId;
  };

  fn.setInterval = function (fn, ms) {
    var intervalId = setInterval(fn, ms);
    this._intervals.push(intervalId);
    return intervalId;
  };

  fn.clearTimers = function () {
    this._timeouts.forEach(function (timer) {
      return clearTimeout(timer);
    });
    this._intervals.forEach(function (interval) {
      return clearInterval(interval);
    });

    this._timeouts = [];
    this._intervals = [];
  };

  fn.clearTimeout = function (timeoutId) {
    var index = this._timeouts.indexOf(timeoutId);
    if (index > -1) {
      this._timeouts.splice(index, 1);
      clearTimeout(timeoutId);
    }
  };

  fn.clearInterval = function (intervalId) {
    var index = this._intervals.indexOf(intervalId);
    if (index > -1) {
      this._intervals.splice(index, 1);
      clearInterval(intervalId);
    }
  };
});